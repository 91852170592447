import { Alert, Box, Button, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import './YourDetails.css';
import '../../../css/Mpa.css';
import { SetStateAction, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MerchantInformation } from '../../../Models/MerchantInformation';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import config from "../../../config/config.json";
import { PageStatus } from '../MpaStepper';
import lodash from 'lodash';

interface Values {
    businessName: string;
    abn: string;
    legalName: string;
    firstName: string;
    lastName: string;
    positionTitle: string;
    street: string;
    state: string;
    suburb: string;
    postcode: string;
    fullAddress: string;
    email: string;
    emailConfirmation: string;
    phone: string;
    identity: boolean;
    authorisation: boolean;
}

export interface YourDetailsProps {
    merchantInformation: MerchantInformation,
    pageStatus: SetStateAction<PageStatus>;
    handleNext: (capturedInformation: MerchantInformation) => void
}

const len = config.INPUT_FIELD_LEN;

export const YourDetails = (props: YourDetailsProps) => {
    const { merchantInformation, pageStatus, handleNext } = props;
    const [abnNotFound, setAbnNotFound] = useState(false);
    const [abnServiceDown, setAbnServiceDown] = useState(false);
    const [legalEntityName, setLegalEntityName] = useState<string>(merchantInformation.legalName);

    function getPageStatus() {
        if (pageStatus === PageStatus.ValidState) {
            return true;
        }
        return false;
    }
    
    const [abnOutcomePermitted, setAbnOutcomePermitted] = useState(() =>
        getPageStatus()
    );
    
    const handleAbnChange = useCallback(
        () => {
            setAbnOutcomePermitted(false);
            setAbnNotFound(false);
            setAbnServiceDown(false);
            setLegalEntityName('');
        },
        [],
    )
    
    const scrollToTop = useCallback(
        () => {
            window.scrollTo(0,0);
        },
        [],
    )

    const verifyAbn = useCallback(
        (abn: string) => {
            const abnUrl = abn.trim().replace(' ', '+');
            const abnSearchUrl = config.ABN_URL_BASE + config.ABN_SEARCH_PARAM + abnUrl + config.INCLUDE_HIST_STRING + config.AUTH_GUID_STRING;
            axios.get(abnSearchUrl, { timeout: 10000 })
            .then((response) => {
                const xmlDoc = (new DOMParser()).parseFromString(response.data, "text/xml");
                if (!xmlDoc.getElementsByTagName("exception")[0]) {
                    setAbnOutcomePermitted(true);
                    getLegalName(xmlDoc);                    
                } else {
                    setAbnNotFound(true);
                }
            }, (error) => {
                console.log(error);
                setAbnServiceDown(true);
                setAbnOutcomePermitted(true);
            });
        },
        [],
    )

    const getLegalName = useCallback((xmlDoc: Document) => {
        if (xmlDoc.getElementsByTagName("entityTypeCode")[0].textContent === "IND") {
            const givenName = xmlDoc.getElementsByTagName("givenName")[0].textContent;
            const otherGivenName = xmlDoc.getElementsByTagName("otherGivenName")[0].textContent;
            const familyName = xmlDoc.getElementsByTagName("familyName")[0].textContent;

            setLegalEntityName(`${familyName ? familyName : ''}${familyName && (givenName || otherGivenName) ? ', ' : ''}${givenName ? givenName : ''}${givenName && otherGivenName ? ' ' : ''}${otherGivenName ? otherGivenName : ''}`);
        } else {
            const organisationName = xmlDoc.getElementsByTagName("mainName")[0].getElementsByTagName("organisationName")[0].textContent;
            setLegalEntityName(organisationName ? organisationName : '');
        }
        }, 
        []
    )

    const formik = useFormik({
        initialValues: {
            businessName: merchantInformation.businessName,
            abn: merchantInformation.abn,
            legalName: merchantInformation.legalName,
            firstName : merchantInformation.firstName,
            lastName : merchantInformation.lastName,
            positionTitle: merchantInformation.positionTitle,
            street: merchantInformation.street,
            state: merchantInformation.state,
            suburb: merchantInformation.suburb,
            postcode: merchantInformation.postcode,
            fullAddress: merchantInformation.fullAddress,
            email: merchantInformation.email,
            emailConfirmation: merchantInformation.email,
            phone: merchantInformation.phone,
            identity: merchantInformation.identity,
            authorisation: merchantInformation.authorisation
        },
        validationSchema: yup.object({
            businessName: yup.string()
                .max(100, 'Business trading name cannot exceed 100 characters in length.')
                .required('Please enter your business trading name.'),
            abn: yup.string()
                .max(14, 'Please enter a valid ABN.')
                .required('Please enter your ABN.')
                .transform((value) => {
                    return value !== null ? value.replace(/ /g, "") : value;
                    })
                .matches(/^[0-9]{11}$/, 'Please enter a valid ABN.'),
            firstName: yup.string()
                .max(50, 'First name cannot exceed 50 characters in length.')
                .required('Please enter your first name.'),
            lastName: yup.string()
                .max(50, 'Last name cannot exceed 50 characters in length.')
                .required('Please enter your last name.'),
            positionTitle: yup.string()
                .max(50, 'Position title cannot exceed 50 characters in length. ')
                .required('Please enter your position title.'),
            street: yup.string()
                .max(100, 'Street address cannot exceed 100 characters in length.')
                .required('Please enter your business street address.'),
            suburb: yup.string()
                .max(50, 'Suburb cannot exceed 50 characters in length.')
                .required('Please enter your business suburb.'),
            state: yup.string()
                .required('Please enter your business state.'),
            postcode: yup.string()
                .max(4, 'Please enter a valid postcode.')
                .required('Please enter your business postcode.')
                .matches(/^[0-9]{4}$/, 'Please enter a valid postcode.'),
            email: yup.string()
                .max(320, 'Email address cannot exceed 320 characters in length.')
                .required('Please enter your email address.')
                .email('Please enter a valid email address.'),
            emailConfirmation: yup.string()
                .oneOf([yup.ref('email')], 'Your email address does not match.')
                .required('Please re-enter your email address.'),
            phone: yup.string()
                .required('Please enter your phone number.')
                .transform((value) => {
                    return value !== null ? value.replace(/ /g, "") : value;
                    })
                .matches(/^((\+61(2|3|4|7|8)[\d]{8})|(0(2|3|4|7|8)[\d]{8})|(((1300)|(1800))[\d]{6})|((13)[\d]{4}))$/, 
                'Please enter a valid phone number using digits, spaces and + only. Please also include a valid area code if entering a landline.'),
            identity: yup.bool()
                .oneOf([true], 'Please confirm that you are the business owner or manager of the business.'),
            authorisation: yup.bool()
                .oneOf([true], 'Please confirm that you are authorised to sign the Merchant Participation Agreement.')
        }),
        validate,
        onSubmit: (values) => {
          
            values.fullAddress = `${values.street}, ${values.suburb}, ${values.state} ${values.postcode}`
            
            if (abnOutcomePermitted) handleNext(values);
            else window.scrollTo(0,0);
        },
    });

    function getIdentityStatus() {
        if (formik.values.identity === true) {
            return true;
        }        
        return false;
    }

    function getAuthorisationStatus() {
        if (formik.values.authorisation === true) {
            return true;
        }
        return false;
    }
    
    const [identityChecked, setIdentityChecked] = useState(() =>
        getIdentityStatus()
    );
    const [authorisationChecked, setAuthorisationChecked] = useState(() =>
        getAuthorisationStatus()
    );
    
    const handleIdentityClick = useCallback(
        () => {
            setIdentityChecked(!identityChecked);
            formik.setFieldValue('identity', !identityChecked);
        },
        [formik, identityChecked],
    )
    
    const handleAuthorisationClick = useCallback(
        () => {
            setAuthorisationChecked(!authorisationChecked);
            formik.setFieldValue('authorisation', !authorisationChecked);
        },
        [authorisationChecked, formik],
    )
    
    const openPrivatePolicy = useCallback(
        () => {
            window.open(config.PRIVACY_POLICY);
        },
        [],
    )
    
    const isFormEmpty = useCallback(
        (input : Values) => {
            if (input.businessName === '') return false;  
            if (input.abn !== '') return false; 
            if (input.legalName !== '') return false;
            if (input.firstName  !== '') return false; 
            if (input.lastName  !== '') return false; 
            if (input.positionTitle !== '') return false;
            if (input.street !== '') return false; 
            if (input.state !== '') return false; 
            if (input.suburb !== '') return false; 
            if (input.postcode !== '') return false; 
            if (input.fullAddress !== '') return false; 
            if (input.email !== '') return false; 
            if (input.emailConfirmation !== '') return false; 
            if (input.phone !== '') return false; 
            if (input.identity !== false) return false; 
            if (input.authorisation !== false) return false; 

            return true;
        },
        [],
    )

    function validate(values: Values) {
        const errors: any = {};

        // Show error immediately if user exceeds max length 
        const checkLength = (fieldLabel: string, fieldName: string, length: number) => {
            if (lodash.get(values, fieldName).length > length) {
                lodash.set(formik.touched, fieldName, true);
                errors.fieldName = `${fieldLabel} cannot exceed ${length} characters in length.`;
            }
        }

        checkLength("Business trading name", "businessName", len.BUSINESS_NAME_LEN);
        checkLength("ABN", "abn", len.ABN_LEN);
        checkLength("First name", "firstName", len.FIRST_NAME_LEN);
        checkLength("Last name", "lastName", len.LAST_NAME_LEN);
        checkLength("Position title ", "positionTitle", len.POSITION_TITLE_LEN);
        checkLength("Street address", "street", len.STREET_LEN);
        checkLength("Suburb", "suburb", len.SUBURB_LEN);
        checkLength("Postcode", "postcode", len.POSTCODE_LEN);
        checkLength("Email address", "email", len.EMAIL_LEN);

        // Show errors immediately if illegal character is found
        function checkIllegalChars(fieldName: string) {
            const regexIllegal = new RegExp('[<>;&]');

            if (regexIllegal.test(lodash.get(values, fieldName))) {
                lodash.set(formik.touched, fieldName, true);
                errors[fieldName] = 'The following characters are not allowed in this field: < > ; &';
            }
        }

        function checkIllegalCharsBusinessName(fieldName: string) {
            const regexIllegalBusinessName = new RegExp('[<>;]');

            if (regexIllegalBusinessName.test(lodash.get(values, fieldName))) {
                lodash.set(formik.touched, fieldName, true);
                errors[fieldName] = 'The following characters are not allowed in this field: < > ;';
            }
        }

        checkIllegalCharsBusinessName("businessName");
        checkIllegalChars("abn");
        checkIllegalChars("firstName");
        checkIllegalChars("lastName");
        checkIllegalChars("positionTitle");
        checkIllegalChars("street");
        checkIllegalChars("suburb");
        checkIllegalChars("postcode");
        checkIllegalChars("email");
        checkIllegalChars("emailConfirmation");
        checkIllegalChars("phone");

        return errors;
    }
    
    const removeSpaces = useCallback(
        (string: string) => {
            return string.replace(/ /g, "");
        },
        [],
    )
    
    const formatAbn = useCallback(
        (rawAbn: string) => {
            const strippedAbn = removeSpaces(rawAbn);
            return `${strippedAbn.substring(0,2)}${strippedAbn.substring(2, 5) ? ' ' + strippedAbn.substring(2, 5) : ''}${strippedAbn.substring(5, 8) ? ' ' + strippedAbn.substring(5, 8) : ''}${strippedAbn.substring(8, 11) ? ' ' + strippedAbn.substring(8, 11) : ''}${strippedAbn.substring(11)}`;
        },
        [removeSpaces],
    )

    const triggerFunctionOnEnter = useCallback(
        (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                trigger();
            }
        },
        [],
    )
    
    const preventDefault = useCallback(
        () => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                e.preventDefault();
            }
        },
        [],
    )

    const handleChange = useCallback(
        (changeFunction) => (event: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(event)
            changeFunction()
        },
        [formik],
    )

    const onBlurABN = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            formik.handleBlur(event);
            formik.values.abn = formatAbn(formik.values.abn);
            if (!Boolean(formik.errors.abn)) verifyAbn(event.target.value);
        },
        [formatAbn, formik, verifyAbn],
    )

    return (
        <>
            <Box className="background yourDetails_background">
                <Box className="formBox yourDetails_formBox">
                    <Typography variant="h1" className="yourDetails_heading1" sx={{mb:'0.9375rem'}}>
                        Your details
                    </Typography>
                    <Typography variant="h3" className="yourDetails_heading3" sx={{mb:'0.9375rem'}}>
                        General details about your business
                    </Typography>
                    <Typography variant="h5" className="yourDetails_heading5" sx={{mb:'2.1875rem'}}>
                        <strong>
                            {"Please provide the following information for your Merchant Participation Agreement."}
                        </strong>
                    </Typography>
                    <form onSubmit={formik.handleSubmit} className="widest" onKeyPress={preventDefault}>
                        <TextField
                            className="yourDetails_textField widest"
                            id="businessName"
                            label="Business trading name*"
                            variant="filled"
                            sx={{mb:'1.25rem'}}
                            value={formik.values.businessName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                            helperText={formik.touched.businessName && formik.errors.businessName ? formik.errors.businessName : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="abn"
                            label="ABN*"
                            variant="filled"
                            sx={{mb:'1.25rem'}}
                            value={formik.values.abn}
                            onChange={handleChange(handleAbnChange)}
                            onBlur={onBlurABN}
                            error={formik.touched.abn && Boolean(formik.errors.abn)}
                            helperText={formik.touched.abn && formik.errors.abn ? formik.errors.abn : ''}
                        />
                        {/* ABN found and legal name auto-filled */}
                        {abnOutcomePermitted && legalEntityName &&
                            <Alert
                                className="yourDetails_alert">
                                    ABN found
                                    <br/><span className = "yourDetails_alertDesc">Your legal entity name has been automatically entered below.</span>
                            </Alert>
                        }
                        {/* ABN found but no legal name retrieved in ABN Lookup */}
                        {abnOutcomePermitted && !legalEntityName && !abnServiceDown &&
                            <Alert
                                className="yourDetails_alert">
                                    ABN found
                                    <br/><span className = "yourDetails_alertDesc">Legal entity name data unavailable. Services Australia to enter during countersigning.</span>
                            </Alert>
                        }
                        {/* ABN not found by ABN Lookup */}
                        {abnNotFound && !Boolean(formik.errors.abn) &&
                            <Alert
                                severity="error"
                                className="yourDetails_alert"
                            >
                                ABN not found
                                <br/><span className = "yourDetails_alertDesc">
                                    <NumberFormat value={formik.values.abn} displayType={'text'} format="## ### ### ####" />&nbsp;is not a valid ABN
                                </span>
                            </Alert>
                        }
                        {/* ABN Lookup not responding - users allowed to proceed without ABN verification*/}
                        {abnServiceDown &&
                            <Alert
                                severity="warning"
                                className="yourDetails_alert">
                                An unknown error occured.
                                <br/><span className = "yourDetails_alertDesc">Legal entity name data unavailable. Services Australia to enter during countersigning.</span>
                            </Alert>
                        }
                        <TextField
                            disabled
                            className="yourDetails_textField widest"
                            id="legalName"
                            label="Legal entity name"
                            variant="filled"
                            value={formik.values.legalName = legalEntityName}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="firstName"
                            label="First name*"
                            variant="filled"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="lastName"
                            label="Last name*"
                            variant="filled"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="positionTitle"
                            label="Position title*"
                            variant="filled"
                            value={formik.values.positionTitle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.positionTitle && Boolean(formik.errors.positionTitle)}
                            helperText={formik.touched.positionTitle && formik.errors.positionTitle ? formik.errors.positionTitle : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="email"
                            label="Email address*"
                            variant="filled"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="emailConfirmation"
                            label="Confirm email address*"
                            variant="filled"
                            value={formik.values.emailConfirmation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.emailConfirmation && Boolean(formik.errors.emailConfirmation)}
                            helperText={formik.touched.emailConfirmation && formik.errors.emailConfirmation ? formik.errors.emailConfirmation : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="phone"
                            label="Phone number*"
                            variant="filled"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone ? formik.errors.phone: ''}
                        />
                        <Typography sx={{
                            textAlign: 'left',
                            font: 'normal normal 600 1.125rem/2rem Open Sans',
                            color: '#5F277E',
                            padding: '0.3125rem 0rem 0.625rem'
                        }}>
                            Business Address
                        </Typography>
                        <TextField
                            className="yourDetails_textField widest"
                            id="street"
                            label="Street address*"
                            variant="filled"
                            value={formik.values.street}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.street && Boolean(formik.errors.street)}
                            helperText={formik.touched.street && formik.errors.street ? formik.errors.street : ''}
                        />
                        <TextField
                            className="yourDetails_textField widest"
                            id="suburb"
                            label="Suburb*"
                            variant="filled"
                            value={formik.values.suburb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
                            helperText={formik.touched.suburb && formik.errors.suburb ? formik.errors.suburb : ''}
                        />
                        <div className='flexAddress'>
                            <div className='yourDetails_splitRowWidth'>
                                <FormControl variant="filled" className='widest'>
                                    <InputLabel
                                        id="yourDetails_stateTerritory_label"
                                        error={formik.touched.state && Boolean(formik.errors.state)}
                                    >
                                        State*
                                    </InputLabel>
                                    <Select
                                        labelId="yourDetails_stateTerritory_label"
                                        label="State*"
                                        id="state"
                                        name="state"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.state && Boolean(formik.errors.state)}
                                    >
                                        <MenuItem value={""}>
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'ACT'}>ACT</MenuItem>
                                        <MenuItem value={'NSW'}>NSW</MenuItem>
                                        <MenuItem value={'NT'}>NT</MenuItem>
                                        <MenuItem value={'QLD'}>QLD</MenuItem>
                                        <MenuItem value={'SA'}>SA</MenuItem>
                                        <MenuItem value={'TAS'}>TAS</MenuItem>
                                        <MenuItem value={'VIC'}>VIC</MenuItem>
                                        <MenuItem value={'WA'}>WA</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormHelperText className="yourDetails_errorText">
                                    {formik.touched.state && formik.errors.state ? formik.errors.state : ''}
                                </FormHelperText>
                            </div>
                            <TextField
                                className="yourDetails_textField yourDetails_splitRowWidth"
                                id="postcode"
                                label="Postcode*"
                                variant="filled"
                                value={formik.values.postcode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                                helperText={formik.touched.postcode && formik.errors.postcode ? formik.errors.postcode : ''}
                            />
                        </div>
                        <Box
                            className="yourDetails_checkBoxContainer widest"
                            tabIndex={0}
                            onKeyPress={triggerFunctionOnEnter(handleIdentityClick)}
                            onClick={handleIdentityClick}
                        >
                            <Checkbox
                                tabIndex={-1}
                                id="chkIdentity"
                                value={formik.values.identity}
                                checked={identityChecked === true}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                                onBlur={formik.handleBlur}
                                onChange={handleChange(handleIdentityClick)}
                            />
                            <Typography className="yourDetails_checkBoxText">
                                I confirm I am the owner or manager of this business*
                            </Typography>
                        </Box>
                        <FormHelperText className="yourDetails_errorText">
                            {formik.touched.identity && formik.errors.identity ? formik.errors.identity : ''}
                        </FormHelperText>
                        <Box
                            className="yourDetails_checkBoxContainer widest"
                            tabIndex={0}
                            onKeyPress={triggerFunctionOnEnter(handleAuthorisationClick)}
                            onClick={handleAuthorisationClick}
                        >
                            <Checkbox
                                tabIndex={-1}
                                id="chkAuthorisation"
                                value={formik.values.authorisation}
                                checked={authorisationChecked === true}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                                onBlur={formik.handleBlur}
                                onChange={handleChange(handleAuthorisationClick)}
                            />
                            <Typography className="yourDetails_checkBoxText">
                                I am authorised to sign this Merchant Participation Agreement*
                            </Typography>
                        </Box>
                        <FormHelperText className="yourDetails_errorText">
                            {formik.touched.authorisation && formik.errors.authorisation ? formik.errors.authorisation : ''}
                        </FormHelperText>

                        <Typography variant="caption" className="widest" sx={{mb:'3.125rem'}}>
                            *indicates mandatory field
                        </Typography>
                        <Typography
                            tabIndex={0}
                            onKeyPress={triggerFunctionOnEnter(openPrivatePolicy)}
                            className="yourDetails_privatePolicy"
                        >
                            By submitting this form you agree to our <span className="yourDetails_privatePolicyHighlight" onClick={openPrivatePolicy}>Privacy Policy</span> which explains how we may collect, use and disclose your personal information.
                        </Typography>
                        <div className="yourDetails_nextButtonContainer widest">
                            <Button type="submit" className="primaryButton" onClick={scrollToTop} disabled={!formik.isValid || isFormEmpty(formik.values)}>
                                Next
                            </Button>
                        </div>
                    </form>
                </Box>
            </Box>
        </>
    )
}
