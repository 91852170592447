import React, { useEffect, useCallback } from 'react';
import { Grid, Typography, Button, Box, Dialog } from '@mui/material'
import { DialogTemplate } from '../DialogTemplate'
import { RestrictedDialog } from './Dialogs/Restricted_Dialog'
import { UnrestrictedDialog } from './Dialogs/Unrestricted_Dialog'
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import {
    CompatibilityLocation,
    SignUpLocation,
    FactsheetGetToKnowLocation,
    FactsheetRestrictedLocation,
    FactsheetPLBLocation,
    FactsheetReadyLocation,
    FactsheetGoingLiveLocation
} from '../../routeConstants';

import section_1_image from '../../Assets/images/home_section_1.svg';
import section_3_image_restricted from '../../Assets/images/home_section_2_neg.png';
import section_3_image_non_restricted from '../../Assets/images/home_section_2_pos.png';

import section_3_image_1 from '../../Assets/images/home_section_3_1.png';
import section_3_image_2 from '../../Assets/images/home_section_3_2.svg';
import section_3_image_3 from '../../Assets/images/home_section_3_3.svg';
import section_3_image_4 from '../../Assets/images/home_section_3_4.svg';
import section_3_image_5 from '../../Assets/images/home_section_3_5.png';

import section_5_icon_1 from '../../Assets/icons/payment_icon.svg';
import section_5_icon_2 from '../../Assets/icons/wallet_icon.svg';
import section_5_icon_3 from '../../Assets/icons/training_icon.svg';
import section_5_icon_4 from '../../Assets/icons/fast_icon.svg';
import business_image_1 from '../../Assets/images/home_page_boat_club.jpg';

import InfoIcon from '@mui/icons-material/Info';
import section_7_image from '../../Assets/images/home_section_7_image.png';
import { HomePageCard } from '../Common/HomePageCard';

import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';

export const HomePage = () => {
    const [dialogPreview, setDialogPreview] = React.useState(-1);
    const navigate = useNavigate();

    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )
    
    const triggerLocationOnEnter = useCallback(
        (location: string) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                window.scrollTo(0, 0);
                navigate(location);
            }
        },
        [navigate],
    )
    
    const openDialog = useCallback(
        (dialog: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
            setDialogPreview(dialog);
        },
        [],
    )
    
    const triggerDialogOnEnter = useCallback(
        (dialog: number) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                setDialogPreview(dialog);
            }
        },
        [],
    )

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        }
    }, []);

    const closeDialog = useCallback(
        () => {
            const targetElement = document.getElementById("popup");
            if(targetElement != null) {
                enableBodyScroll(targetElement);
            }
            setDialogPreview(-1);
        },
        [],
    )

    const dialogScreen = (screen: number) => {
        switch (screen) {
            case 0:
                return (
                    <DialogTemplate
                        handleClose={closeDialog}
                    >
                        <UnrestrictedDialog />
                    </DialogTemplate>
                )
            case 1:
                return (
                    <DialogTemplate
                        handleClose={closeDialog}
                    >
                        <RestrictedDialog />
                    </DialogTemplate>
                )
        }
    }

    return (
        <>
            {/* Section 1 */}
            <Box className="section_1_background home_background">
                <Grid container className="section_1">
                    <Grid item xs={12} md={6}>
                        <Typography className="section_1_header">
                            Welcome to the Product Level Blocking (PLB) Merchant Portal
                        </Typography>
                        <Typography className="section_1_text">
                            {"We're here to help small businesses provide a smoother shopping experience. The "}<span className='section_1_highlight' tabIndex={0} role="link" onKeyPress={triggerLocationOnEnter(FactsheetGetToKnowLocation.path)} onClick={goToLocation(FactsheetGetToKnowLocation.path)}>SmartCard</span>{' and enhanced Income Management are initiatives of the Australian Government to support recipients of certain welfare payments to manage their money and protect them from financial abuse.\n\nOn this site you can have your questions answered, and learn how you and your staff can easily accept the SmartCard as a form of payment if you are a small business that sells ‘restricted items’.'}
                        </Typography>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={5} className="section_1_image_container">
                        <img
                            src={section_1_image}
                            className="section_1_image"
                            alt="" />
                    </Grid>
                </Grid>

                {/* Section 2 */}
                <Grid container className="section_2">
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={4} className="section_2_container">
                        <Typography className="section_2_text">
                            Already know about Product Level Blocking (PLB) and ready to sign up?
                        </Typography>
                        <Button variant="yellow" onClick={goToLocation(SignUpLocation.path)} className="section_2_button" tabIndex={0}>
                            <p className="section_2_button_text">
                                Sign up for PLB
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={2}></Grid>
                    <Grid item xs={12} md={4} className="section_2_container section_2_mobile">
                        <Typography className="section_2_text">
                            Want to check if your business is compatible for Product Level Blocking (PLB)?
                        </Typography>
                        <Button variant="yellow" onClick={goToLocation(CompatibilityLocation.path)} className="section_2_button" tabIndex={0}>
                            <p className="section_2_button_text">
                                Check compatibility
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                </Grid>
                <div className="section_2_end">
                    <Typography className="section_2_text_center">
                        Or continue scrolling down the page to learn more.
                    </Typography>
                </div>
            </Box>

            {/* Section 3 */}
            <div className="section_3_background home_background home_padding_gutter">
                <Grid container className="gutter section_3">
                    <Grid item xs={12} md={12}>
                        <Typography className="section_3_header">
                            Accepting SmartCard payments
                        </Typography>
                        <Typography className="section_3_text">
                            <div className='dialog_flex'>
                                <Typography className='section_3_point'>{"•\t"}</Typography>
                                <Typography className='section_3_point_text'>{"If your business does not sell any restricted items, you will not need to do anything as the SmartCard will automatically work at your business."}</Typography>
                            </div>
                            <div className='dialog_flex'>
                                <Typography className='section_3_point'>{"\n•\t"}</Typography>
                                <Typography className='section_3_point_text'>{"\nIf your business sells both restricted and unrestricted items (for example, a grocery store that sells alcohol), you can accept the SmartCard as a form of payment, however a method of preventing the purchase of restricted items with a SmartCard will be required."}</Typography>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className="gutter" spacing={6}>
                    <Grid item xs={12} md={6}>
                        <div className="section_3_button" role="button" tabIndex={0} onKeyPress={triggerDialogOnEnter(1)} onClick={openDialog(1)}>
                            <InfoIcon className="section_3_info" />
                            <Typography className="section_3_highlight">
                                What are the restricted items?
                            </Typography>
                        </div>
                        <div className='section_2_image_container'>
                            <img
                                src={section_3_image_restricted}
                                tabIndex={0}
                                onKeyPress={triggerDialogOnEnter(1)}
                                onClick={openDialog(1)}
                                className="section_2_image"
                                alt="Some items are restricted" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className='section_2_grid_margin'>
                        <div className="section_3_button"  role="button" tabIndex={0} onKeyPress={triggerDialogOnEnter(0)} onClick={openDialog(0)}>
                            <InfoIcon className="section_3_info" />
                            <Typography className="section_3_highlight">
                                What if I don't sell restricted items?
                            </Typography>
                        </div>
                        <div className='section_2_image_container'>
                            <img
                                src={section_3_image_non_restricted}
                                tabIndex={0}
                                onKeyPress={triggerDialogOnEnter(0)}
                                onClick={openDialog(0)}
                                className="section_2_image"
                                alt="Some items are not restricted" />
                        </div>
                    </Grid>
                </Grid>

                <Grid container className="gutter section_3B">
                    <Grid item xs={0} md={4}></Grid>
                    <Grid item xs={12} md={4} className="section_6_flex_center">
                        <Button variant="yellow" onClick={goToLocation(FactsheetRestrictedLocation.path)} className="section_button">
                            <p className="section_button_text">
                                Learn more about restricted items
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={4}></Grid>
                </Grid>
            </div>

            {/* Section 4 */}
            <div className="home_background home_padding_gutter" >
                <Grid container className="gutter section_4">
                    <Grid item xs={0} md={3} />
                    <Grid item xs={12} md={6}>
                        <Typography className="section_4_top_header">
                            What is Product Level Blocking?
                        </Typography>
                        <Typography className="section_4_top_text">
                            Product Level Blocking (PLB) is an automated process to ensure that a SmartCard is not able to purchase restricted items. Here's an example of how it works:
                        </Typography>
                    </Grid>
                    <Grid item xs={0} md={3} />
                </Grid>
                <Grid container spacing={2} className="section_4_grid gutter"  justifyContent={"center"} >
                    <HomePageCard
                        img={section_3_image_1}
                        number="1"
                        heading="Restricted item"
                        body={<p>A customer goes to checkout with bread, fruit, milk and <strong>beer</strong> in their trolley.</p>}
                    />
                    <HomePageCard
                        img={section_3_image_2}
                        number="2"
                        heading="Payment with a SmartCard"
                        body={<p>The customer attempts to pay for these items with a SmartCard.</p>}
                    />
                    <HomePageCard
                        img={section_3_image_3}
                        number="3"
                        heading="SmartCard payment cancelled"
                        body={<p>The payment is declined automatically by the EFTPOS terminal and a message is displayed to the cardholder.</p>}
                    />
                    <HomePageCard
                        img={section_3_image_4}
                        number="4"
                        heading="Operator notified"
                        body={<p>The beer is highlighted on the POS screen, so the operator knows which item is restricted.</p>}
                    />
                    <HomePageCard
                        img={section_3_image_5}
                        number="5"
                        heading="Customer decides"
                        body={<p>The customer can pay for the beer with a different card or cash, or remove the beer from the sale.</p>}
                    />
                </Grid>

                <Grid container className="gutter section_3">
                    <Grid item xs={0} md={4}></Grid>
                    <Grid item xs={12} md={4} className="section_6_flex_center">
                        <Button variant="yellow" onClick={goToLocation(FactsheetPLBLocation.path)} className="section_button">
                            <p className="section_button_text">
                                Learn more about PLB and how it works
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={4}></Grid>
                </Grid>
            </div>

            {/* Section 5 */}
            <Box className="section_5_background home_background home_padding_gutter">
                <Grid container className="gutter section_4">
                    <Grid item xs={12} md={12}>
                        <Typography className="section_5_header">
                            Why would I want Product Level Blocking in my business?
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="section_4_contents gutter">
                    <Grid item xs={12} md={3}>
                        <Box className="section_5_container">
                            <img
                                src={section_5_icon_1}
                                className="section_5_icon"
                                alt="" />
                            <Typography className="section_5_container_text">
                                {'Your business can easily accept the SmartCard as a form of payment if you sell any restricted items.'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className="section_5_container">
                            <img
                                src={section_5_icon_4}
                                className="section_5_icon"
                                alt="" />
                            <Typography className="section_5_container_text">
                                {'Your POS system will automatically prevent the sale of restricted items.'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className="section_5_container">
                            <img
                                src={section_5_icon_3}
                                className="section_5_icon"
                                alt="" />
                            <Typography className="section_5_container_text">
                                {'Your staff do not need to recognise the SmartCard or remember which items are restricted.'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className="section_5_container">
                            <img
                                src={section_5_icon_2}
                                className="section_5_icon"
                                alt="" />
                            <Typography className="section_5_container_text">
                                {'You can help your customers manage their money and protect them from financial abuse.'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className="gutter section_3">
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item className="section_6_flex_center" xs={12} md={6}>
                        <Button variant="yellow" onClick={goToLocation(FactsheetGoingLiveLocation.path)} className="section_5_button">
                            <p className="section_5_button_text">
                                Learn more about going live with PLB
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                </Grid>
            </Box>

            {/* Section 6 */}
            <div className="home_background home_padding_gutter">
                <Grid container className="section_6 gutter">
                    <Grid item xs={12} md={5} className="section_6_image_container">
                        <img
                            src={business_image_1}
                            className="section_6_image"
                            alt="The Hervey Bay Boat Club" />
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={6} className="section_6_container">
                        <Typography className="section_6_header">
                            Glen from The Hervey Bay Boat Club says:
                        </Typography>
                        <div className="section_6_quote_container">
                            <Typography className="section_6_quotemark section_6_quote_start">
                                “
                            </Typography>
                            <Box className="section_6_text_container">
                                <Typography className="section_6_text">
                                    “It has been a pleasure implementing the Product Level Blocking system on our EFTPOS terminals at our licensed venue.”
                                </Typography>
                            </Box>
                            <Typography className="section_6_quotemark section_6_quote_end">
                                „
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/* Section 7 */}
            <div className="home_background section_7_background home_padding_gutter">
                <Grid container className="gutter">
                    <Grid item xs={0} md={0} lg={1}></Grid>
                    <Grid item xs={12} md={6} lg={6} className="section_7_container">
                        <Typography className="section_7_header">
                            {"Want to find your way to Product Level Blocking with us?"}
                        </Typography>
                        <div className='section_7_text_container'>
                            <div className='section_7_text_section'>
                                <Typography className="section_7_text section_7_point">
                                    {'1.\t'}
                                </Typography>
                                <Typography className="section_7_text">
                                    {"Do the simple compatibility check."}
                                </Typography>
                            </div>
                            <div className='section_7_text_section'>
                                <Typography className="section_7_text section_7_point">
                                    {'2.\t'}
                                </Typography>
                                <Typography className="section_7_text">
                                    {"Follow the prompts to read and sign a Merchant Participation Agreement."}
                                </Typography>
                            </div>
                        </div>
                        <Button variant="yellow" onClick={goToLocation(CompatibilityLocation.path)} className="section_7_button">
                            <p className="section_7_button_text">
                                Check my compatibility
                            </p>
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={0.5} lg={0.5}></Grid>
                    <Grid item xs={12} md={4.5} lg={4.5} className="section_7_image_container">
                        <img
                            src={section_7_image}
                            className="section_7_image"
                            alt="" />
                    </Grid>
                </Grid>
            </div>

            {/* Section 8 */}
            <div className="home_background home_padding_gutter">
                <Grid container className="gutter section_8">
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item xs={12} md={6} className="section_8_container">
                        <Typography className="section_8_header">
                            Your business and the SmartCard
                        </Typography>
                        <Typography className="section_8_text">
                            The SmartCard can be used to pay for many different products and your business may already be accepting SmartCard payments. How you accept SmartCard payments depends on the types of products you sell.
                        </Typography>
                        <Button variant="yellow" onClick={goToLocation(FactsheetReadyLocation.path)} className="section_8_button">
                            Learn more about your business and the SmartCard
                        </Button>
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                </Grid>
            </div>

            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                scroll={"paper"}
                open={dialogPreview >= 0}
                aria-describedby="scroll-dialog-description"
                classes={{
                    paper: "home_dialog_expand_screenspace"
                }}
            >
                {dialogScreen(dialogPreview)}
            </Dialog>
        </>
    )
}
