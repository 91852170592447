import { Typography } from '@mui/material';
import React from 'react';

export interface HighlitedTitleProps {
    variant: string,
    start: string,
    highlight: string,
    end?: string
}

export const HighlightedTitle = (props: HighlitedTitleProps) => {
    const {variant, start, highlight, end} = props;

    return (
        <>
            <Typography variant={(variant === "h1") ? "h1" : "h2"} className="facts_heading_start black">
                {start} 
                <Typography variant={(variant === "h1") ? "h1" : "h2"} className="facts_heading purple">{highlight}</Typography>
                {end}
            </Typography>
        </>
    );
}