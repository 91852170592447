import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import './StepperBackButton.css';

export interface StepperBackButtonProps {
    handleBack: () => void;
}

export const StepperBackButton = (props: StepperBackButtonProps) => {
    const { handleBack } = props;

    return (
        <>
            <div className="stepperBackButton_container">
                <Button
                    variant="secondary"
                    className="stepperBackButton"
                    onClick={handleBack}
                    sx={{ py: '0.375rem' }}
                >
                    <ArrowBackIcon className="backArrow" />
                    Back
                </Button>
            </div>
        </>
    );
}
