import React from 'react';
import { Box, Typography } from '@mui/material';
import './PageNotFound.css';

export const PageNotFound = () => {
    return (
        <Box className="pageNotFound">
            <Typography className='pageNotFoundText'>Sorry, this page does not appear to exist. </Typography>
        </Box>
    )
}