import { CompatibilityLocation, ContactUsLocation, FactsheetReadyLocation, FactsheetDefinitionsLocation, FactsheetPLBLocation, FactsheetRestrictedLocation } from "../../routeConstants";
import { ElementItems } from "./FAQ";
import config from '../../config/config.json';

export const elementItems: ElementItems[] = [
  {
    label: 'compatibility check',
    path: CompatibilityLocation.path,
  },
  {
    label: 'contacting',
    path: ContactUsLocation.path,
  },
  {
    label: 'contact us',
    path: ContactUsLocation.path,
  },
  {
    label: 'contact',
    path: ContactUsLocation.path,
  },
  {
    label: 'send us a message',
    path: ContactUsLocation.path,
    hash: "#contact-us"
  },
  {
    label: 'restricted items',
    path: FactsheetRestrictedLocation.path,
  },
  {
    label: 'Product Level Blocking',
    path: FactsheetPLBLocation.path,
  },
  {
    label: 'list of terms',
    path: FactsheetDefinitionsLocation.path,
  },
  {
    label: 'removing restricted items',
    path: FactsheetRestrictedLocation.path,
  },
  {
    label: 'your business and the SmartCard',
    path: FactsheetReadyLocation.path,
  },
  {
    label: 'Services Australia Smartcard page',
    isExternalLink: true,
    path: config.SA_EIM_WEBSITE,
  },
  {
    label: 'PLB-accredited point of sale (POS) system',
    path: FactsheetPLBLocation.path,
    isLink: true,
    hash: "#PLB-accredited"    
  },
  {
    label: 'cash-like products',
    path: '',
    isLink: false,
    isDialog: true,
    dialog_title: "Cash-like products",
    dialog_text: "A product that can be used like cash to purchase alcohol, gambling services, tobacco or pornography, or to withdraw cash. Examples include some gift cards, prepaid cards or e-vouchers."
  },
  {
    label: 'group or chain',
    path: '',
    isLink: false,
    isDialog: true,
    dialog_title: "Group or chain",
    dialog_text: "A business which owns more than one store."
  },


];