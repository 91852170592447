import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HomeLocation } from '../../../routeConstants';
import './MpaSubmitted.css';
import '../../../css/Mpa.css';
import { MerchantInformation } from '../../../Models/MerchantInformation';

export interface MpaSubmittedProps {
    merchantInformation: MerchantInformation;
}

export const MpaSubmitted = (props: MpaSubmittedProps) => {
    const navigate = useNavigate();
    const {firstName, email} = props.merchantInformation;

    const goToLocation = (location: string) => {
        window.scrollTo(0, 0);
        navigate(location);
    }

    return (
        <>
            <Box className="background mpaSubmitted_background">
                <Box className="dialogueBox mpaSubmitted_dialogueBox">
                    <Typography sx={{ mb:"1.375rem" }} variant="h1" className='mpaThankYou' tabIndex={0}>
                    Thanks {firstName}
                    </Typography>
                    <Typography sx={{ mb:"1.375rem" }} variant="h5" className="body" tabIndex={0}>
                    Your Merchant Participation Agreement has been sent to the Merchant Engagement Team at Services Australia.
                    </Typography>
                    <Typography sx={{ mb:"1.375rem" }} variant="h5" className="body" tabIndex={0}>                    
                    The Merchant Engagement Team will contact you soon to confirm your details, countersign your agreement and discuss next steps.
                    </Typography>
                    <Typography variant="h5" className="body" tabIndex={0}>
                    A copy of your agreement has also been sent to your email address:
                    </Typography>
                    <Typography sx={{ mb:"3.125rem" }} variant="h5" className="body-margin-bottom" tabIndex={0}>                    
                    {email}.
                    </Typography> 
                    <Button                        
                        onClick={() => goToLocation(HomeLocation.path)}
                    >
                    Back to home
                    </Button>
                </Box>
            </Box>
        </>
    )

}
