import React from 'react';
import { Typography } from '@mui/material'
import '../../../../DialogTemplate/DialogTemplate.css'

interface ExampleDialogProps {
    cashProducts: boolean;
    tobaccoProducts: boolean;
}

export const ExampleDialog = (props: ExampleDialogProps) => {
    const {
        cashProducts,
        tobaccoProducts
    } = props;

    if (cashProducts) {
        return (
            <>
                <Typography className='dialog_title_bold'>
                    Examples of restricted cash-like products
                </Typography>
                <div className='dialog_text_container'>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Mastercard, Visa and eftpos gift and prepaid cards</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Reloadable prepaid cards</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>BWS, Liquorland or Dan Murphy's gift cards</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>eBay gift cards</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Coles Group, Myer or Woolworths Group gift cards</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Neosurf e-vouchers</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>paysafecard e-vouchers</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Flexepin e-vouchers</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Any other gift card, prepaid card or e-voucher that can be used as cash or to purchase alcohol, gambling services, tobacco or pornography</Typography>
                    </div>
                </div>
            </>
        )
    }
    if (tobaccoProducts) {
        return (
            <>
                <Typography className='dialog_title_bold'>
                    Examples of tobacco products
                </Typography>
                <div className='dialog_text_container'>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text'>Examples of tobacco and tobacco products: </Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Cigarettes</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Cigars</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Cigarette papers</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Cigarette rollers</Typography>
                    </div>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text_point'>{"·\t"}</Typography>
                        <Typography className='dialog_text'>Pipes</Typography>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
        </>
    )
}