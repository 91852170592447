import { Grid } from '@mui/material';
import logo from '../../../Assets/logos/services_aust_logo.png';
import './Agreement.css';
import '../../../css/Mpa.css';
import '../../../css/App.css';
import { MerchantInformation } from '../../../Models/MerchantInformation';

export interface AgreementProps {
    merchantInformation: MerchantInformation;
}

export const Agreement = (props: AgreementProps) => {
    const { legalName, businessName, abn, fullAddress } = props.merchantInformation;
    const mode = "display";

    const getClassName = (category : string) => {
        return "agreement_" + mode + "_" + category;
    }

    // Show blank space manual fill-in if legal entity name could not be auto-filled by ABN Lookup
    const displayLegalName = legalName ? legalName : '_'.repeat(70);
    

    return (
        <>
            <div className={getClassName("divWidth")}>   
                <img src={logo} alt="" className={getClassName("dssLogo")} />
				<p className={getClassName("heading")}><b>Product Level Blocking Merchant Participation Agreement</b></p>
                <p className={getClassName("body")}><b>Between</b> <span className='wrapAnywhere'>{displayLegalName}</span> <span className='noWrap'>ABN {abn}</span> trading as {businessName} of {fullAddress} <b>'Merchant'</b></p>
				<p className={getClassName("body")}><b>And the </b>Commonwealth of Australia, as represented by Services Australia ABN 90 794 605 008 of 57 Athllon Drive Greenway ACT 2900, <b className={getClassName("no_wrap")}>‘Services Australia’</b>.</p>
                
                <p className={getClassName("body")}>The purpose of this Agreement is to facilitate the Merchant’s ability to accept payments from holders of a SmartCard issued under the enhanced Income Management (eIM) program.</p>
                <p className={getClassName("body")}>This Agreement outlines the respective undertakings of the Merchant and Services Australia regarding the Merchant’s participation in the Product Level Blocking (PLB) program (the Program).</p>
                <p className={getClassName("body")}>The objective of the Program is to implement an automated method for preventing SmartCard purchases of Restricted Items at the Merchant’s place of business.</p>
                <p className={getClassName("body")}>Restricted Items include alcohol, gambling services, tobacco, pornography and items that can be used in a cash-like manner, such as digital currency, gift cards and prepaid cards.</p>

                <p className={getClassName("body")}>Services Australia, as supported by its third party service providers relevant to the Program, undertakes to:</p>
                
                <ul className={getClassName("ul")}>
                    <li className={getClassName("li")}>
                        engage with the Merchant’s supplier of point of sale software (POS Provider)</li>
                    <li className={getClassName("li")}>
                        engage with the Merchant’s card payments acquiring institution (Acquirer)</li>
                    <li className={getClassName("li")}>
                        work with the POS Provider and Acquirer to update the Merchant’s POS System, and</li>
                    <li className={getClassName("li")}>
                        engage the POS Provider to provide training to the Merchant’s staff at no cost to the Merchant.</li>
                </ul >
                <p className={getClassName("body")}>Services Australia undertakes to:</p>
                <ul className={getClassName("ul")}>
                    <li className={getClassName("li")}>
                    assist the Merchant with identification of Restricted Items in the Merchant's product range.</li>
                </ul >
                <p className={getClassName("body")}>In consideration for Services Australia facilitating the Merchant’s ability to accept payments from SmartCard holders, updating the Merchant’s POS system software, providing training and operational support, the Merchant undertakes to:</p>
                <ul className={getClassName("ul")}>
                    <li className={getClassName("li")}>
                        permit the Merchant’s POS system to be updated with changes required to implement PLB at the Merchant’s place of business</li>
                    <li className={getClassName("li")}>
                        identify all Restricted Items in the Merchant's product range during the period of the Merchant's participation in the Program</li>
                    <li className={getClassName("li")}>
                        co-operate with third party service providers engaged by Services Australia to deliver the Program, and</li>
                    <li className={getClassName("li")}>
                        provide feedback upon request to Services Australia regarding any impact upon the Merchant’s customers and staff resulting from the Merchant’s participation in the Program.</li>
                </ul>

                <p className={getClassName("body")}>The Merchant consents to allow SmartCard purchase transaction data from the Merchant’s POS system to be uploaded and stored in a secure central database to be used only for the purpose of managing and evaluating the Program, noting that any information collected will be de-identified before storing.</p>               
                <br />

                <div className="agreement_table">
                    <Grid container>
                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            <b>Signed for the Merchant:</b>
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>

                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            Name:
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>
                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            Position:
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>

                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            <b>Signed for Services Australia:</b>
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>

                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            Agency representative:
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>
                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            Position:
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>

                        <Grid item xs={12} md={3} className='agreement_text_rightAlign' sx={{mb: '45px'}}>
                            Date:
                        </Grid>
                        <Grid item xs={12} md={9} sx={{mb: '45px', borderBottom: '1px', borderBottomStyle: 'solid'}}/>

                    </Grid>
                </div>
            </div>
        </>
    );
}
