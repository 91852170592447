import GlobalFonts from './Assets/fonts/fonts';
import React, { useEffect } from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UnsupportedPage } from './Components/UnsupportedPage';
import RouteChangeTracker from './RouteChangeTracker';
import ScrollToSectionHandler from './utils/ScrollToSectionHandler';
import ReactGA from 'react-ga4';
import { UAParser } from 'ua-parser-js';
import { Helmet } from 'react-helmet';
import { PageTitles } from "./utils/PageTitles";
import '../src/css/App.css';

const TRACKING_ID = `${process.env.REACT_APP_TRACKING_ID}`;
ReactGA.initialize(TRACKING_ID);

const KEYWORDS = `${process.env.REACT_APP_KEYWORDS}`;
const ROBOT_KEYWORDS = `${process.env.REACT_APP_ROBOTS_KEYWORDS}`;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#500A78'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderRadius: '6.25rem',
          opacity: '1',
          boxShadow: '0rem 0.125rem 0.25rem #60617029',
          textTransform: 'none',
          minWidth: '150px',
          paddingLeft: '1.875rem',
          paddingRight: '1.875rem',
          paddingTop: '0.625rem',
          paddingBottom: '0.625rem',
          font: 'normal normal 600 1rem/1.75rem Open Sans',
          letterSpacing: '0.01875rem',
          background: 'var(--primary-purple)',
          color: 'var(--primary-white)',
          border: '0.125rem solid var(--primary-purple)',
          boxSizing: 'border-box',
          '&:hover': {
            background: 'var(--secondary-yellow)',
            color: 'var(--primary-black)',
            border: '0.125rem solid var(--primary-purple)',
          },
          '&:disabled': {
            color: 'var(--primary-white)',
            background: 'var(--disabled-button-gray)',
            border: '0.125rem solid var(--disabled-button-gray)',
          },
        }
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            background: 'var(--primary-purple)',
            color: 'var(--primary-white)',
            '&:hover': {
              background: 'var(--secondary-yellow)',
              color: 'var(--primary-black)',
              border: '0.125rem solid var(--primary-purple)',
            }
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: 'var(--primary-white)',
            color: 'var(--primary-purple)',
            border: '0.125rem solid var(--primary-purple)',
            '&:hover': {
              background: 'var(--primary-purple)',
              color: 'var(--primary-white)',
              border: '0.125rem solid var(--primary-purple)',
            }
          }
        },
        {
          props: { variant: 'esignature' },
          style: {
            background: '#F0F0F0',
            border: '0.0625rem solid #A5A5A5',
            borderRadius: '0.313rem',
            opacity: '1',
            color: 'var(--primary-black)',
            padding: '0.313rem',
            marginLeft: '2.25rem',
            '&:hover': {
              background: 'var(--disabled-button-gray)',
            }
          }
        },
        {
          props: { variant: 'yellow' },
          style: {
            background: 'var(--primary-white)',
            color: 'var(--primary-black)',
            border: '0.1875rem solid var(--secondary-yellow)',
            paddingTop: '0.4375rem',
            paddingBottom: '0.4375rem',
            '&:hover': {
              background: 'var(--secondary-yellow)',
              color: 'var(--primary-black)',
              border: '0.1875rem solid var(--secondary-yellow)',
            }
          }
        },
        {
          props: { variant: 'esignature' },
          style: {
            background: '#F0F0F0',
            border: '0.0625rem solid #A5A5A5',
            borderRadius: '0.313rem',
            opacity: '1',
            color: 'var(--primary-black)',
            padding: '0.3125rem',
            marginLeft: '2.25rem',
            '&:hover': {
              background: 'var(--disabled-button-gray)',
            }
          }
        }
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: '0.3',
          width: '100%',
          backgroundColor: '#707070'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          textAlign: 'left',
          fontFamily: 'Open Sans',
          fontSize: '0.8125rem',
          fontWeight: 'normal',
          lineHeight: '1.0625rem',
          letterSpacing: '0.020625rem',
          color: '#000000DE',
          '&.Mui-active': {
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '1.188rem',
          height: '1.188rem',
          '&.Mui-completed': {
            color: '#178639'
          }
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          height: '1px',
          background: '#BBBABB'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontFamily: 'Open Sans',
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0.033125rem',
          color: '#505050',
          '&.Mui-disabled': {
            color: '#636363'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          textAlign: 'left'
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: '#636363'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-error:before": {
            borderBottomColor: '#d32f2f !important',
            borderBottomWidth: '2px'
          },
          '&:before': {
            borderBottomColor: 'transparent !important'
          },
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '0.9375rem',
          '&.Mui-error': {
            color: 'var(--error-red)',
            opacity: '1',
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color:'var(--primary-purple)',
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        },
        icon: {
          padding: '0.6rem 0'
        }
      }
    }
  },
  typography: {
    h1: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '2.25rem',
      fontWeight: 'bold',
      lineHeight: '2.8125rem',
      letterSpacing: '0.015rem',
      color: 'var(--dss-dark-green-text)'
    },
    h2: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.875rem',
      fontWeight: 'bold',
      lineHeight: '2.5rem',
      letterSpacing: '0rem',
      color: 'var(--primary-black)'
    },
    h3: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.6875rem',
      fontWeight: '600',
      lineHeight: '2rem',
      letterSpacing: '-0.0425rem',
      color: 'var(--dss-dark-green-text)'
    },
    h4: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.375rem',
      letterSpacing: '0.01125rem',
      color: 'var(--primary-purple)',
    },
    h5: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: '1.625rem',
      letterSpacing: '-0.028125rem',
      color: 'var(--primary-black)'
    },
    body1: {
      // Body text on forms
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
      letterSpacing: '0.03125rem',
      color: 'var(--primary-black)'
    },
    body2: {
      // Body text on homepage
      textAlign: 'left',
      fontFamily: 'Open Sans',
      fontSize: '1.1875rem',
      fontWeight: '500',
      lineHeight: '1.625rem',
      letterSpacing: '0rem',
      color: 'var(--primary-black)'
    },
    caption: {
      justifySelf: 'flex-start',
      fontFamily: 'Open Sans',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: '0.3125rem',
      letterSpacing: '0rem',
      color: 'var(--primary-black)',
      opacity: '0.72'
    }
  }
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: boolean;
    secondary: boolean;
    esignature: boolean;
    yellow: boolean;
  }
}

const unsupportedBrowsers = ["IE"]

export const App = () => {
  const [isSupported, setIsSupported] = React.useState(true);

  useEffect(() => {
    try {
      let parser = new UAParser();
      let result = parser.getBrowser();
      if(unsupportedBrowsers.find(e => e === result.name)) {
          setIsSupported(false);
          ReactGA.event({
              category: 'User',
              action: 'Unsupported Browser',
              label: 'Unsupported Browser - ' + result.name
          });
      } else {
        setIsSupported(true);
      }
    }
    catch {
      setIsSupported(true);
    }
  }, []);

  return (
    <div className="root">
      <GlobalFonts />
      <Helmet>
        <title>{PageTitles.Home}</title>
        <meta
          name="description"
          content="Information about SmartCard and enhanced Income Management for merchants, retailers and small business."
        />
        <meta name="keywords" content={KEYWORDS}/>
        <meta name="author" content="Services Australia "/>
	      <meta name="robots" content={ROBOT_KEYWORDS}/>
      </Helmet>
      <ThemeProvider theme={theme}>
        {isSupported &&
          <BrowserRouter>
            <RouteChangeTracker />
            <ScrollToSectionHandler />
            <Header />
            <Routes />
            <Footer />
          </BrowserRouter>
        }
        {!isSupported &&
          <UnsupportedPage/>
        }
      </ThemeProvider >
    </div>
  );
}
