import { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  IconButton,
  Link
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';

import { FAQitems } from "./FAQList";
import { elementItems } from "./FAQTags";
import { ContactInfo } from "../Common/ContactInfo";
import { makeStyles } from 'tss-react/mui';
import faq_banner_img from '../../Assets/backgrounds/FAQ_Banner.png'
import faq_banner_img_mobile from '../../Assets/backgrounds/FAQ_Banner_mobile.png'
import { DialogTemplate } from '../DialogTemplate';
import { CustomDialog } from "../Common/Dialogs/CustomDialog";
import ClearIcon from '@mui/icons-material/Clear';
import config from "../../config/config.json";
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { PageTitles } from "../../utils/PageTitles";
import { clearAllBodyScrollLocks, enableBodyScroll } from "body-scroll-lock";
import { isAndroid, isApple, isMobileDevice } from '../Common/DeviceCheck/DeviceCheck';
import '../../css/App.css'

export interface FAQItem {
  faqSubIndex?: number;
  faqSubHeader: string;
  faqText: string;
  expanded?: boolean;
}

export interface FAQHead {
  faqIndex?: number;
  faqHeader: string;
  expanded?: boolean
  faqList: FAQItem[];
}

export interface ElementItems {
  label: string;
  path: string;
  hash?: string;
  isLink?: boolean;
  isExternalLink?: boolean;
  isDialog?: boolean;
  dialog_title?: string;
  dialog_text?: string;
}

FAQitems.forEach((item, index) => {
  item.faqIndex = index + 1;
  item.expanded = false;
  item.faqList.forEach((subItem, subIndex) => {
    subItem.faqSubIndex = subIndex + 1;
    subItem.expanded = false;
  })
});

const useStyles = makeStyles()((theme) => {
  return {
    faq_gutter: {
      maxWidth: '86.875rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    faq_box: {
      display: 'flex',
      flexDirection: 'column'
    },
    faq_banner: {
      background: 'url(' + faq_banner_img + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      paddingBottom: '5rem',
      "@media (max-width: 1023px)": {
        background: 'url(' + faq_banner_img_mobile + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        paddingBottom: '8.125rem',
        paddingLeft: '5%',
        paddingRight: '5%'
      }
    },
    faq_title: {
      font: 'normal normal bold  2.187rem/2.937rem Open Sans',
      letterSpacing: '0.011rem',
      color: '#000000',
      paddingTop: '3.75rem',
      paddingBottom: '2.1875rem'
    },
    faq_input_box: {
      borderRadius: '0.3125rem 0.3125rem 0rem 0rem',
      maxWidth: '86.875rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      background: '#F2F2F2 0% 0% no-repeat padding-box',
      "& .MuiOutlinedInput-root": {
        "& input": {
          fontSize: '1rem',
          fontWeight: '600',
          lineHeight: '1rem',
          letterSpacing: '0.0075rem',
          color: '#2C1936'
        },
        "& input::placeholder": {
          fontWeight: 'normal',
          color: '#505050',
          opacity: 1,
        },
        "& fieldset": {
          border: "none"
        },
      }
    },
    faq_banner_text: {
      font: 'normal normal normal 1rem/1.625rem Open Sans',
      letterSpacing: '0.0125rem',
      color: '#222222',
      padding: '1.875rem 5% 1.5625rem',
    },
    toggleButton: {
      fontFamily: "Open Sans",
      borderRadius: "3.125rem",
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: '1.562rem',
      letterSpacing: '0rem',
      width: '100%',
      color: 'var(--primary-purple)',
      '&:hover': {
        color: 'var(--primary-white)',
      },
    },
    faq_panel: {
      padding: '2.75rem 2% 7.5rem',
      "@media (max-width: 1023px)": {
        padding: '0.75rem 2% 7.5rem',
      }
    },
    faq_search_result_msg: {
      color: "#222222",
      textAlign: "left",
      font: "normal normal bold 1rem/1.625rem Open Sans",
      letterSpacing: "0.0125rem",
    },
    faq_accordion: {
      color: 'var(--primary-purple)',
      boxShadow: 'none',
      '&::before': {
        display: 'none'
      }
    },
    faq_AccordionSummary: {
      '&.Mui-expanded':{
        minHeight:"unset",
      },
      '& div': {
        alignItems: 'center',
        margin:'0.5rem 0',
        '&.Mui-expanded':{
          margin:'0',
        },
      }
    },
    faq_AccordionSummary_header_wrapper: {
      display: "flex",
      alignItems: "center",
      margin:"0 0 1rem"
    },
    faq_AccordionSummary_header: {
      font: 'normal normal bold 1.687rem/2rem Open Sans',
      letterSpacing: '-0.0425rem',
      color: 'var(--primary-purple)',
      textAlign: 'left',
    },
    faq_headerIcon: {
      marginLeft: '0.3125rem'
    },
    faq_sub_Accordion: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0rem 0.375rem 0.625rem #00000008',
      border: '0.0625rem solid #E5EAF4',
      borderRadius: '0.5rem',
      marginTop: '0.625rem',
      padding: '2.0625rem 4%',
      '&::before': {
        display: 'none'
      },
      "@media (max-width: 1023px)": {
        "& .MuiAccordionSummary-content": {
          marginRight: "0.3125rem"
        }
      }
    },


    faq_sub_AccordionSummary_header: {
      font: 'normal normal bold  1.25rem/1.5rem Open Sans',
      letterSpacing: '0.0125rem',
      color: '#005A70',
      textAlign: 'left',

    },
    faq_sub_text: {
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      font: 'normal normal normal 1.125rem/2rem Open Sans',
      letterSpacing: '0rem',
      color: '#5A7184',
      overflowWrap: "break-word",
      wordBreak: "break-word"
    },
    faq_dialog_info_icon: {
      verticalAlign: "middle",
      font: "normal normal normal 1.125rem/2rem Open Sans",
      color: 'var(--primary-purple)',
      textDecoration: "none",
      cursor: "pointer",
    },
    faq_link_text: {
      textAlign: "left",
      textDecoration: "none",
      borderBottom: "0.0625rem solid var(--primary-purple)",
      font: "normal normal normal 1.125rem/2rem Open Sans",
      letterSpacing: "0rem",
      color: 'var(--primary-purple)',
      cursor: "pointer"
    },
    faq_new_line: {
      margin: "0rem",
      lineHeight:"0.4rem" 
    },
    faq_contact_panel: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "1.25rem 5% 5rem"
    },
    ListItem: {
      display: "list-item",
      marginLeft: "1.4375rem",
      paddingLeft: "1.25rem"
    },
    NumberItem: {
      display: "list-item",
      marginLeft: "1.4375rem",
      paddingLeft: "1.25rem",
      listStyleType: "decimal"
    },
  };
});

export const FAQ = () => {
  const [globalExpanded, setExpanded] = useState(() => getGlobalExpanded());
  const [faqItems, setFaqItems] = useState(() => getFAQ());
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [dialogPreview, setDialogPreview] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');
  const [searchValue, setSearchValue] = useState(() => getSearchTerm());
  const [searchResultLength, setSearchResultLength] = useState(() => getSearchResultLength());;

  const goToLocation = useCallback(
    (location: string, isExternalLink?: boolean) => {
      if (isExternalLink === true) {
        window.open(location, "_blank")
        ReactGA.event({
          category: 'User',
          action: 'Opened External Link from FAQ page',
          label: 'FAQ Link: ' + location
        });
      }
      else {
        localStorage.setItem("storeDataFlag", "true");
        localStorage.setItem("storedSearchTerm", searchValue);
        localStorage.setItem("storedSearchResultLength", searchResultLength.toString());
        localStorage.setItem("storedGlobalExpanded", globalExpanded.toString());
        localStorage.setItem("storedFAQ", JSON.stringify(faqItems));
        localStorage.setItem("contentYOffset", String(window.scrollY));
        window.scrollTo(0, 0);
        navigate(location);
      }
    },
    [faqItems, globalExpanded, navigate, searchResultLength, searchValue],
  )

  const GoToLocationOnClick = useCallback(
    (location: string, isExternalLink?: boolean) => (e: React.MouseEvent<HTMLSpanElement>) => {
        goToLocation(location, isExternalLink);
    },
    [goToLocation]
  )

  const GoToLocationOnEnter = useCallback(
    (location: string, isExternalLink?: boolean) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if(e.key === "Enter") {
        goToLocation(location, isExternalLink);
      }
    },
    [goToLocation]
  )

  function getFAQ() {
    let storedFAQString = localStorage.getItem("storedFAQ");
    if (storedFAQString) {
      let storedFAQ: FAQHead[] = JSON.parse(storedFAQString);
      if (storedFAQ) {
        return storedFAQ;
      }
    }
    return FAQitems;
  }

  function getSearchTerm() {
    let storedSearchTermString = localStorage.getItem("storedSearchTerm");
    return storedSearchTermString ? storedSearchTermString : "";
  }

  function getSearchResultLength() {
    let storedSearchResultLength = localStorage.getItem("storedSearchResultLength");
    return storedSearchResultLength ? storedSearchResultLength : 0
  }

  function getGlobalExpanded() {
    let storedGlobalExpand = localStorage.getItem("storedGlobalExpanded");
    return storedGlobalExpand ? storedGlobalExpand : false;
  }
  
  const handleToggle = useCallback(
    (status: boolean) => (e: React.MouseEvent<HTMLSpanElement>) => {
      if (searchValue.length > 0) {
        let updatedFAQList = faqItems.map(item => {
          let updatedFAQSubList = item.faqList.map(sub => { return { ...sub, expanded: status }; });
  
          return {
            ...item,
            expanded: true,
            faqList: updatedFAQSubList
          }
        });
  
        setFaqItems(updatedFAQList);
      }
      else {
        let updatedFAQList = faqItems.map(item => {
          let updatedFAQSubList;
          updatedFAQSubList = item.faqList.map(sub => { return { ...sub, expanded: status }; })
          return {
            ...item,
            expanded: status,
            faqList: updatedFAQSubList
          }
        });
  
        setFaqItems(updatedFAQList);
      }
      setExpanded(status);
    },
    [faqItems, searchValue.length],
  )
  
  const handleFAQChange = useCallback(
    (faqIndex?: number) => (event: React.SyntheticEvent<Element>) => {
      let updatedFAQList = faqItems.map(item => {
        if (item.faqIndex === faqIndex) {
          const updatedFAQSubList = item.faqList.map(sub => { return { ...sub, expanded: false }; });
          return { ...item, expanded: !item.expanded, faqList: updatedFAQSubList };
        }
        return item;
      });
      setFaqItems(updatedFAQList);
    },
    [faqItems],
  )
  
  const handleFAQSubItemChange = useCallback(
    (faqIndex?: number, faqSubIndex?: number) => (event: React.SyntheticEvent<Element>) => {
      let updatedFAQList = faqItems.map(item => {
        if (item.faqIndex === faqIndex) {
          let updatedFAQSubList = item.faqList.map(subItem => {
            if (subItem.faqSubIndex === faqSubIndex) {
              return { ...subItem, expanded: !subItem.expanded };
            }
            return subItem;
          });
          return { ...item, faqList: updatedFAQSubList };
        }
        return item;
      });
      setFaqItems(updatedFAQList);
    },
    [faqItems],
  )
  
  const clearSearchValue = useCallback(
    () => {
        setSearchValue("");
    },
    [setSearchValue],
  )
  
  // Phone link
  const getPhoneLink = useCallback(
    () => {
        if (isAndroid()) {
            return ("tel:$(" + config.CONTACT_NUMBER + ")");
        } else if (isApple()) {
            return ("telprompt:$(" + config.CONTACT_NUMBER + ")");
        }
    },
    [],
  )

  const assignSearchValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Search " + event.target.value);
        setSearchValue(event.target.value);
    },
    [setSearchValue],
  )

  const renderFAQText = (text: string) => {
    return text.split(/<dotpoint>(.*?)<\/dotpoint>/g).map((line, index) => {
      if (index % 2) {
        return <span className={classes.ListItem} key={'list' + index}>{formatFAQText(line)}</span>;
      }
      else {
        return line.split(/<numbered-point>(.*?)<\/numbered-point>/g).map((subline, subInd) => {
          if (subInd % 2) {
            return <span className={classes.NumberItem} key={'sublist' + subInd}>{formatFAQText(subline)}</span>;
          }
          else {
            return formatFAQText(subline);
          }
        });
      }
    });
  };

  const formatFAQText = (text: string) => {
    return text.split(/<clickable>(.*?)<\/clickable>/g).map((line, index) => (
      mapFAQText(line, index)
    ));
  };

  const mapFAQText = (text: string, index: number) => {
    const result = elementItems.find(i => i.label === text);
    if (result) {
      if (result.isDialog === true && result.isLink === true) {
        return <span key={'clickable' + index}><span tabIndex={0} className={classes.faq_link_text} onKeyPress={GoToLocationOnEnter(result.path + "" + (result.hash || ''), result.isExternalLink)} onClick={GoToLocationOnClick(result.path + "" + (result.hash || ''), result.isExternalLink)}>{text}</span>
          <span tabIndex={0} onKeyPress={openDialogOnEnter(result.dialog_title, result.dialog_text)} onClick={openDialogOnClick(result.dialog_title, result.dialog_text)} className={classes.faq_link_text}>
            <InfoIcon className={classes.faq_dialog_info_icon} /></span></span>
      }
      else if (result.isDialog === true) {
        return <span role="button" tabIndex={0} onKeyPress={openDialogOnEnter(result.dialog_title, result.dialog_text)} onClick={openDialogOnClick(result.dialog_title, result.dialog_text)} key={'clickable' + index}>
          <span className={classes.faq_link_text} >{text}</span><InfoIcon className={classes.faq_dialog_info_icon} /></span>
      }
      else {
        return <span role="link" tabIndex={0} className={classes.faq_link_text} key={'clickable' + index} onKeyPress={GoToLocationOnEnter(result.path + "" + (result.hash || ''), result.isExternalLink)} onClick={GoToLocationOnClick(result.path + "" + (result.hash || ''), result.isExternalLink)}>{text}</span>
      }
    }
    
    /* split the text on <a>...</a> and replace value between the tag with actual element
      ind % 2 will always have value between the tag
          split will return the array: 
                first element of array will be the text before the tag
                second elemnt of array will be the text between the the tag
                last elment of array will be the text after the tag
    */
   
    return text.split(/<email>(.+?)<\/email>/g).map((a, ind) =>
      ind % 2 ?
        (<a className={classes.faq_link_text} key={'email' + index} href={"mailto:" + config.ServicesAustralia_EMAIL}>{config.ServicesAustralia_EMAIL}</a>) :
        (a.split(/<phone>(.+?)<\/phone>/g).map((a1, ind1) => ind1 % 2 ?
          (isMobileDevice() ? <><Link className='noWrap' href={getPhoneLink()}>{config.CONTACT_NUMBER}</Link></> : <span className='noWrap'>{config.CONTACT_NUMBER}</span>) : (a1.split(/<i>(.+?)<\/i>/g).map((iText, ind2) => ind2 % 2 ? (<i key={'i' + ind2}>{iText}</i>) : 
          (iText.split(/([\n]{2,})/g).map((nText, ind3) => ind3 % 2 ? (<p key={'newLine' + ind3} className={classes.faq_new_line}>{nText}</p>) : nText)))))
        )
    )
  }

  const openDialog = useCallback(
    (dialog_title?: string, dialog_text?: string) => {
      setDialogPreview(true);
      setDialogTitle(dialog_title || '');
      setDialogText(dialog_text || '');
    },
    [],
  )
  
  const openDialogOnClick = useCallback(
    (dialog_title?: string, dialog_text?: string) => (event: React.MouseEvent<HTMLInputElement>) => {
      openDialog(dialog_title, dialog_text);
    },
    [openDialog],
  )
  
  const openDialogOnEnter = useCallback(
    (dialog_title?: string, dialog_text?: string) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if(e.key === "Enter") {
        openDialog(dialog_title, dialog_text);
      }
    },
    [openDialog],
  )

  const closeDialog = useCallback(
    () => {
      const targetElement = document.getElementById("popup");
      if(targetElement != null) {
          enableBodyScroll(targetElement);
      }
      setDialogPreview(false);
      setDialogTitle('');
      setDialogText('');
      },
    [],
  )

  const dialogScreen = (dialogTitle: string, dialogText: string) => {
    return (
      <DialogTemplate
        handleClose={closeDialog}>
        <CustomDialog dialog_title={dialogTitle} dialog_text={dialogText} />
      </DialogTemplate>
    )
  }

  const resetStoredData = () => {
    localStorage.removeItem("storeDataFlag");
    localStorage.removeItem("storedSearchTerm");
    localStorage.removeItem("storedSearchResultLength");
    localStorage.removeItem("storedGlobalExpanded");
    localStorage.removeItem("storedFAQ");
    localStorage.removeItem("contentYOffset");
  }

  useEffect(() => {
    let searchResults: FAQHead[] = [];
    let storedStatus = localStorage.getItem("storeDataFlag");

    if (storedStatus !== "true") {
      setExpanded(false);
      if (searchValue.length > 0) {
        const resItems = FAQitems.reduce((resArray: FAQItem[], f) => {
          const filteredFAQ = f.faqList.filter(x => (x.faqSubHeader.toLowerCase().includes(searchValue.toLowerCase()) || x.faqText.toLowerCase().includes(searchValue.toLowerCase())))
          resArray.push(...filteredFAQ);
          return resArray;
        }, []);
        setSearchResultLength(resItems.length);
        setExpanded(getGlobalExpanded());

        const formattedResSet = resItems.map((item, index) => {
          return {
            ...item,
            faqSubIndex: index + 1,
            expanded: false,
          }
        });

        searchResults.push({
          faqHeader: '',
          faqIndex: 1,
          faqList: formattedResSet,
          expanded: true
        })
      }
      else {
        setSearchResultLength(0);
        setExpanded(false);
        searchResults = [...FAQitems];
      }

      setFaqItems(searchResults);
    }
    else {
      let storedcontentYOffset = localStorage.getItem("contentYOffset");
      if (storedcontentYOffset) {
        const scrollTimeout = setTimeout(() => {
          console.log(storedcontentYOffset);
          window.scrollTo({
            behavior: "smooth",
            top: storedcontentYOffset ? Number(storedcontentYOffset) : 0,
            left: 0
          });
          resetStoredData();
        }, 100);
        return () => clearTimeout(scrollTimeout);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
  }
  }, [searchValue]);

  return <>
    <Helmet>
      <title>{PageTitles.FrequentlyAskedQuestions}</title>
    </Helmet>
    <Box>
      <Box className={classes.faq_banner}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} className={classes.faq_box}>
            <Typography className={classes.faq_title}>Frequently Asked Questions</Typography>
            <TextField
              placeholder="Search Frequently Asked Questions"
              type="text"
              classes={{ root: classes.faq_input_box }}
              fullWidth
              onChange={assignSearchValue}
              value={searchValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchValue && (
                  <IconButton
                    onClick={clearSearchValue}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
            <Typography className={classes.faq_banner_text}>To quickly find the answer you need, enter keywords that match your query such as ‘restricted items’ or ‘Product Level Blocking’.
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={4}>
                <Button variant="secondary" className={classes.toggleButton}
                  onClick={handleToggle(!globalExpanded)}>
                  {globalExpanded ? <HorizontalRuleIcon /> : <AddIcon />}
                  {globalExpanded ? 'Contract all FAQs' : ' Expand all FAQs'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.faq_gutter}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} >


            <Box className={classes.faq_panel}>
              {searchValue && <span className={classes.faq_search_result_msg}>
                {'Showing '} <u>{searchResultLength}</u>  {'  results for "' + searchValue + '"'}
              </span>}
              {faqItems.map((item) => (
                < Accordion TransitionProps={{ timeout: 500 }} key={item.faqIndex} expanded={item.expanded}
                  className={classes.faq_accordion}
                  onChange={handleFAQChange(item.faqIndex)}>
                  <AccordionSummary
                    aria-expanded={item.expanded}
                    aria-controls={"faq-" + item.faqIndex + "-content"} id={"faq-" + item.faqIndex + "-header"}
                    className={classes.faq_AccordionSummary}>
                    {item.faqHeader &&
                      <span className={classes.faq_AccordionSummary_header_wrapper}>

                        <Typography className={classes.faq_AccordionSummary_header}>
                          {item.faqHeader}
                        </Typography>
                        {item.expanded ?
                          <ExpandLessIcon className={classes.faq_headerIcon} /> :
                          <ExpandMoreIcon className={classes.faq_headerIcon} />}
                      </span>}
                  </AccordionSummary>
                  <AccordionDetails id={"faq-" + item.faqIndex + "-content"} aria-labelledby={"faq-" + item.faqIndex + "-header"} >
                    {item.faqList.map((subitem) => (
                      <Accordion TransitionProps={{ timeout: 500 }} key={item.faqIndex + "sub" + subitem.faqSubIndex}
                        expanded={subitem.expanded}
                        className={classes.faq_sub_Accordion}
                        id={"faq-" + item.faqIndex + "-" + subitem.faqSubIndex + "-parent"}
                        onChange={handleFAQSubItemChange(item.faqIndex, subitem.faqSubIndex)}>
                        <AccordionSummary
                          expandIcon={subitem.expanded ? <HorizontalRuleIcon /> : <AddIcon />}
                          aria-expanded={subitem.expanded}
                          aria-controls={"faq-" + item.faqIndex + "-" + subitem.faqSubIndex + "content"}
                          id={"faq-" + item.faqIndex + "-" + subitem.faqSubIndex + "-header"}>
                          <Typography className={classes.faq_sub_AccordionSummary_header}>
                            {subitem.faqSubHeader}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails id={"faq-" + item.faqIndex + "-" + subitem.faqSubIndex + "content"}
                          aria-labelledby={"faq-" + item.faqIndex + "-" + subitem.faqSubIndex + "-header"} >
                          <Typography className={classes.faq_sub_text}>
                            {renderFAQText(subitem.faqText)}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.faq_contact_panel}>
        <ContactInfo
          text="If you would like more information or to speak to someone about signing up for Product Level Blocking, please contact our Merchant Engagement Team at Services Australia."
          textWidth="56.25rem"
          source=" - FAQ Page" />
      </Box>
    </Box>
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      scroll={"paper"}
      open={dialogPreview === true}
      aria-describedby="scroll-dialog-description"
    >
      {dialogScreen(dialogTitle, dialogText)}
    </Dialog>
  </>;
};