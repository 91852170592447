import React, { useCallback } from 'react';
import { Box, Typography, TextField, Button, Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../Services/apiServices';
import { Enquiry } from '../../Models/Enquiry';
import ReactGA from 'react-ga4';
import '../ContactUs/ContactUs.css';
import { ContactInfo } from '../Common/ContactInfo'
import { FailureDialog } from '../Common/Dialogs/FailureDialog'; 
import {
    ThankYouLocation,
} from '../../routeConstants';

import { Helmet } from 'react-helmet';
import { PageTitles } from "../../utils/PageTitles";
import config from "../../config/config.json";

export const ContactUs = () => {
    const [fullName, setFullName] = React.useState<string | null>(null);
    const [contactNumber, setContactNumber] = React.useState<string | null>(null);
    const [businessName, setBusinessName] = React.useState<string | null>(null);
    const [contactTime, setContactTime] = React.useState<string | null>(null);
    const [message, setMessage] = React.useState<string | null>(null);
    const [dialog, showDialog] = React.useState<boolean>(false);
    const [isSending, setIsSending] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [contactNumberFlag, setContactNumberFlag] = React.useState<boolean>(false);
    const [fullNameFlag, setFullNameFlag] = React.useState<boolean>(false);
    const [businessNameFlag, setBusinessNameFlag] = React.useState<boolean>(false);
    const [contactTimeFlag, setContactTimeFlag] = React.useState<boolean>(false);

    const sendContactUsForm = useCallback(
        () => {
            setIsSending(true);
            localStorage.setItem("ThankYou", "" + fullName);
            ReactGA.event({
                category: 'User',
                action: 'Sent Contact Us Form'
            });

            let sendInfo: Enquiry = {
                FullName: "" + fullName,
                BusinessName: "" + businessName,
                ContactNumber: "" + contactNumber,
                BestTimeToReach: "" + contactTime,
                Message: "" + message
            };

            ApiService.sendContactUsForm(sendInfo).then(
                () => {
                    window.scrollTo(0, 0);
                    navigate(ThankYouLocation.path);
                })
                .catch(() => {
                    showDialog(true);
                    setIsSending(false);
                }
            );
        },
        [businessName, contactNumber, contactTime, fullName, navigate, message],
    )

    const openPrivatePolicy = useCallback(
        () => {
            window.open(config.PRIVACY_POLICY);
        },
        [],
    )

    const handleContentChange = useCallback(
        (setFunction) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setFunction(event.target.value);
        },
        [],
    )

    const handleBlur = useCallback(
        (setFunction) => (event: React.FocusEvent<HTMLInputElement>) => {
            setFunction(true);
        },
        [],
    )
    
    const checkLimit = useCallback(
        (name: string | null, limit: number) => {
            if (name === null) {
                return false;
            }
            if (name.length > limit) {
                return true;
            }
            return false;
        },
        [],
    )
    
    const checkSpecialCharacters = useCallback(
        (name: string | null, isBusinessName: boolean) => {
            if (name === null) {
                return false;
            }
            let specialCharacters = [] 
            if(isBusinessName) {
                specialCharacters = ['<', '>', ';'];
            } else {
                specialCharacters = ['<', '>', ';', '&'];
            }
            for (let character of specialCharacters) {
                if (name.includes(character)) {
                    return true;
                }
            }
        },
        [],
    )
    
    const isValidFullName = useCallback(
        (name: string | null,) => {
            if (name === null) {
                return true;
            }
            if (name.length === 0) {
                return false;
            }
            if (name.length > 100) {
                return false;
            }
            if (checkSpecialCharacters(name, false)) {
                return false;
            }
            return true;
        },
        [checkSpecialCharacters],
    )

    const checkBlankField = useCallback(
        (name: string | null,) => {
            if(name === null) {
                return false;
            }
            if(name.length === 0) {
                return true;
            }
        },
        [],
    )

    const isValidContactNumber = useCallback(
        (name: string | null,) => {
            if (name === null) {
                return true;
            }
            if (name.length === 0) {
                return false;
            }
            if (name.length > 20) {
                return false;
            }
            let allowName = name
                .replace(/ /g, '')
                .replace(/#/g, '')
                .replace(/\(/g, '')
                .replace(/\)/g, '');
    
            if (allowName.length === 12 && /^\+61(2|3|4|7|8)[\d]{8}$/.test(allowName)) {
                return true;
            }   else if (allowName.length === 10 && /^(0(2|3|4|7|8)[\d]{8}|((1300)|(1800))[\d]{6})$/.test(allowName)) {
                return true;
            }   else if(allowName.length === 6 && /^(13)[\d]{4}$/.test(allowName)) {
                return true;
            }   else {
                return false;
            }
        },
        [],
    )

    const isValidBusinessName = useCallback(
        (name: string | null,) => {
            if (name === null) {
                return true;
            }
            if (name.length === 0) {
                return false;
            }
            if (name.length > 100) {
                return false;
            }
            if (checkSpecialCharacters(name, true)) {
                return false;
            }
            return true;
        },
        [checkSpecialCharacters],
    )

    const isValidContactTime = useCallback(
        (name: string | null,) => {
            if (name === null) {
                return true;
            }
            if (name.length === 0) {
                return false;
            }
            if (name.length > 200) {
                return false;
            }
            if (checkSpecialCharacters(name, false)) {
                return false;
            }
            return true;
        },
        [checkSpecialCharacters],
    )
    
    const isValidMesssage = useCallback(
        (name: string | null,) => {
            if (name === null) {
                return true;
            }
            if (name.length > 500) {
                return false;
            }
            if (checkSpecialCharacters(name, false)) {
                return false;
            }
            return true;
        },
        [checkSpecialCharacters],
    )

    const isFormValid = useCallback(
        () => {
            if (isSending === true) {
                return false;
            }
            if (!isValidFullName(fullName) || fullName === null) {
                return false;
            }
            if (!isValidContactNumber(contactNumber) || contactNumber === null) {
                return false;
            }
            if (!isValidContactTime(contactTime) || contactTime === null) {
                return false;
            }
            if (!isValidBusinessName(businessName) || contactTime === null) {
                return false;
            }
            if (!isValidMesssage(message)) {
                return false;
            }
            return true;
        },
        [businessName, contactNumber, contactTime, fullName, isSending, isValidBusinessName, isValidContactNumber, isValidContactTime, isValidFullName, isValidMesssage, message],
    )
    
    const handleCloseDialog = useCallback(
        () => {
            showDialog(false);
        },
        [],
    )

    return (
        <Box className="contactus_background">
            <Helmet>
                <title>{PageTitles.ContactUs}</title>
            </Helmet>
            <Box className="contactus_panel">
                <Dialog 
                    open={dialog} 
                    maxWidth="sm" 
                    className="contactus_dialog"
                    PaperProps={{
                        style: { borderRadius: '1.5rem' }
                    }}
                >
                    <FailureDialog handleClose={handleCloseDialog}/>
                </Dialog>
                <ContactInfo
                    text="If you would like more information or to speak to someone about signing up for Product Level Blocking, please contact our Merchant Engagement Team at Services Australia."
                    textWidth="56.25rem"
                    source=" - Contact Us"
                />
            </Box>
            <Box className="contactus_panel" id="contact-us">
                <Typography className="contactus_title_text">Contact us</Typography>
                <Typography className="contactus_text_instructions">If you would like us to call you, please enter your message and tell us the best time to reach you.</Typography>
                
                <TextField 
                    className="contactus_input_box"
                    label="Full Name" 
                    variant="filled"
                    required
                    error={fullNameFlag && !isValidFullName(fullName)}
                    onChange={handleContentChange(setFullName)}
                    onBlur={handleBlur(setFullNameFlag)}
                />
                {checkLimit(fullName, 100) && 
                    <Typography className="contactus_error_text">
                        Full Name cannot exceed 100 characters in length.
                    </Typography>
                }
                {checkSpecialCharacters(fullName, false) &&
                    <Typography className="contactus_error_text">
                        The following characters are not allowed in this field: {"< > ; &"}
                    </Typography>
                }
                {fullNameFlag && checkBlankField(fullName) &&
                    <Typography className="contactus_error_text">
                        Please enter your full name.
                    </Typography>
                }
                <TextField 
                    className="contactus_input_box" 
                    label="Contact number" 
                    variant="filled" 
                    required
                    error={contactNumberFlag && !isValidContactNumber(contactNumber)}
                    onChange={handleContentChange(setContactNumber)}
                    onBlur={handleBlur(setContactNumberFlag)}
                />
                {checkSpecialCharacters(contactNumber, false) &&
                    <Typography className="contactus_error_text">
                        The following characters are not allowed in this field: {"< > ; &"}
                    </Typography>
                }
                {contactNumberFlag && !isValidContactNumber(contactNumber) && 
                    <Typography className="contactus_error_text">
                        Please enter a valid phone number using digits, spaces and + only. Please also include a valid area code if entering a landline.
                    </Typography>
                }
                <TextField 
                    className="contactus_input_box" 
                    label="Business name" 
                    variant="filled" 
                    required
                    error={businessNameFlag && !isValidBusinessName(businessName)}
                    onChange={handleContentChange(setBusinessName)}
                    onBlur={handleBlur(setBusinessNameFlag)}
                />
                {checkLimit(businessName, 100) && 
                    <Typography className="contactus_error_text">
                        Business Name cannot exceed 100 characters in length.
                    </Typography>
                }
                {checkSpecialCharacters(businessName, true) &&
                    <Typography className="contactus_error_text">
                    The following characters are not allowed in this field: {"< > ;"}
                    </Typography>
                }
                {businessNameFlag && checkBlankField(businessName) &&
                    <Typography className="contactus_error_text">
                        Please enter your business name.
                    </Typography>
                }
                <TextField 
                    className="contactus_input_box" 
                    label="Best time to reach you (between 9am and 4pm AEST Monday to Friday)" 
                    variant="filled"
                    multiline
                    maxRows={2}
                    required
                    error={contactTimeFlag && !isValidContactTime(contactTime)}
                    onChange={handleContentChange(setContactTime)}
                    onBlur={handleBlur(setContactTimeFlag)}
                />
                {checkLimit(contactTime, 200) && 
                    <Typography className="contactus_error_text">
                        This field cannot exceed 200 characters in length.
                    </Typography>
                }
                {checkSpecialCharacters(contactTime, false) &&
                    <Typography className="contactus_error_text">
                    The following characters are not allowed in this field: {"< > ; &"}
                    </Typography>
                }
                {contactTimeFlag && checkBlankField(contactTime) &&
                    <Typography className="contactus_error_text">
                        Please enter the best time to reach you.
                    </Typography>
                }
                <TextField 
                    className="contactus_input_box" 
                    label="Message (optional)" 
                    variant="filled" 
                    error={!isValidMesssage(message)}
                    onChange={handleContentChange(setMessage)}
                    multiline
                    rows={6}
                />
                {checkLimit(message, 500) && 
                    <Typography className="contactus_error_text">
                        Message cannot exceed 500 characters in length.
                    </Typography>
                }
                {checkSpecialCharacters(message, false) &&
                    <Typography className="contactus_error_text">
                        The following characters are not allowed in this field: {"< > ; &"}
                    </Typography>
                }
                <Typography className="contactus_conditions">
                    By submitting this form you agree to our <span tabIndex={0} role="link" onKeyPress={e => e.key === "Enter" && openPrivatePolicy()} className="contactus_conditionsHighlight" onClick={openPrivatePolicy}>Privacy Policy</span> which explains how we may collect, use and disclose your personal information.
                </Typography>
                <Typography className="contactus_hint">*indicates mandatory field</Typography>
                
                <Button
                    variant="primary"
                    className="contactus_button"
                    disabled={!isFormValid()}
                    tabIndex={0}
                    onClick={sendContactUsForm}
                >
                    <p className="contactus_button_text">
                        Send
                    </p>
                </Button>
            </Box>
        </Box>
    )
}