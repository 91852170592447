import React from 'react';
import '../../../../css/FactSheets.css';
import { ContactInfo } from '../../../Common/ContactInfo';
import './ContactUsBlock.css';

export interface ContactUsBlockProps {
    variant?: string
}

export const ContactUsBlock = (props: ContactUsBlockProps) => {
    const { variant } = props;

    
    return (
        <>
            <ContactInfo
                text="If you would like more information, please contact our Merchant Engagement Team at Services Australia."
                textWidth="42.1875rem"
                hasPadding
                violet={(variant === "violet")}
            />
        </>
    );
}