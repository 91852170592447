import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ContactInfoCards } from '../ContactInfoCards';

export interface ContactInfoProps {
    hasPadding?: boolean;
    violet?: boolean;
    text: string;
    textWidth?: string;
    source?: string;
}

const useStyles = makeStyles<ContactInfoProps>()((theme, { hasPadding, violet, textWidth }) => {
    return {
        contactus_background: {
            background: violet ? "var(--dss-light-purple)" : "var(--primary-white)",
            padding: hasPadding ? "2.5rem 5% 3.75rem 5%" : "0rem",
            width: hasPadding ? "" : "100%",
        },
        contactus_panel: {
            borderRadius: "0.5rem",
            opacity: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width:"100%",
        },
        contactus_title_text: {
            textAlign: "center",
            font: "normal normal bold 2.25rem/3rem Open Sans",
            letterSpacing: "0.0125rem",
            color: "#000000",
            opacity: "1",
            marginBottom: "0.9375rem",
            height: '100%'
        },
        contactus_text: {
            textAlign: "center",
            letterSpacing: "0rem",
            font: "normal normal normal 1.125rem/2rem Open Sans",
            opacity: "1",
            marginBottom: "2.25rem",
            maxWidth: textWidth,
            height: "100%"
        },
    };
});

export const ContactInfo = (props: ContactInfoProps) => {
    const { source, text } = props;
    const { classes } = useStyles(props);
    
    return <>
        <Box className={classes.contactus_background}>
            <Box className={classes.contactus_panel}>
                <Typography className={classes.contactus_title_text}>How can we help you?</Typography>
                <Typography className={classes.contactus_text}>{text}</Typography>
                <ContactInfoCards source={source}/>
            </Box>
        </Box>
    </>
}