import Location from 'react-app-location';

export const HomeLocation = new Location('/');

export const CompatibilityLocation = new Location('/compatibility');
export const FrequentlyAskedQuestionsLocation = new Location('/frequently-asked-questions');
export const ContactUsLocation = new Location('/contact-us');
export const ThankYouLocation = new Location('/thank-you');
export const SignUpLocation = new Location('/sign-up');
export const MpaLocation = new Location('/merchant-participation-agreement');
export const MpaRedirectLocation = new Location('/merchant-participation-agreement/existingMPA');

export const FactsheetGetToKnowLocation = new Location('/get-to-know-the-SmartCard');
export const FactsheetRestrictedLocation = new Location('/restricted-items');
export const FactsheetPLBLocation = new Location('/what-is-product-level-blocking');
export const FactsheetGoingLiveLocation = new Location('/going-live-with-plb');
export const FactsheetReadyLocation = new Location('/your-business-and-the-SmartCard');
export const FactsheetDefinitionsLocation = new Location('/list-of-terms');
