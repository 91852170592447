import React, { useCallback, useEffect } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import '../../../css/FactSheets.css';
import { Introduction } from '../Components/Introduction';
import { TextBlock } from '../Components/TextBlock';
import { ContactUsBlock } from '../Components/ContactUsBlock';
import sabine_supermarket from '../../../Assets/images/sabine_supermarket.png';
import './WhatIsPlb.css';
import { HighlightedTitle } from '../Components/HighlightedTitle';
import plb_product_icon from '../../../Assets/icons/plb_product_icon.png';
import plb_eftpos_icon from '../../../Assets/icons/plb_eftpos_icon.png';
import plb_customer_icon from '../../../Assets/icons/plb_customer_icon.png';
import plb_pos_img from '../../../Assets/images/plb_pos_img.png'
import PosProviderDialog from '../Components/PosProviderDialog/PosProviderDialog';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import { useNavigate } from 'react-router-dom';
import { CompatibilityLocation } from '../../../routeConstants';
import ApiService from '../../../Services/apiServices';
import {AxiosResponse} from 'axios';
import { FactSheetServerErrorMessage } from '../FactSheetErrorMessage/FactSheetErrorMessage';

export const WhatIsPlb = () => {
    const [storageUrl, setStorageUrl] = React.useState<string>('');
    const [updatedDateDescription, setUpdatedDateDescription] = React.useState<string>('');
    const [apiError, setApiError] = React.useState<boolean>(false);

    const navigate = useNavigate();
    
    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )
    
    const GoToLocationOnEnter = useCallback(
        (location: string) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                window.scrollTo(0, 0);
                navigate(location);
            }
        },
        [navigate],
    )

    useEffect(() => {
        fetchMetaData();
      }, []);

    // fetches the document's url to download from and the date description to display from the API
    // and then applies it to the page.  If it can't be found, an error message is displayed
    // next to the button and the error logged to the browser's console.
    const fetchMetaData = () => {
        ApiService.getFactSheetMetadata('What_Is_Product_Level_Blocking')
        .then((response : AxiosResponse) => {
            setApiError(false);
            setStorageUrl(response.data.value[0].StorageUrl);
            setUpdatedDateDescription(response.data.value[0].UpdatedDateDescription);
        })
        .catch((e) => {
            console.log('Error fetching factsheet metadata for What is product level blocking');
            console.error(e);
            setApiError(true);
            setUpdatedDateDescription(FactSheetServerErrorMessage);
        });
    };

    const heading1 = {
        start: "What is ",
        highlight: "Product Level Blocking?",
        body: <>
                Product Level Blocking is a function on the point of sale (POS) system to ensure restricted items cannot be purchased with a SmartCard.
                <br/><br/>The POS system and EFTPOS terminal work together to identify when a SmartCard is presented by a customer, and if there are any restricted items in the sale.
            </>
    }

    return(
        <>
            <Helmet>
                <title>{PageTitles.WhatIsProductLevelBlocking} </title>
            </Helmet>
            <Introduction
                heading1 = {heading1}
                downloadButton = {true}                
                storageUrl={storageUrl}
                updatedDateDescription={updatedDateDescription}
                apiError={apiError}
            />
            <TextBlock
                variant = "purple"
                heading = "How does Product Level Blocking work?"
                content = {<>
                        <Grid container sx={{mt:'1.875rem', width:'100%'}}>
                            <Grid item xs={6} md={0} className="plb_diagramSideGrid_mobile">
                                <div className="plb_diagramSideContainerColumn_mobile">
                                    <Box className="plb_diagramBox plb_diagramBox_mobile">
                                        <img src={plb_eftpos_icon} className="plb_diagramIcon_mobile" alt=""/>
                                        <Typography variant="h5" className="plb_diagramBody_mobile"><strong>EFTPOS</strong> terminal identifies SmartCard</Typography>
                                    </Box>
                                    <Box className="plb_diagramLine_mobile"></Box>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={0} className="plb_diagramSideGrid_mobile">
                                <div className="plb_diagramSideContainerColumn_mobile">
                                    <Box className="plb_diagramBox plb_diagramBox_mobile">
                                        <img src={plb_product_icon} className="plb_diagramIcon_mobile" alt=""/>
                                        <Typography variant="h5" className="plb_diagramBody_mobile">Restricted items displayed to the POS operator</Typography>
                                    </Box>
                                    <Box className="plb_diagramLine_mobile"></Box>
                                </div>
                            </Grid>
                            <Grid item xs={0} md={4} sx={{height:'34.375rem'}} className="plb_diagramSideGrid">
                                <div className="plb_diagramSideContainerRow">
                                    <div>
                                        <div className="plb_diagramSideItemContainer">
                                            <Box className="plb_diagramBox plb_diagramBox_desktop">
                                                <Typography variant="h5" className="plb_diagramBody"><strong>EFTPOS</strong> terminal identifies SmartCard</Typography>
                                                <img src={plb_eftpos_icon} className="plb_diagramIcon" alt=""/>
                                            </Box>
                                            <Box className="plb_diagramLine_desktop"></Box>
                                        </div>
                                        <div className="plb_diagramSideItemContainer">
                                            <Box className="plb_diagramBox plb_diagramBox_desktop">
                                                <Typography variant="h5" className="plb_diagramBody">Restricted items displayed to the POS operator</Typography>
                                                <img src={plb_product_icon} className="plb_diagramIcon" alt=""/>
                                            </Box>
                                            <Box className="plb_diagramLine_desktop"></Box>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box className="plb_diagramBox plb_diagramCenterBox">
                                    <Typography variant="h2" className="plb_diagramHeading" sx={{mb:'1.25rem'}}>Point of sale (POS) system</Typography>
                                    <img src={plb_pos_img} className="plb_diagramImage" alt="The POS system displays restricted and non-restricted items"/>
                                </Box>
                            </Grid>
                            <Grid item xs={0} md={3} className="plb_diagramSideGrid">
                                <div className="plb_diagramSideContainerRow">
                                    <div className="plb_diagramSideItemContainer">
                                        <Box className="plb_diagramLine_desktop"></Box>
                                        <Box className="plb_diagramBox plb_diagramBox_desktop" sx={{flexDirection:'column', mb:'1.25rem'}}>
                                            <img src={plb_customer_icon} className="plb_diagramIcon" alt=""/>
                                            <Typography variant="h5" className="plb_diagramBody" sx={{textAlign:'center'}}>Customer chooses what to do with any restricted items</Typography>
                                        </Box>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={0} className="plb_diagramSideGrid_mobile">
                                <div className="plb_diagramSideContainerColumn_mobile">
                                    <Box className="plb_diagramLine_mobile"></Box>
                                    <Box className="plb_diagramBox plb_diagramBox_mobile">
                                        <img src={plb_customer_icon} className="plb_diagramIcon_mobile" alt=""/>
                                        <Typography variant="h5" className="plb_diagramBody_mobile">Customer chooses what to do with any restricted items</Typography>
                                    </Box>
                                </div>

                            </Grid>
                        </Grid>

                </>}
            />
            <TextBlock
                variant = "white"
                heading = "Why would I want Product Level Blocking in my business?"
                body = {<p style={{height: '100%'}}>By having Product Level Blocking in your business:
                    <ul style={{height: '100%'}}>
                        <li>you can easily accept the SmartCard as a form of payment if you sell any restricted items</li>
                        <li>your POS system will automatically prevent the sale of restricted items</li>
                        <li>your staff do not need to recognise the SmartCard or remember which items are restricted</li>
                        <li>you can help your customers manage their money and protect them from financial abuse.</li>
                    </ul></p>}
            />
            <TextBlock
                variant = "violet"
                heading = "How to get Product Level Blocking"
                body = {<p style={{height: '100%'}}>Getting Product Level Blocking is an easy, two-step process:
                        <ol style={{height: '100%'}}>
                            <li>Do the simple <span className='section_1_highlight' tabIndex={0} role="link" onKeyPress={GoToLocationOnEnter(CompatibilityLocation.path)} onClick={goToLocation(CompatibilityLocation.path)}>compatibility check</span>.</li>
                            <li>Follow the prompts to read and sign a Merchant Participation Agreement.</li>
                        </ol>
                        Your point of sale (POS) provider will then contact you to confirm a time to implement Product Level Blocking in your business.</p>}
            />
            <Box className="facts_background whiteBackground">
                <div className="facts_contentContainer">
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="plb_quoteImageContainer">
                                <img src={sabine_supermarket} className="plb_quoteImage" alt="The checkout at Sabine Supermarket"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                        <div className="plb_quoteContainer">
                            <Typography className="plb_quoteHeading" sx={{mb:'1.25rem'}}>
                                Leo from Sabine Supermarket says:
                            </Typography>

                                <Typography className="plb_quoteMark plb_quoteStart">
                                    “
                                </Typography>
                                <Typography className="plb_quoteBody">
                                    "Very convenient and easy for check-out staff."
                                </Typography>
                                <Typography className="plb_quoteMark plb_quoteEnd">
                                    „
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <Box className="facts_background violetBackground" id="PLB-accredited">
                <Box className="facts_contentContainer" sx={{alignItems:'center'}}>
                    <HighlightedTitle
                        variant="h1"
                        start="PLB-accredited "
                        highlight="POS providers"
                    />
                    <Typography variant="body2" className="plb_accreditedText" sx={{mt:'1.5625rem', mb:'3.125rem', maxWidth:'62.5rem'}}>
                        If your business has a PLB-accredited point of sale (POS) system and a current support agreement, it is likely there is no cost to upgrade your POS system.
                    </Typography>
                    <Box className="plb_accreditedBox" sx={{mb:'3.125rem'}}>
                        <Typography variant="body2" className="plb_accreditedBoxHeading" sx={{mb:'0.3125rem'}}>PLB-accredited POS providers:</Typography>
                        <Typography variant="body2" className="plb_accreditedBoxBody" sx={{mb:'2.1875rem'}}>Please click on the POS provider name to see their contact information.</Typography>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={4}>
                                <PosProviderDialog
                                    name={"ABS POS Systems"}
                                    posName={"RetailTouch"}
                                    website={"https://www.retailtouch.com.au"}
                                    email={"sales@abspos.net"}
                                    phone={"08 8234 8002"}
                                />
                                <PosProviderDialog
                                    name={"Beacon Business Systems"}
                                    posName={"Beacon POS"}
                                    website={"https://www.beaconsoftware.com.au"}
                                    email={"support@beaconsoftware.com.au"}
                                    phone={"03 8807 9989"}
                                />
                                <PosProviderDialog
                                    name={"Bepoz"}
                                    posName={"SmartPOS"}
                                    website={"https://www.bepoz.com.au"}
                                    email={"sales@bepoz.com.au"}
                                    phone={"1300 832 876"}
                                />
                                <PosProviderDialog
                                    name={"Black Label Solutions"}
                                    posName={"NomadPOS"}
                                    website={"https://nomadpos.com"}
                                    email={"support@blss.com.au"}
                                    phone={"03 8290 2900"}
                                />
                                <PosProviderDialog
                                    name={"Companion Software"}
                                    posName={"EDGEPos"}
                                    website={"https://www.companionsoftware.com.au/products/retail"}
                                    email={"enquiries@companionsoftware.com.au"}
                                    phone={"07 3387 0387"}
                                />
                                <PosProviderDialog
                                    name={"FutureNet"}
                                    posName={"OSPOS, Convenience POS"}
                                    website={"https://www.futurenet.com.au"}
                                    customEmail={<>
                                        <li><Link href={"mailto:support@futurenet.com.au"} className="plb_overflow_wrapper">{"support@futurenet.com.au"}</Link></li>
                                    </>}
                                    phone={"02 4761 0666"}
                                />
                                <PosProviderDialog
                                    name={"GaP Solutions"}
                                    posName={"EM Cloud, EM POS"}
                                    website={"https://www.gapsolutions.com.au"}
                                    email={"help@gapsolutions.com.au"}
                                    phone={"1300 722 289"}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <PosProviderDialog
                                    name={"GPK Group"}
                                    posName={"Merlin POS, Merlin ESP"}
                                    website={"https://www.gpkgroup.com.au/products-services/retail-solutions/"}
                                    email={"info@gpkgroup.com.au"}
                                    phone={"1300 000 475"}
                                />
                                <PosProviderDialog
                                    name={"H&L"}
                                    posName={"Exceed POS"}
                                    website={"https://www.hlpos.com/"}
                                    email={"sales@hlpos.com"}
                                    phone={"1800 778 340"}
                                />
                                <PosProviderDialog
                                    name={"Idealpos"}
                                    posName={"Idealpos"}
                                    website={"https://www.idealpos.co"}
                                    email={"support@idealpos.co"}
                                    phone={"07 3630 2455"}
                                />
                                <PosProviderDialog
                                    name={"Impulse Retailing"}
                                    posName={"Impulse Touch"}
                                    website={"https://www.impulseretailing.com/"}
                                    email={"support@impulseretailing.com"}
                                    phone={"1300 369 755"}
                                    phoneAvailability={"(option 2)"}
                                />
                                <PosProviderDialog
                                    name={"Independent Solutions"}
                                    posName={"PROFIT-TRACK"}
                                    website={"https://www.independentsolutions.com.au"}
                                    email={"support@independentsolutions.com.au"}
                                    phone={"1800 020 946"}
                                />
                                <PosProviderDialog
                                    name={"Kreative Technology"}
                                    posName={"Konvenience"}
                                    website={"http://www.kreative.com.au"}
                                    website2={"https://www.facebook.com/KreativePOS"}
                                    email={"support@kreative.com.au"}
                                    phone={"03 9328 8968"}
                                />
                                <PosProviderDialog
                                    name={"LS Retail"}
                                    posName={"LS Retail - LS Central"}
                                    website={"https://www.lsretail.com"}
                                    website2={"https://www.lsretail.com/contact-us"}
                                    email={"info@lsretail.com.au"}
                                    phone={"+354 414 5700"}
                                    phoneAvailability={"(Iceland Head Office)"}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <PosProviderDialog
                                    name={"POS Solutions"}
                                    posName={"PosBrowser"}
                                    website={"https://www.possolutions.com.au"}
                                    email={"support@possolutions.com.au"}
                                    customContent={<li>See <Link href="https://www.possolutions.com.au/contact-us" target="_blank" rel="noopener noreferrer">https://www.possolutions.com.au/contact-us</Link> for additional contact details</li>}
                                />
                                <PosProviderDialog
                                    name={"Quickfuel"}
                                    posName={"Quickfuel powered by SwiftPOS"}
                                    website={"https://www.quickfuel.com.au"}
                                    email={"support@quickfuel.com.au"}
                                    phone={"0400 958 725"}
                                    phoneAvailability={"(24/7 Support)"}
                                />
                                <PosProviderDialog
                                    name={"SmartFuel"}
                                    posName={"SmartFuel POS"}
                                    website={"https://smartfuelpos.com/"}
                                    email={"support@smartfuelpos.com"}
                                    phone={"02 8306 4701"}
                                    phoneAvailability={"(Sales & 24/7 Support)"}
                                />
                                <PosProviderDialog
                                    name={"Starrtec Solutions"}
                                    posName={"StarrPOS"}
                                    website={"https://www.starrtec.com.au"}
                                    email={"support@starrtec.com.au"}
                                    phone={"07 3808 9917"}
                                />
                                <PosProviderDialog
                                    name={"Surefire Systems"}
                                    posName={"Surefire POS"}
                                    website={"https://www.surefiresystems.com"}
                                    email={"support@surefiresystems.com"}
                                    phone={"1300 724 667"}
                                />
                                <PosProviderDialog
                                    name={"SwiftPOS"}
                                    posName={"SwiftPOS"}
                                    website={"https://www.swiftpos.com.au"}
                                    email={"support@swiftpos.com.au"}
                                    phone={"07 3351 8777"}
                                />
                                <PosProviderDialog
                                    name={"Tower Systems"}
                                    posName={"Retailer"}
                                    website={"https://www.towersystems.com.au"}
                                    email={"support@towersystems.com.au"}
                                    phone={"03 9524 8000"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography variant="body2" className="plb_accreditedText">
                        If your POS provider is not currently listed as PLB-accredited, it may be joining the Product Level Blocking program soon. You can contact us to find out if your POS provider is joining the program.
                    </Typography>

                </Box>
            </Box>

            <ContactUsBlock
                variant = "white"
            />
        </>
    );

}
