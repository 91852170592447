
import React, { useEffect } from 'react';
import { Box, Typography, Grid, Link } from "@mui/material";
import { Introduction } from "../Components/Introduction";
import '../../../css/FactSheets.css'
import './RestrictedItems.css'
import { ContactUsBlock } from '../Components/ContactUsBlock';
import no_alcohol_icon from '../../../Assets/icons/no_alcohol_icon.png';
import no_gambling_icon from '../../../Assets/icons/no_gambling_icon.png';
import no_cash_icon from '../../../Assets/icons/no_cash_icon.png';
import no_digital_currency_icon from '../../../Assets/icons/no_digital_currency_icon.png';
import no_giftcards_icon from '../../../Assets/icons/no_giftcards_icon.png';
import no_account_credit_icon from '../../../Assets/icons/no_account_credit_icon.png';
import no_pornography_icon from '../../../Assets/icons/no_pornography_icon.png';
import no_tobacco_icon from '../../../Assets/icons/no_tobacco_icon.png';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import ApiService from '../../../Services/apiServices';
import {AxiosResponse} from 'axios';
import { FactSheetServerErrorMessage } from '../FactSheetErrorMessage/FactSheetErrorMessage';

export const RestrictedItems = () => {

    const [storageUrl, setStorageUrl] = React.useState<string>('');
    const [updatedDateDescription, setUpdatedDateDescription] = React.useState<string>('');
    const [apiError, setApiError] = React.useState<boolean>(false);

    const heading1 = {
        start: "Understanding ",
        highlight: "restricted items"
    };
    const heading2 =  {
        start: "Does my business sell items that cannot be purchased with a SmartCard?",
        body: <>‘Restricted items’ are products that cannot be purchased with the SmartCard, including alcohol, gambling services, tobacco, pornography and some cash-like products.</>
    };    

    useEffect(() => {
        fetchMetaData();
      }, []);

    // fetches the document's url to download from and the date description to display from the API
    // and then applies it to the page.  If it can't be found, an error message is displayed
    // next to the button and the error logged to the browser's console.
    const fetchMetaData = () => {
        ApiService.getFactSheetMetadata('Restricted_Items')
        .then((response : AxiosResponse) => {
            setApiError(false);
            setStorageUrl(response.data.value[0].StorageUrl);
            setUpdatedDateDescription(response.data.value[0].UpdatedDateDescription);
        })
        .catch((e) => {
            console.log('Error fetching factsheet metadata for Restricted Items');
            console.error(e);
            setApiError(true);
            setUpdatedDateDescription(FactSheetServerErrorMessage);
        });
    };

    return (
        <>
            <Helmet>
                <title>{PageTitles.RestrictedItems} </title>
            </Helmet>
            <Introduction 
                heading1={heading1}
                heading2={heading2}
                downloadButton={true}
                storageUrl={storageUrl}
                updatedDateDescription={updatedDateDescription}
                apiError={apiError}
            />
            <Box className="products_background violetBackground">
                <div className="facts_contentContainer">
                    <Typography variant="h1" className="purple products_h1">Restricted items</Typography>
                    <Grid container spacing={3} sx={{mb:'45px'}}>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_alcohol_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Alcohol</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Alcohol</strong> 
                                    <br/><br/>
                                    If your business sells alcohol in-store, via a drive-through, in a bar, pub or licensed club, you sell ‘restricted items’. 
                                    <br/><br/>
                                    Alcohol means alcoholic beverages and products, including home-brew kits and home-brew concentrates.
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_gambling_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Gambling</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Gambling services</strong> 
                                    <br/><br/>
                                        If your business sells services that can be used for gambling, betting or wagering, you sell ‘restricted items’. 
                                        <br/><br/>Examples include scratchies, lottery tickets or gambling account top-ups for wagering companies such as Ladbrokes, Neds and William Hill. 
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_tobacco_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Tobacco</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Tobacco products</strong> 
                                    <br/><br/>
                                        If your business sells tobacco and tobacco products, you sell ‘restricted items’. 
                                        <br/><br/>Examples include cigars, cigarettes, cigarette papers, cigarette rollers or pipes.
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_pornography_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Pornography</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Pornographic material</strong> 
                                    <br/><br/>
                                        If your business sells publications, computer games or films that are classified Refused Classification or films classified X 18+ or magazines classified Category 1 or 2 Restricted, you sell ‘restricted items’.
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_digital_currency_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Digital money</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Digital currency and cryptocurrency</strong> 
                                    <br/><br/>
                                    If your business sells digital currency like Bitcoin, or facilities such as Coinjar, Coinspot or crypto-debit cards which provide access to digital currency that can be used to pay for alcohol, gambling services, tobacco, pornography or to obtain cash, you sell ‘restricted items’.
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_cash_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Some gift and prepaid cards</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                <strong>Gift cards and prepaid cards</strong> 
                                <br/><br/>
                                If your business sells gift cards or prepaid cards that can be used to purchase alcohol, gambling services, tobacco, pornography, or to obtain cash, you sell ‘restricted items’. 
                                <br/><br/>Some examples include: 
                                <ul>
                                    <li>Mastercard, Visa or eftpos gift and prepaid cards</li>
                                    <li>Coles Group, Myer or Woolworths Group gift cards</li>
                                    <li>Reloadable prepaid cards </li>
                                    <li>Australia Post money orders </li>
                                    <li>Store gift cards that allow the purchase of alcohol or other restricted items, such as a BWS, Liquorland or Dan Murphy’s gift card</li>
                                </ul>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_giftcards_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Some e-vouchers</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>E-vouchers</strong> 
                                    <br/><br/>
                                    If your business sells e-vouchers that can be used to pay for alcohol, gambling services including online casinos, tobacco, pornography, or to obtain cash, you sell ‘restricted items’.
                                    <br/><br/>E-vouchers are prepaid vouchers used to pay for goods and services by way of a unique code or PIN, and are distributed by companies such as epay Worldwide.
                                    <br/><br/>A list of restricted epay Worldwide e-vouchers is available in the <Link href="#intro_box">downloadable restricted items factsheet</Link>.
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className="products_box products_productBox">
                                <img src={no_account_credit_icon} className="products_image" alt=""/>
                                <Typography className="products_boxHeading">Store credit</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box className="products_box products_descriptionBox">
                                <div>
                                    <strong>Accounts and store credit</strong> 
                                    <br/><br/>
                                    If your business sells alcohol, gambling services, tobacco, pornography or cash-like products and you have a point of sale (POS) system that allows customers to run credit accounts or ‘tabs’, load funds onto membership cards, or purchase vouchers for in-store credit, you sell ‘restricted items’.
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" sx={{height:'100%'}}>
                        Here are some examples of unrestricted items:
                        <ul style={{height:'100%'}}>
                            <li>Groceries, clothes, books and toys</li>
                            <li>Products containing nicotine that require a prescription such as chewing gum, lozenges and patches</li>
                            <li>Store gift cards that do not allow the purchase of alcohol, gambling services, tobacco or pornography like Bunnings or JB Hi-Fi​ gift cards</li>
                            <li>Mobile phone, iTunes and Xbox top-ups</li>
                        </ul>
                    </Typography>
                </div>
            </Box>
            <ContactUsBlock
                variant="white"
            />
        </>
    );
}