import { useEffect, useState, useCallback } from "react";
import { Esign } from "./Esign";
import { YourDetails } from "./YourDetails";
import { MpaSubmitted } from "./MpaSubmitted";
import { TermsConditions } from "./TermsConditions";
import { Step, Stepper, StepLabel, Typography, Grid, CircularProgress, Box } from '@mui/material';
import './MpaStepper.css';
import { MerchantInformation } from "../../Models/MerchantInformation";
import ReactGA from 'react-ga4';
import { HomeLocation } from '../../routeConstants';
import { StepperBackButton } from "../Common/StepperBackButton";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { PageTitles } from "../../utils/PageTitles";

export enum PageStatus {
    InitialState = 'InitialState',
    ValidState = 'ValidState',    
}

const defaultInformation: MerchantInformation = {
    businessName: '',
    abn: '',
    legalName: '',
    firstName: '',
    lastName: '',
    positionTitle: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    fullAddress: '',
    email: '',
    phone: '',
    identity: false,
    authorisation: false,
}

const steps = ['Your Details', 'Merchant Participation Agreement', 'Confirmation'];
const stepsWithTerms = ['Summary of\nAgreement','Your\nDetails', 'Merchant\nParticipation\nAgreement', 'Confirmation'];
  
export const MpaStepper = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(1);
    const [requireTerms, setRequireTerms] = useState(false);
    const [hasCheckedRequirements, setHasCheckedRequirements] = useState(false);
    const [pageStatus, setPageStatus] = useState(PageStatus.InitialState);
    const [hasBackLocation, setHasBackLocation] = useState(false);
    const [merchantInformation, setMerchantInformation] = useState<MerchantInformation>(defaultInformation)

    const getActiveStep = useCallback(
        () => {
            if (requireTerms) return activeStep;
            else return activeStep - 1;
        },
        [activeStep, requireTerms],
    )
    
    const getSteps = useCallback(
        () => {
            return requireTerms ? stepsWithTerms : steps;
        },
        [requireTerms],
    )
    
    const getMobileStepperProgress = useCallback(
        () => {
            if(requireTerms) {
                return (activeStep + 1) * 25.00;
            } else {
                if (activeStep === 3) {
                    return 100.00;
                } else {
                    return activeStep * 33.00;
                }
            }
        },
        [activeStep, requireTerms],
    )
    
    const handleBack = useCallback(
        () => {
            setActiveStep(activeStep-1);
        },
        [activeStep],
    )
    
    const handleNext = useCallback(
        () => {
            if(activeStep === 0) {
                localStorage.setItem("requiresTerms","false");
            }
            if(activeStep + 1 === 3) {
                ReactGA.event({
                    category: 'User',
                    action: 'Completed MPA',
                    label: 'Completed MPA'
                });
            }
            setActiveStep(activeStep+1);
        },
        [activeStep],
    )
    
    const handleNext_yourDetails = useCallback(
        (capturedInformation: MerchantInformation) => {
            setMerchantInformation(capturedInformation);
            setPageStatus(PageStatus.ValidState);
            handleNext();
        },
        [handleNext],
    )

    useEffect(() => {
        if(!hasCheckedRequirements) {
            let requireTerms = localStorage.getItem("requiresTerms");
            if(requireTerms != null) {
                if(requireTerms === "true") {
                    setRequireTerms(true);
                    setActiveStep(0);
                }
                if(requireTerms === "false") {
                    setRequireTerms(false);
                    setActiveStep(1);
                }
                setHasCheckedRequirements(true);
            }
        }
    }, [hasCheckedRequirements]);

    const onBackButtonEvent = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      if((requireTerms && activeStep === 0) || (!requireTerms && activeStep === 1)) {
          window.removeEventListener('popstate', onBackButtonEvent);
          window.history.back();
      } else if (activeStep === 3) {
        navigate(HomeLocation.path);
      } else {
          handleBack();
          window.history.pushState(null, "", window.location.pathname);
      }
    }

    useEffect(() => {
        if(!hasBackLocation) {
            window.history.pushState(null, "", window.location.pathname);
            setHasBackLocation(true);
        }
        window.addEventListener('popstate', onBackButtonEvent);
        
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [requireTerms, activeStep]);

    const getStepContent = (stepIndex: number) => {
        window.scrollTo(0,0);
        switch(stepIndex) {
            case 0:
                return <TermsConditions
                            handleTermsAccepted={handleNext}/>
            case 1:
                return <YourDetails
                            merchantInformation={merchantInformation}
                            pageStatus={pageStatus}
                            handleNext={handleNext_yourDetails}/>;
            case 2:
                return <Esign
                            merchantInformation={merchantInformation}
                            handleNext={handleNext}                        
                            handleBack={handleBack}/>;
            case 3:
                return <MpaSubmitted
                            merchantInformation={merchantInformation}/>;
            default:
                return <YourDetails
                    merchantInformation={merchantInformation}
                    pageStatus={pageStatus}
                    handleNext={handleNext}/>;
        }
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.MerchantParticipationAgreement}</title>
            </Helmet>
            <Grid container className="mpaStepper_header">
                <Grid item xs={0} md={2}>
                    {(activeStep !== 3 && ((requireTerms && activeStep > 0) || (!requireTerms && activeStep > 1))) &&
                        <StepperBackButton
                            handleBack={handleBack}
                        />
                    }
                </Grid>
                <Grid item xs={0} md={0.5}/>
                <Grid item xs={0} md={7} className="mpaStepper_stepContainer">
                    <Stepper
                        activeStep={getActiveStep()}
                        className="mpaStepper"
                    >
                        {getSteps().map((label) => (
                              <Step key={label}>
                                  <StepLabel className="mpaStepper_wrapLabel">{label}</StepLabel>
                              </Step>
                          ))}
                    </Stepper>
                </Grid>
                <Grid item xs={0} md={2.5}/ >
                <Stepper
                    activeStep={getActiveStep()}
                    className="mpaStepper_mobile widest"
                >
                    <Box className='mpaStepper_progressContainer_mobile'>
                        <CircularProgress className='mpaStepper_progressCircle_mobile' size={60} thickness={4} variant="determinate" value={100}/>
                        <CircularProgress size={60} thickness={4} variant="determinate" value={getMobileStepperProgress()}/>
                        <Box className="mpaStepper_progress_mobile">
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                >{getActiveStep()+1} of {getSteps().length}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="mpaStepper_textContainer_mobile">
                        <Typography className="mpaStepper_activeStepText_mobile">{getSteps()[getActiveStep()]}</Typography>
                        {activeStep < steps.length &&
                            <Typography className="mpaStepper_nextStepText_mobile">Next: {getSteps()[getActiveStep()+1]}</Typography>
                        }
                    </Box>
                </Stepper>
            </Grid>
            <div className="mpa_step_contents">
                {getStepContent(activeStep)}
            </div>
        </>
    );
}
