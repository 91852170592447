import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import SignatureEntryDialog from '../SignatureEntryDialog/SignatureEntryDialog';
import './Esign.css';
import '../../../css/Mpa.css';
import { Agreement } from '../Agreement';
import { MerchantInformation } from '../../../Models/MerchantInformation';

export interface EsignProps {
    merchantInformation: MerchantInformation
    handleNext: () => void
    handleBack: () => void
}

export const Esign = (props: EsignProps) => {
    const [eSignOpen, seteSignOpen] = React.useState(false);

    const handleESignOpen = (open : boolean) => {
        seteSignOpen(open);
    }
    
    const {handleNext, handleBack} = props;

    return (
        <>
            <Box className="background esign_background">
                <Box className="pdfBox esign_dialogueBox">
                    <div className="esign_dialogueBoxPadding esign_dialogBoxFlex">
                        <Typography variant="h1" className="esign_heading1" sx={{mb:'0.9375rem'}}>
                            Electronically sign your Merchant Participation Agreement
                        </Typography>
                        <Typography variant="h5" className="esign_heading5" sx={{mb:'1.25rem'}}>
                            Please read through the below Merchant Participation Agreement, check your details are correct, and then click ‘electronically sign MPA’ to enter your signature.
                        </Typography>
                    </div>
                    <Divider className="esign_divider1" />
                    <div className="esign_centreBoxPadding">
                        <Box className="esign_paper">
                            <Agreement 
                                merchantInformation={props.merchantInformation}
                            />                 
                        </Box>
                    </div>
                    <Divider className="esign_divider2" />
                    <SignatureEntryDialog  
                        eSignOpenP={eSignOpen}
                        merchantInformation={props.merchantInformation}
                        setEOpen={handleESignOpen}
                        handleNext={handleNext}
                        />
                    <Button          
                        sx={{mt: '2.1875rem'}}         
                        onClick={() => handleESignOpen(true)}
                    >
                        Electronically sign MPA
                    </Button>
                    <Button variant="secondary" className="esign_backButton" sx={{mt: '0.625rem'}} onClick={handleBack}>
                        Back
                    </Button>
                </Box>
            </Box>
        </>
    )
}
