import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MpaLocation } from '../../../routeConstants';

export const MpaRedirect = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.setItem("requiresTerms","false");
        navigate(MpaLocation.path);
    }, [navigate]);

    return (
        <>
        </>
    );
};

export default MpaRedirect;