import axios from 'axios';
import { BaseResponse } from '../Models/baseResponse';
import { CompatibilityResult} from '../Models/compatibilityResult';
import { VerificationRequest } from '../Models/VerificationRequest';
import { MpaRequest } from "../Models/mpaRequest";
import { Enquiry } from '../Models/Enquiry';
import FactSheetMetaData from '../Models/FactSheetMetadata';

const ApiService = {

  sendCompatibilityResult: (result: CompatibilityResult) => {
    return axios.post<CompatibilityResult>(
      `${process.env.REACT_APP_API}/odata/compatibilitycheck`,
      result,
    );
  },
  checkVerificationCode: (code: VerificationRequest) => {
    return axios.post<BaseResponse>(
      `${process.env.REACT_APP_API}/portal/v1/mpa/verification`, 
      code,
    );
  },
  sendContactUsForm: (enquiry: Enquiry) => {
    return axios.post<Enquiry>(
      `${process.env.REACT_APP_API}/portal/v1/enquiry`,
      enquiry,
    );
  },
  sendMpa: ( mpaData: MpaRequest) => {
    return axios.post<BaseResponse>(
      `${process.env.REACT_APP_API}/portal/v1/mpa`,
      mpaData,
    );
  },
  getFactSheetMetadata: ( factSheetName: string) => {
    return axios.get<FactSheetMetaData>(`${process.env.REACT_APP_API}/odata/factsheet?$filter=name eq '${factSheetName}'`);
  }
}

export default ApiService;
