import { Box, Button, Divider, FormControl, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompatibilityLocation, MpaLocation } from "../../routeConstants";
import './SignUpPage.css';
import '../../css/Mpa.css';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { PageTitles } from "../../utils/PageTitles";

export const SignUpPage = () => {
    const navigate = useNavigate();
    const [hasMpa, setHasMpa] = useState<boolean | null>(null);

    const handleUserHasMpa = useCallback(
        () => {
            setHasMpa(true);
        },
        [],
    )
    
    const handleDoesNotHaveMpa = useCallback(
        () => {
            setHasMpa(false);
        },
        [],
    )

    const openMPA = useCallback(
        (e: React.MouseEvent<HTMLSpanElement>) => {
            localStorage.setItem("requiresTerms","false");
            window.scrollTo(0, 0);
            ReactGA.event({
                category: 'User',
                action: 'SignUp - Existing MPA'
            });
            navigate(MpaLocation.path);
        },
        [navigate],
    )

    
    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )
    
    const triggerFunctionOnEnter = useCallback(
        (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                trigger();
            }
        },
        [],
    )

    return (
        <>
            <Helmet>
                <title>{PageTitles.SignUpForProductLevelBlocking}</title>
            </Helmet>
            <Box className="background signUp_background">
                <Box className="dialogueBox signUp_dialogueBox">
                    <Typography variant="h1" className="signUp_heading1" sx={{fontSize: '2rem',mt: '0.75rem', mb: '2.5rem'}}>
                        Do you have an MPA?
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="mpa-sign-up"
                            name="verification-code-obtained"
                        >
                            <Grid container alignItems="stretch">
                                <Grid item xs={12} md={6}>
                                    <Box 
                                        tabIndex={0}
                                        className={(hasMpa && hasMpa !== null) ? "largeRadioButton" : "largeRadioButton largeRadioButton_disabled"}
                                        onKeyPress={triggerFunctionOnEnter(handleUserHasMpa)}
                                        onClick={handleUserHasMpa}
                                    >
                                        <div>
                                            <Radio
                                                tabIndex={-1}
                                                checked={hasMpa === true}
                                                size="small"
                                            />
                                        </div>
                                        <Divider variant="middle" sx={{ml:'0rem'}} />
                                        <Typography className="radioText" variant="body2">
                                            I have a Merchant Participation Agreement.
                                        </Typography>
                                        <Button 
                                            className="primaryButton widest"
                                            onClick={openMPA}
                                            disabled={(hasMpa && hasMpa !== null) ? false : true}
                                        >
                                            Next step
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box 
                                        tabIndex={0}
                                        className={(hasMpa === null || hasMpa) ? "largeRadioButton largeRadioButton_disabled" : "largeRadioButton"} 
                                        onKeyPress={triggerFunctionOnEnter(handleDoesNotHaveMpa)}
                                        onClick={handleDoesNotHaveMpa}
                                    >
                                        <div>
                                        <Radio
                                            tabIndex={-1}
                                            checked={hasMpa === false}
                                            size="small"
                                        />
                                        </div>  
                                        <Divider variant="middle" sx={{ml:'0px'}} />
                                        <Typography className="radioText" variant="body2">
                                            I do not have a Merchant Participation Agreement.
                                        </Typography>
                                        <Button 
                                            className="primaryButton widest rightButtonSizing noLRPadding" 
                                            sx ={{paddingRight: '0rem', paddingLeft: '0rem'}}
                                            onClick={goToLocation(CompatibilityLocation.path)}
                                            disabled={(hasMpa === null || hasMpa) ? true : false}
                                        >
                                            Go to compatibility check
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </>
    )
}
