import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import '../../HomePage/HomePage.css';

export interface HomePageCardProps {
    img: string,
    number: string,
    heading: string,
    body: JSX.Element,
}

export const HomePageCard = (props: HomePageCardProps) => {
    const { img, number, heading, body } = props;

    return (
        <>
            <Grid item xs={12} sm={12} md={4}>
                <Box sx={{'height': '100%'}}>
                    <Box className="section_4_container">
                        <Box className="section_4_image_container">
                            <img
                                src={img}
                                className="section_4_image_scaledown"
                                alt="" />
                        </Box>
                        <Typography className="section_4_header">
                            <span className="section_4_info">{number}</span>
                            <span className="section_4_header_span">{heading}</span>
                        </Typography>
                        <Typography className="section_4_left_text">{body}</Typography>
                    </Box>
                </Box>
            </Grid>
        </>
    );

}
