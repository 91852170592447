// Checks whether this is running on a mobile device by checking if it's an android or apple device
// TODO - this is suspect because what about the other mobiles that aren't apple or androids?
export function isMobileDevice() {    
    if (isAndroid() || isApple()) {
        return true;
    } else {
        return false;
    }
}

// Checks whether it's an android device by using the user agent and vendor headers
export function isAndroid() {
    let userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return true;
    }
    else {
        return false;
    }    
}

// Checks whether it's an apple device by using the user agent and vendor headers
export function isApple() {
    let userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return true;
    }
    else {
        return false;
    }    
}