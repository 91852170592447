import React from 'react';
import { Typography } from '@mui/material'
import '../../../../DialogTemplate/DialogTemplate.css'

interface MoreDialogProps {
    pornographyProducts: boolean;
}

export const MoreDialog = (props: MoreDialogProps) => {
    const {
        pornographyProducts
    } = props;

    if (pornographyProducts) {
        return (
            <>
                <Typography className='dialog_title_bold'>
                    More information - pornography
                </Typography>
                
                <div className='dialog_text_container'>
                    <div className='dialog_flex'>
                        <Typography className='dialog_text'>Pornographic material is a publication, computer game, or film that is classified Refused Classification or a film classified X 18+ or a magazine classified Category 1 or 2 Restricted.</Typography>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
        </>
    )
}