import React from 'react';
import { Box, Typography } from "@mui/material";
import '../../../../css/FactSheets.css';

export interface TextBlockProps {
    variant: string,
    heading: string,
    body?: JSX.Element,
    content?: JSX.Element
}

export const TextBlock = (props: TextBlockProps) => {
    const {variant, heading, body, content} = props;

    const backgroundClass = {
        white: "whiteBackground",
        violet: "violetBackground",
        purple: "purpleBackground"
    }

    const headingClass = {
        yellow: "yellow",
        purple: "purple"
    }

    const bodyClass = {
        black: "black",
        white: "white"
    }

    const getBackgroundClass = (variant: string) => {
        switch (variant){
            case "white":
                return backgroundClass.white;
            case "violet":
                return backgroundClass.violet;
            case "purple":
                return backgroundClass.purple;
            default:
                return backgroundClass.white;
        }
    }

    const getHeadingClass = (variant: string) => {
        switch (variant){
            case "white":
                return headingClass.purple;
            case "violet":
                return headingClass.purple;
            case "purple":
                return headingClass.yellow;
            default:
                return headingClass.purple;
        }
    }

    const getBodyClass = (variant: string) => {
        switch (variant){
            case "white":
                return bodyClass.black;
            case "violet":
                return bodyClass.black;
            case "purple":
                return bodyClass.white;
            default:
                return bodyClass.black;
        }
    }

    return(
        <>
            <Box className={"facts_background " + getBackgroundClass(variant)}>
                <div className="facts_contentContainer">
                    <Typography variant="h1" className={getHeadingClass(variant)} sx={{ textAlign:'left', mb:'1.25rem', height: '100%' }}>{heading}</Typography>
                    <Typography variant="body2" className={getBodyClass(variant)} sx={{ textAlign:'left'}}>{body}</Typography>
                    {content}
                </div>
            </Box>
        </>
    );
}