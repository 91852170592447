import { FAQHead } from './FAQ';
import config from '../../config/config.json';

export const FAQitems: FAQHead[] = [
    {

        faqHeader: 'How do I get Product Level Blocking in my business?',
        faqList: [
            {

                faqSubHeader: 'How do I get Product Level Blocking in my business?',
                faqText: 'There are two steps to sign up for Product Level Blocking in your business:\n' +
                    '<numbered-point>Do the simple compatibility check.</numbered-point>' +
                    '<numbered-point>Follow the prompts to read and sign a Merchant Participation Agreement.</numbered-point>' +
                    'You can sign up for Product Level Blocking through the <clickable>compatibility check</clickable> on the PLB Merchant Portal, or by <clickable>contacting</clickable> the Merchant Engagement Team.',

            },
            {

                faqSubHeader: 'What do I need to do to prepare for Product Level Blocking?',
                faqText: 'Once you sign a Merchant Participation Agreement, there are two steps to prepare for Product Level Blocking in your business:\n' +
                    '<numbered-point>Confirm your point of sale (POS) system details with the Merchant Engagement Team.</numbered-point>' +
                    '<numbered-point>Lock in a time with your POS provider for the technology upgrade, training session and identification of restricted items in your product range.</numbered-point>',
            },
            {

                faqSubHeader: 'Where do I find a Merchant Participation Agreement? ',
                faqText: 'You can electronically sign a Merchant Participation Agreement online by first completing the <clickable>compatibility check</clickable> and then ' +
                    'following the prompts to read and sign the Merchant Participation Agreement. \n\n' +
                    'Alternatively, you can <clickable>contact us</clickable> and we can email you a copy of the Merchant Participation Agreement.',

            },
            {

                faqSubHeader: 'How long will it take to get Product Level Blocking?',
                faqText: 'Once you sign a Merchant Participation Agreement, the Merchant Engagement Team will contact you to confirm your product range and point of sale (POS) system. \n\n' +                    
                    'Your POS provider will then contact you to organise a time for the Product Level Blocking technology upgrade, training session and identification of restricted items in your product range.',
            },
            {

                faqSubHeader: 'How much will it cost to get Product Level Blocking in my POS system?',
                faqText: 'If you have a <clickable>PLB-accredited point of sale (POS) system</clickable> and a current support agreement, it is likely there is no cost to upgrade your POS system. If you have more questions about costs, <clickable>contact</clickable> the Merchant Engagement Team.\n\n' +
                    'Training for you and your staff is provided at no cost to you.',

            },
            {

                faqSubHeader: 'What changes need to happen for Product Level Blocking to work in my business? ',
                faqText: 'Product Level Blocking involves an upgrade to your EFTPOS terminals and point of sale (POS) system, which adds a ‘PLB Restricted’ flag in the product file.\n\n' +
                    'Once the upgrade is complete, Product Level Blocking will automatically ensure restricted items cannot be purchased with a SmartCard.\n\n' +
                    'If you add a new item to your product range, you\'ll just need to identify if it is a restricted item.',

            },

        ]
    },
    {
        faqHeader: 'Do I need Product Level Blocking?',
        faqList: [
            {

                faqSubHeader: 'What are ‘restricted items’?',
                faqText: 'There are some products that cannot be purchased with a SmartCard. These are called <clickable>restricted items</clickable>, including:\n' +
                    '<dotpoint>Alcohol</dotpoint>' +
                    '<dotpoint>Gambling services</dotpoint>' +
                    '<dotpoint>Tobacco products</dotpoint>' +
                    '<dotpoint>Pornographic material</dotpoint>' +
                    '<dotpoint>Cash and <clickable>cash-like products</clickable></dotpoint>',
            },
            {

                faqSubHeader: 'My business does not sell restricted items, can I accept payments with a SmartCard? ',
                faqText: 'Yes.  If your business does not sell any restricted items, you will not need to do anything as the SmartCard will automatically work at your business.',

            },
            {

                faqSubHeader: 'My business sells mostly restricted items, can I accept payments with a SmartCard? ',
                faqText: 'Not at this time. If your business exclusively or primarily sells restricted items (for example, casinos, TABs or liquor stores), your business will be blocked automatically from accepting payments with a SmartCard.',
            },
            {

                faqSubHeader: 'My business sells both restricted and unrestricted items, can I accept payments with a SmartCard? ',
                faqText: 'Yes. However, a method of preventing the sale of restricted items to a SmartCard customer will be required.',

            },
            {

                faqSubHeader: 'What if my business sells both restricted and unrestricted items?',
                faqText: 'If your business sells both restricted and unrestricted items, you can accept the SmartCard as a form of payment for items that are not restricted by taking one of these actions:\n' +
                    '<dotpoint>Enter into a ‘Merchant Participation Agreement’ with Services Australia to implement Product Level Blocking in your business. This technology will automatically detect restricted items so they cannot be purchased with a SmartCard. You can learn about Product Level Blocking and sign a Merchant Participation Agreement on this website, or call the Merchant Engagement Team at Services Australia on <phone>OVERWRITTEN_BY_CONFIG</phone> for further assistance.</dotpoint>' +
                    '<dotpoint>Remove restricted items from your product range.</dotpoint>' +
                    'You can read more about <clickable>your business and the SmartCard</clickable> or <clickable>contact us</clickable> for more information.'                 
            }
        ]
    },
    {

        faqHeader: 'What is Product Level Blocking?',
        faqList: [
            {
                faqSubHeader: 'What is Product Level Blocking?',
                faqText: '<clickable>Product Level Blocking</clickable> is a function on the point of sale (POS) system to ensure restricted items cannot be purchased with a SmartCard.\n\n' +
                    'The point of sale (POS) system and EFTPOS terminal work together to identify when a SmartCard is presented by a customer, and if there are any restricted items in the sale.',
            },
            {
                faqSubHeader: 'Why would I want Product Level Blocking in my business?',
                faqText: 'By having Product Level Blocking in your business:\n' +
                    '<dotpoint>you can easily accept the SmartCard as a form of payment if you sell any restricted items</dotpoint>' +
                    '<dotpoint>your POS system will automatically prevent the sale of restricted items</dotpoint>' +
                    '<dotpoint>your staff do not need to recognise the SmartCard or remember which items are restricted</dotpoint>' +
                    '<dotpoint>you can help your customers manage their money and protect them from financial abuse.</dotpoint>',
            },
            {
                faqSubHeader: 'What do I agree to when I sign up for Product Level Blocking?',
                faqText: 'Here is an overview of what you agree to undertake when you sign a Merchant Participation Agreement:\n' +
                    '<dotpoint>Allow the upgrade of your POS system and your EFTPOS terminals to enable Product Level Blocking.</dotpoint>' +
                    '<dotpoint>Identify restricted items in your product range.</dotpoint>' +
                    '<dotpoint>Allow de-identified SmartCard purchase data to be used for managing and evaluating the Product Level Blocking program.</dotpoint>\n\n' +
                    'A Merchant Participation Agreement is a one-page agreement between you and Services Australia.',
            },
            {
                faqSubHeader: 'What happens if a customer tries to buy a restricted item with a SmartCard when my business has Product Level Blocking? ',
                faqText: 'With Product Level Blocking, the EFTPOS terminal informs the customer the SmartCard payment has been cancelled due to the attempted purchase of a restricted item.\n\n' +
                    'The point of sale (POS) system displays a message “Items not allowed with this card type.” and highlights the restricted items for the POS operator.\n\n' +
                    'The customer can choose to:\n' +
                    '<dotpoint>purchase the restricted items with a different card or cash, or </dotpoint>' +
                    '<dotpoint>remove the restricted items from the sale, and pay for the unrestricted items with the SmartCard.</dotpoint>',
            },
            {
                faqSubHeader: 'Who is involved in Product Level Blocking?',
                faqText: 'Services Australia delivers enhanced Income Management (eIM) across Australia on behalf of government. Product Level Blocking prevents the purchase of goods that are restricted under eIM.\n\n' +
                    'Along with each business, Services Australia works with companies to incorporate Product Level Blocking functionality, including:\n' +
                    '<dotpoint>Companies who supply EFTPOS terminals</dotpoint>' +
                    '<dotpoint>Companies who supply POS systems</dotpoint>\n\n' +
                    'The Merchant Engagement Team helps at Services Australia helps businesses understand the options for accepting SmartCard payments if they sell restricted items, answers merchant questions about the SmartCard and explains how Product Level Blocking can work for you.\n\n' +
                    'You can <clickable>contact</clickable> the Merchant Engagement Team on <phone>OVERWRITTEN_BY_CONFIG</phone> or email <email>OVERWRITTEN_BY_CONFIG</email>.\n\n' +
                    'You can also <clickable>send us a message</clickable>, and we will respond at a time convenient to you.',
            }

        ]
    },
    {

        faqHeader: 'I need more information',
        faqList: [
            {

                faqSubHeader: 'Who can I contact to answer my questions or get more information?',
                faqText: 'If you have any questions or would like some more information, you can <clickable>contact</clickable> the Merchant Engagement Team on <phone>OVERWRITTEN_BY_CONFIG</phone> or email <email>OVERWRITTEN_BY_CONFIG</email>.\n\n' +
                    'You can also <clickable>send us a message</clickable>, and we will respond at a time convenient to you.',
            },
            {
                faqSubHeader: 'I’m not sure what all these terms mean. Can you help?',
                faqText: 'Yes we can.  Here is a <clickable>list of terms</clickable>.',
            },
            {
                faqSubHeader: 'I already have Product Level Blocking in my business, who can I contact?',
                faqText: 'If you have any questions or feedback, or would like to provide updates about your business, such as a change in management or point of sale arrangements, you can <clickable>contact</clickable> the Merchant Engagement Team by phone on <phone>OVERWRITTEN_BY_CONFIG</phone> or email <email>OVERWRITTEN_BY_CONFIG</email>.',
            },
        ]
    },
    {

        faqHeader: 'The SmartCard',
        faqList: [
            {

                faqSubHeader: 'What is the SmartCard?',
                faqText: 'The SmartCard is a Visa/eftpos debit card issued under the enhanced Income Management (eIM) program to help recipients of certain welfare payments manage their money and protect them from financial abuse.\n\n' +
                    'The customer receives their income support payment in two parts. One part goes into their normal bank account and the balance goes into their eIM account which is linked to a SmartCard. The SmartCard cannot be used to buy alcohol, gambling services, tobacco, pornography, cash-like products or to withdraw cash.\n\n' +
                    'Most cardholders are located in Northern Territory, Queensland, Western Australia and South Australia.\n\n' +
                    'Services Australia delivers eIM on behalf of government. To find out more about the program, visit the <clickable>Services Australia Smartcard page</clickable>.'
            },
            {
                faqSubHeader: 'Where can the SmartCard be used? ',
                faqText: 'The SmartCard can be used anywhere that accepts eftpos or Visa card payments, including online, by phone and via mail order, unless the business primarily sells restricted items.',
            }
        ]
    }

];
