import React, { useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HomeLocation, CompatibilityLocation, FrequentlyAskedQuestionsLocation, ContactUsLocation } from '../../routeConstants';
import './Footer.css';
import config from "../../config/config.json";

export const Footer = () => {
    const navigate = useNavigate();
    
    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )
    
    const triggerFunctionOnEnter = useCallback(
        (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                trigger();
            }
        },
        [],
    )
    
    const openPrivatePolicy = useCallback(
        () => {
            window.open(config.PRIVACY_POLICY);
        },
        [],
    )

    return (
        <Box className="footer footer_gutter">
            <Grid container className="footer_container">
                <Grid item xs={12} md={3}>
                    <Typography className="footer_site_title">
                        PLB Merchant Portal
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} className="footer_optionMargin">
                    <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(HomeLocation.path))} onClick={goToLocation(HomeLocation.path)} className="footer_link_text">
                        Home
                    </Typography>
                    <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(CompatibilityLocation.path))} onClick={goToLocation(CompatibilityLocation.path)} className="footer_link_text">
                        Compatibility
                    </Typography>
                    <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FrequentlyAskedQuestionsLocation.path))} onClick={goToLocation(FrequentlyAskedQuestionsLocation.path)} className="footer_link_text">
                        Frequently Asked Questions
                    </Typography>
                    <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(ContactUsLocation.path))} onClick={goToLocation(ContactUsLocation.path)} className="footer_link_text">
                        Contact Us
                    </Typography>
                    <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(openPrivatePolicy)} onClick={openPrivatePolicy}  className="footer_link_text">
                        Privacy Policy
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography className="footer_text">
                        Services Australia acknowledges the Traditional Custodians of the lands we live on. We pay our respects to all Elders, past and present, of all Aboriginal and Torres Strait Islander nations.
                    </Typography>
                </Grid>
            </Grid>
            <Typography className="footer_copyright">
                Copyright © 2023. Services Australia
            </Typography>
        </Box>
    )
}