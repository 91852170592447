import { Grid, Typography } from '@mui/material';
import React from 'react';
import './Definition.css';

export interface DefinitionProps {
    heading: string,
    body: JSX.Element
}

export const Definition = (props: DefinitionProps) => {

    const {heading, body} = props;

    return(
        <>
            <Grid container columnSpacing={3} sx={{mb:'0.9375rem'}}>
                <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="h5" className="definition_text">{heading}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1" className="definition_text">{body}</Typography>
                </Grid>
            </Grid>
        </>
    );
}
