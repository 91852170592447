import { useEffect } from "react";
import { withRouter } from '../utils/withRouter';

/* This handler is used to navigate to the specific section of page by puting #<id_of_section> at the end of the path
Usage : it is been used in FAQ page*/
const ScrollToSectionHandler = (obj: any) => {

    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            const element = document.getElementById(obj.router.location.hash?.replace("#", ''));

            if(element)
            {
                window.scrollTo({
                    behavior: "smooth",
                    top: element.offsetTop 
                });
            }
           
        }, 100);
        return () => clearTimeout(scrollTimeout);
    }, [obj]);

    return null;
};


export default withRouter(ScrollToSectionHandler);
