import React, { useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import '../../../../css/FactSheets.css';
import './Introduction.css';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { HighlightedTitle } from '../HighlightedTitle/HighlightedTitle';

export interface IntroductionProps {
    heading1: {
        start?: string,
        highlight?: string,
        end?: string,
        body?: JSX.Element
    },
    heading2?: {
        start?: string, 
        highlight?: string,
        end?: string,
        body?: JSX.Element
    },
    content?: JSX.Element,
    downloadButton?: boolean,
    storageUrl?: string,
    updatedDateDescription?: string,
    apiError?: boolean
}

export const Introduction = (props: IntroductionProps) => {

    const {heading1, heading2, content, downloadButton, storageUrl, updatedDateDescription, apiError} = props;

    const OpenPdf = useCallback(
        () => {
                window.open(storageUrl, "_blank", "noopener noreferrer");
        },
        [storageUrl],
    )

    return (
        <>
            <Box id="intro_box" className="intro_background">
                <div className="facts_contentContainer">
                    <Box sx={{ mb:'20px' }}>
                        <HighlightedTitle
                            variant="h1"
                            start={(heading1.start) ? heading1.start : ''}
                            highlight={(heading1.highlight) ? heading1.highlight : ''}
                            end={(heading1.end) ? heading1.end : ''}
                        />
                    </Box>
                    {heading1.body && 
                        <Typography variant="body2" className="facts_intro_body" sx={{mb:'1.875rem'}}>{heading1.body}</Typography>}
                    {heading2 && heading2.start && 
                        <Box sx={{ mb:'1.875rem' }}>
                        <HighlightedTitle 
                                variant="h2"
                                start={(heading2.start) ? heading2.start : ''}
                                highlight={(heading2.highlight) ? heading2.highlight : ''}
                                end={(heading2.end) ? heading2.end : ''}
                            />
                        </Box>}
                    {heading2 && heading2.body && 
                        <Typography variant="body2" className="facts_intro_body" sx={{mb:'2.1875rem'}}>{heading2.body}</Typography>}
                    {content}
                    {downloadButton && 
                        <div className='factsheet_container'>
                            <div className='factsheet_container_button'>
                                <Button variant="secondary" className='factsheet_button' sx={{fontWeight:'bold', lineHeight:'1.5625rem', mb:'0.875rem'}} onClick={OpenPdf}>
                                    <DownloadForOfflineOutlinedIcon sx={{ mr:'0.5625rem' }}/>
                                    Download fact sheet
                                </Button>
                            </div>
                            <div className='factsheet_container_text'>
                                {!apiError ?
                                    <Typography sx={{fontStyle: 'italic'}} variant="body2" className="facts_update_date_description purple">{updatedDateDescription}</Typography>
                                    :
                                    <Typography variant="body2" className="facts_update_date_error error-red">{updatedDateDescription}</Typography>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Box>
        </>
    );
}