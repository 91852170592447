import { Metric } from 'web-vitals';
import ReactGA from 'react-ga4';
 
// event values can only contain integers
function getEventValueFromMetric(metric: Metric) {
  if (metric.name === 'CLS') {
    return Math.round(metric.value * 1000);
  }
  return Math.round(metric.value);
}
 
export function reportHandler(metric: Metric) {
    ReactGA.event({
        category: "Web Vitals",
        action: metric.name,
        label: metric.id,
        value: getEventValueFromMetric(metric),
        nonInteraction: true,
    });
}