import React, { useCallback } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import phone_icon from '../../../Assets/icons/phone_icon.png';
import representive_icon from '../../../Assets/icons/representive_icon.png';
import { makeStyles } from 'tss-react/mui';
import ReactGA from 'react-ga4';
import config from "../../../config/config.json";
import {isApple, isAndroid, isMobileDevice} from '../DeviceCheck/DeviceCheck';

export interface ContactInfoCardsProps {
    source?: string;
}

const useStyles = makeStyles()((theme) => {
    return {
        contactus_grid: {
            maxWidth: "1040px",
            alignSelf: "center",
        },
        contactus_box: {
            background: "var(--primary-white)",
            boxShadow: "0rem 0.375rem 0.625rem #00000008",
            border: "0.0625rem solid #E5EAF4",
            borderRadius: "0.5rem",
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "2.5rem",
            paddingBottom: "1.25rem",
            opacity: "1",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box"
        },
        contactus_icon: {
            width: "40px",
            height: "40px",
            alignSelf: "center",
            marginBottom: "1.25rem"
        },
        contactus_box_header: {
            font: "normal normal normal 1.25rem/2rem Open Sans",
            textAlign: "center",
            letterSpacing: "0.0125rem",
            color: "#183B56",
            opacity: "1",
            overflowWrap: "anywhere"
        },
        contactus_box_header_selectable: {
            font: "normal normal normal 1.25rem/2rem Open Sans",
            textAlign: "center",
            letterSpacing: "0.0125rem",
            opacity: "1",
            overflowWrap: "anywhere",
            cursor: "pointer",
            textDecoration: "underline",
            color: "var(--primary-purple)",
            "&:hover": {
                textShadow: "0rem 0rem 0.0313rem var(--primary-purple)"
            }
        },
        contactus_email: {
            wordBreak: "break-all"
        },
        contactus_box_text: {
            color: "#5A7184",
            opacity: "1",
            maxWidth: "370px"
        },
        contactus_box_phone: {
            whiteSpace: "nowrap"
        }
    };
});

export const ContactInfoCards = (props: ContactInfoCardsProps) => {
    const { source } = props;
    const { classes } = useStyles();

    const openEmailLink = useCallback(
        () => {
            ReactGA.event({
                category: 'User',
                action: 'Opened Contact Us Link to Email',
                label: 'Opened Email link on ' + source
            });
            window.open("mailto:" + config.ServicesAustralia_EMAIL);
        },
        [source],
    );
    
    const openPhoneNumber = useCallback(
        () => {
            if (isAndroid()) {
                ReactGA.event({
                    category: 'User',
                    action: 'Opened Contact Us Link to Dialer',
                    label: 'Opened Dialer link - Android - on ' + source
                });
                window.open("tel:" + config.CONTACT_NUMBER);
            } else if (isApple()) {
                ReactGA.event({
                    category: 'User',
                    action: 'Opened Contact Us Link to Dialer - Apple',
                    label: 'Opened Dialer link - Apple - on ' + source
                });
                document.location.href = "tel:" + config.CONTACT_NUMBER;
            }
        },
        [source],
    );

    const triggerFunctionOnEnter = useCallback(
        (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                trigger();
            }
        },
        [],
    )

    return (
        <>
            <Grid container className={classes.contactus_grid} spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box className={classes.contactus_box}>
                        <img
                            src={phone_icon}
                            className={classes.contactus_icon}
                            alt="phone icon"
                        />
                        <Typography tabIndex={0} 
                            onKeyPress={triggerFunctionOnEnter(openPhoneNumber)} 
                            onClick={openPhoneNumber} className={isMobileDevice() ? classes.contactus_box_header + " " + classes.contactus_box_header_selectable : classes.contactus_box_header}>{config.CONTACT_NUMBER}</Typography>
                        <Typography className={classes.contactus_box_text}>Call our Merchant Engagement Team on <span className={classes.contactus_box_phone}>{config.CONTACT_NUMBER}</span> <br />Monday to Friday 9am to 4pm (AEST).</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className={classes.contactus_box}>
                        <img
                            src={representive_icon}
                            className={classes.contactus_icon}
                            alt="representative icon"
                        />
                        <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(openEmailLink)} onClick={openEmailLink} className={classes.contactus_box_header_selectable + " " + classes.contactus_email}>{config.ServicesAustralia_EMAIL}</Typography>
                        <Typography className={classes.contactus_box_text}>Contact our Merchant Engagement Team using this email address.</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
