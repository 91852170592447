import { createGlobalStyle } from 'styled-components';

import OpenSans_Bold from './OpenSans-Bold.woff';
import OpenSans_Bold2 from './OpenSans-Bold.woff2';


import OpenSans_Extrabold from './OpenSans-Extrabold.woff';
import OpenSans_Extrabold2 from './OpenSans-Extrabold.woff2';
import OpenSans_Semibold from './OpenSans-Semibold.woff';
import OpenSans_Semibold2 from './OpenSans-Semibold.woff2';
import OpenSans_Medium from './OpenSans-Medium.woff';
import OpenSans_Medium2 from './OpenSans-Medium2.woff2';


export default createGlobalStyle`
    @font-face {
        font-family: 'Open-Sans Bold';
        src: local('Open-Sans Bold'), local('Open-Sans Bold'),
        url(${OpenSans_Bold2}) format('woff2'),
        url(${OpenSans_Bold}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Open-Sans Extrabold';
        src: local('Open-Sans Extrabold'), local('Open-Sans Extrabold'),
        url(${OpenSans_Extrabold2}) format('woff2'),
        url(${OpenSans_Extrabold}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'Open-Sans Semibold';
        src: url(${OpenSans_Semibold2}) format('woff2'),
        url(${OpenSans_Semibold}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Open-Sans Medium';
        src: url(${OpenSans_Medium2}) format('woff2'),
        url(${OpenSans_Medium}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    // Adding font weights without extra font family - will replace above definitions later

    @font-face {
        font-family: 'Open Sans';
        src: url(${OpenSans_Medium2}) format('woff2'),
        url(${OpenSans_Medium}) format('woff');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Open Sans';
        src: url(${OpenSans_Semibold2}) format('woff2'),
        url(${OpenSans_Semibold}) format('woff');
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: 'Open Sans';
        src: local('Open-Sans Bold'), local('Open-Sans Bold'),
        url(${OpenSans_Bold2}) format('woff2'),
        url(${OpenSans_Bold}) format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Open Sans';
        src: local('Open-Sans Extrabold'), local('Open-Sans Extrabold'),
        url(${OpenSans_Extrabold2}) format('woff2'),
        url(${OpenSans_Extrabold}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
`;
