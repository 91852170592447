import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    AppBar, 
    Button, 
    Drawer, 
    IconButton, 
    Menu, MenuItem, 
    Toolbar, 
    Typography, 
    Accordion, AccordionSummary, AccordionDetails, styled, AccordionSummaryProps
} from '@mui/material';
import React, { useEffect, useCallback } from 'react';
import { 
    HomeLocation, 
    CompatibilityLocation, 
    FrequentlyAskedQuestionsLocation, 
    ContactUsLocation,
    SignUpLocation,
    FactsheetGetToKnowLocation,
    FactsheetRestrictedLocation,
    FactsheetPLBLocation,
    FactsheetGoingLiveLocation,
    FactsheetReadyLocation,
    FactsheetDefinitionsLocation
} from '../../routeConstants';
import './Header.css';

export const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [drawer, setDrawer] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState("");
    const [headerHeight, setHeaderHeight] = React.useState(0);
    const navigate = useNavigate();
    let location = useLocation();

    const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
        <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="headerMobileDrawer_Chevron"/>}
        {...props}
        />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(270deg)',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(360deg)',
        },
        '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        },
    }));
        
    const StyledDrawer = styled(Drawer)(({ theme }) => ({
        '&	.MuiDrawer-paper': {
            height: 'calc(100% - ' + headerHeight + 'px) !important',
            zIndex: 10,
            top: 'auto !important',
        },
    }));

    const handleMenu = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [],
    )

    const handleKeyDown = useCallback(
        () => {
            setAnchorEl(document.getElementById('dropdownSelect'));
        },
        [],
    )
    
    const handleClose = useCallback(
        () => {
            setAnchorEl(null);
        },
        [],
    )
    
    const handleDrawer = useCallback(
        () => {
            setDrawer(!drawer);
        },
        [drawer],
    )
    
    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            setDrawer(false);
            handleClose();
            navigate(location);
        },
        [handleClose, navigate],
    )
    
    const triggerFunctionOnEnter = useCallback(
        (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                trigger();
            }
        },
        [],
    )

    useEffect(() => {
        setCurrentPage(location.pathname);
        const height = document.getElementById('header-toolbar')?.clientHeight;
        if(height !== undefined) {
            setHeaderHeight(height);
        }
        
        function handleResize() {
            const height = document.getElementById('header-toolbar')?.clientHeight;
            if(height !== undefined) {
                setHeaderHeight(height);
            }
        }
        
        window.addEventListener('resize', handleResize);
    }, [location.pathname]);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar id="header-toolbar" className="header_gutter headerPadding">
                    <Typography tabIndex={0} role="link" onKeyPress={triggerFunctionOnEnter(goToLocation(HomeLocation.path))} onClick={goToLocation(HomeLocation.path)} className="headerTitle">
                        {'PLB \nMerchant Portal'}
                    </Typography>
                    <IconButton tabIndex={0} onClick={handleDrawer} className="headerMobileMenuButton" id="dropdownSelect_mobile"
                      aria-haspopup="true" aria-expanded={drawer} aria-controls="menu_drawer"
                    >
                        {drawer === true ? <CloseIcon className="headerCancelIcon"/> : <MenuIcon/> }
                    </IconButton>
                    <Typography id="dropdownSelect"  role="menu" tabIndex={0} aria-expanded={Boolean(anchorEl)}
                        aria-controls="menu-appbar" aria-haspopup="true"
                        onKeyPress={triggerFunctionOnEnter(handleKeyDown)} onClick={handleMenu} className={currentPage.startsWith("/factsheet") ? "headerOptionDropdown headerOptionSelected" : "headerOptionDropdown headerOptionRegular"}>
                        Learn More
                        <KeyboardArrowDownIcon className={Boolean(anchorEl) ? "headerUpIcon" : "headerDownIcon"}/>
                    </Typography>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'dropdownSelect',
                          }}
                    >
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetGetToKnowLocation.path)}>Get to know the SmartCard</MenuItem>
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetRestrictedLocation.path)}>Restricted items</MenuItem>
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetPLBLocation.path)}>What is Product Level Blocking?</MenuItem>
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetGoingLiveLocation.path)}>Going live with Product Level Blocking</MenuItem>
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetReadyLocation.path)}>Your business and the SmartCard</MenuItem>
                        <MenuItem tabIndex={0} onClick={goToLocation(FactsheetDefinitionsLocation.path)}>List of terms</MenuItem>
                    </Menu>
                    <Typography tabIndex={0} role="link" onKeyPress={triggerFunctionOnEnter(goToLocation(CompatibilityLocation.path))} onClick={goToLocation(CompatibilityLocation.path)} className={currentPage === "/compatibility" ? "headerOption headerOptionSelected" : "headerOption headerOptionRegular"}>
                        Compatibility
                    </Typography>
                    <Typography tabIndex={0} role="link" onKeyPress={triggerFunctionOnEnter(goToLocation(FrequentlyAskedQuestionsLocation.path))} onClick={goToLocation(FrequentlyAskedQuestionsLocation.path)} className={currentPage === "/frequently-asked-questions" ? "headerOption headerOptionSelected" : "headerOption headerOptionRegular"}>
                        Frequently Asked Questions
                    </Typography>
                    <Typography tabIndex={0} role="link" onKeyPress={triggerFunctionOnEnter(goToLocation(ContactUsLocation.path))} onClick={goToLocation(ContactUsLocation.path)} className={currentPage === "/contact-us" ? "headerOption headerOptionSelected" : "headerOption headerOptionRegular"}>
                        Contact Us
                    </Typography>
                    <Button tabIndex={0} variant="primary" onClick={goToLocation(SignUpLocation.path)} className="headerButton">
                        <p className="headerButtonText">
                            Sign up for PLB
                        </p>
                    </Button>
                </Toolbar>
            </AppBar>
            <StyledDrawer
                id="menu_drawer"
                variant="persistent"
                anchor='top'
                open={drawer}
                className="headerMobileDrawer"
            >
                <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(HomeLocation.path))} onClick={goToLocation(HomeLocation.path)} className='headerMobileDrawer_TextContainer'> 
                    <Typography className="headerMobileDrawer_TextTitle">Home</Typography>
                </div>
                <Accordion className='headerMobileDrawer_Accordion'> 
                    <AccordionSummaryStyled 
                        aria-controls='mobileMenu-content' id='mobileMenu-header'
                        className='headerMobileDrawer_AccordionSummary'
                        classes={{
                            content: 'headerMobileDrawer_AccordionSummaryContent'
                        }}
                    >
                        <Typography className="headerMobileDrawer_TextMainTitle">Learn More</Typography>
                    </AccordionSummaryStyled>
                    <AccordionDetails className='headerMobileDrawer_AccordionDetails'
                        id='mobileMenu-content' aria-labelledby='mobileMenu-header'>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetGetToKnowLocation.path))} onClick={goToLocation(FactsheetGetToKnowLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">Get to know the SmartCard</Typography>
                            </div>
                        </div>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetRestrictedLocation.path))} onClick={goToLocation(FactsheetRestrictedLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">Restricted items</Typography>
                            </div>
                        </div>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetPLBLocation.path))} onClick={goToLocation(FactsheetPLBLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">What is Product Level Blocking?</Typography>
                            </div>
                        </div>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetGoingLiveLocation.path))} onClick={goToLocation(FactsheetGoingLiveLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">Going live with Product Level Blocking</Typography>
                            </div>
                        </div>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetReadyLocation.path))} onClick={goToLocation(FactsheetReadyLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">Your business and the SmartCard</Typography>
                            </div>
                        </div>
                        <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FactsheetDefinitionsLocation.path))} onClick={goToLocation(FactsheetDefinitionsLocation.path)} className='headerMobileDrawer_TextSubContainer'>
                            <div>
                                <Typography className="headerMobileDrawer_TextSubTitle">List of terms</Typography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(CompatibilityLocation.path))} onClick={goToLocation(CompatibilityLocation.path)} className='headerMobileDrawer_TextContainer'> 
                    <Typography className="headerMobileDrawer_TextTitle">Compatibility</Typography>
                </div>
                <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(FrequentlyAskedQuestionsLocation.path))} onClick={goToLocation(FrequentlyAskedQuestionsLocation.path)} className='headerMobileDrawer_TextContainer'> 
                    <Typography className="headerMobileDrawer_TextTitle">Frequently Asked Questions</Typography>
                </div>
                <div tabIndex={0} onKeyPress={triggerFunctionOnEnter(goToLocation(ContactUsLocation.path))} onClick={goToLocation(ContactUsLocation.path)} className='headerMobileDrawer_TextContainer'> 
                    <Typography className="headerMobileDrawer_TextTitle">Contact Us</Typography>
                </div>
                <Button variant="primary" onClick={goToLocation(SignUpLocation.path)} className="headerMobileDrawer_Button">
                    <p className="headerMobileDrawer_ButtonText">
                        Sign up for PLB
                    </p>
                </Button>
            </StyledDrawer>
        </>           
    )
}