export enum PageTitles {
    Home = "PLB Merchant Portal - Home",
    GetToKnow = "Get to know the SmartCard",
    RestrictedItems = "Restricted items",
    WhatIsProductLevelBlocking = "What is Product Level Blocking",
    GoingLiveWithProductLevelBlocking = "Going live with Product Level Blocking",
    YourBusiness = "Your business and the SmartCard",
    Definitions = "List of Terms",
    Compatibility = "Compatibility",
    FrequentlyAskedQuestions = "Frequently Asked Questions",
    ContactUs = "Contact Us",
    SignUpForProductLevelBlocking = "Sign up for Product Level Blocking",
    MerchantParticipationAgreement = "Merchant Participation Agreement"
}


