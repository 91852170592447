import { Box } from '@mui/material';
import React from 'react';
import { Definition } from '../Components/Definition';
import { Introduction } from '../Components/Introduction';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import './Definitions.css';

export const Definitions = () => {

    const intro_heading1 = {
        start: "Helpful ",
        highlight: "list of terms",
        body: <>Here is a handy reference to the terminology used on the PLB Merchant Portal.</>
    };

    return (
        <>
            <Helmet>
                <title>{PageTitles.Definitions} </title>
            </Helmet>
            <Introduction
                heading1={intro_heading1}
            />
            <Box className="facts_background whiteBackground" sx={{mt:'0.9375rem', mb:'2.5rem'}}>
                <div className="definitions_gridContainer">
                        <Definition
                            heading="alcohol"
                            body={<>Alcoholic beverages and products, including home-brew kits and home-brew concentrates.</>}
                        />
                        <Definition
                            heading="cash-like product"
                            body={<>A product that can be used like cash to purchase alcohol, gambling services, tobacco, pornography or to withdraw cash. Examples include some gift cards, prepaid cards, e-vouchers or store credit.</>}
                        />
                        <Definition
                            heading="compatibility check"
                            body={<>A set of ‘yes or no’ questions to determine whether a business sells restricted items, and if so, what methods may be available to prevent the sale of restricted items with a SmartCard.</>}
                        />
                        <Definition
                            heading="e-voucher"
                            body={<>A cash-like product that can be used as cash to purchase other goods or services and sold in fixed denominations such as $10, $20, $50 and $100. Commonly distributed by companies such as epay Worldwide. 
                                <br/><br/>E-vouchers cannot be purchased with a SmartCard if they can be used to buy alcohol, gambling services, tobacco, pornographic material or to obtain cash.</>}
                        />
                        <Definition
                            heading="EFTPOS terminal"
                            body={<>A card payment terminal at the point of sale which processes credit and debit card payments.</>}
                        />
                        <Definition
                            heading="enhanced Income Management"
                            body={<>enhanced Income Management (eIM) is a way to help recipients of certain welfare payments manage their money and protect them from financial abuse.
                                <br/><br/>The customer receives their income support payment in two parts. One part goes into their normal bank account and the balance goes into their eIM account which is linked to a debit card called the SmartCard. 
                                <br/><br/>The SmartCard cannot be used to buy restricted items.
                                <br/><br/>Most of the cardholders are located in the Northern Territory, Queensland, Western Australia and South Australia. </>}
                        />
                        <Definition
                            heading="gambling service"
                            body={<>A service that can be used for gambling, betting or wagering.</>}
                        />
                        <Definition
                            heading="gift card and prepaid card"
                            body={<>A cash-like product that can be used to pay for things up to the amount loaded on the card.<br /><br />These cannot be purchased with a SmartCard if they can be used to buy alcohol, gambling services, tobacco, pornographic material or to obtain cash.</>}
                        />
                        <Definition
                            heading="merchant"
                            body={<>A business that sells products or services and accepts cards for payment.</>}
                        />
                        <Definition
                            heading="Merchant Engagement Team"
                            body={<>A team within Services Australia that helps businesses understand the options for accepting SmartCard payments if they sell restricted items, answers merchant questions about the SmartCard and explains how Product Level Blocking (PLB) can work for you.</>}
                        />
                        <Definition
                            heading="Merchant Participation Agreement"
                            body={<>An agreement between a merchant and Services Australia to implement Product Level Blocking in their business.</>}
                        />
                        <Definition
                            heading="PLB-accredited POS system"
                            body={<>A point of sale (POS) system which can automatically prevent a SmartCard paying for restricted items.</>}
                        />
                        <Definition
                            heading="pornographic material"
                            body={<>Publications, computer games, or films that are classified Refused Classification or films classified X 18+ or magazines classified Category 1 or 2 Restricted.</>}
                        />
                        <Definition
                            heading="POS operator"
                            body={<>The person operating the POS system and handling the customer sale and checkout process.</>}
                        />
                        <Definition
                            heading="POS provider or POS reseller"
                            body={<>The company that supplies the POS system to the business.</>}
                        />
                        <Definition
                            heading="Point of sale (POS) system"
                            body={<>The hardware and software used to process customer sales and manage product information in a merchant business.</>}
                        />
                        <Definition
                            heading="Product Level Blocking (PLB)"
                            body={<>A function on the POS system to ensure restricted items cannot be purchased with a SmartCard.
                                <br/><br/>The POS system and EFTPOS terminal work together to identify when a SmartCard is presented by a customer, and if there are any restricted items in the sale.</>}
                        />
                        <Definition
                            heading="restricted items"
                            body={<>Products which customers are not permitted to purchase using a SmartCard:
                                <ul>
                                    <li>alcohol</li>
                                    <li>gambling services</li>
                                    <li>tobacco products</li>
                                    <li>pornographic material</li>
                                    <li>cash and cash-like products (like gift cards, prepaid cards, store credit or e-vouchers) which can be used to purchase alcohol, gambling services, tobacco or pornography</li>
                                </ul>
                                </>}
                        />
                        <Definition
                            heading="Services Australia"
                            body={<>Services Australia delivers enhanced Income Management on behalf of government. Services Australia is an executive agency of the Australian Government, responsible for delivering a range of welfare, health, child support payments and other services to eligible Australian citizens and permanent residents.</>}
                        />
                        <Definition
                            heading="SmartCard"
                            body={<>The SmartCard is a Visa/eftpos debit card issued under the enhanced Income Management (eIM) program. The customer receives their income support payment in two parts. One part goes into their normal bank account, and the balance goes into their eIM account which is linked to a SmartCard. The SmartCard cannot be used to purchase restricted items or to withdraw cash.
                                <br/><br/>The SmartCard can be used anywhere that accepts eftpos or Visa card payments, including online, by phone or via mail order, unless the business primarily sells restricted items.</>}
                        />
                        <Definition
                            heading="store credit"
                            body={<>Some POS systems allow customers to run credit accounts or ‘tabs’, load funds onto membership cards, or purchase vouchers for in-store credit.<br /><br />The SmartCard may not be used to pay for these types of credit if the business sells alcohol, gambling services, tobacco, pornography or cash-like products.</>}
                        />
                        <Definition
                            heading="tobacco"
                            body={<>Tobacco and tobacco products such as cigarettes, cigars, cigarette paper, cigarette rollers or pipes.</>}
                        />
                </div>
            </Box>
        </>
    );
}
