import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';
import EventfullSignaturePad, { SignaturePadOperations } from './EventFullSignaturePad';
import Typography from '@mui/material/Typography';
import { MerchantInformation } from '../../../Models/MerchantInformation';
import ApiService from '../../../Services/apiServices';

import configMpa from '../config/configMpa.json'
import config from "../../../config/config.json";

import './SignatureEntryDialog.css';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: "0rem",
    },
    '& .MuiDialogActions-root': {
      height: "5.625rem",
      padding: theme.spacing(2),
      justifyContent: "center",
    },
  }));

  export interface BootstrapDialogTitleProps {
    eSignOpenP : boolean
    children: React.ReactNode
    onClose(): void
}

  const BootstrapDialogTitle = (props : BootstrapDialogTitleProps) => {
    const { eSignOpenP, children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 4, paddingBottom:2, paddingLeft:3 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            sx={{
              position: 'absolute',
              right: '0.125rem',
              top: '0.125rem',            
            }}
            aria-label="close"
            onClick={onClose}
          >
            <CancelIcon/>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    eSignOpenP: PropTypes.bool,
    children: PropTypes.node,
    id : PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };

export interface SignatureEntryDialogProps {
    eSignOpenP : boolean,
    merchantInformation: MerchantInformation,
    setEOpen: (open: boolean) => void,
    handleNext: () => void
}

export default function SignatureEntryDialog(props : SignatureEntryDialogProps)  {
  const [isEmpty, setIsEmpty] = useState(true);
  const [sending, setSending] = useState(false);
  const [enableRetry, setEnableRetry] = useState(false);
  const { eSignOpenP, merchantInformation, setEOpen, handleNext } = props;

  const childRef = useRef<SignaturePadOperations>(null);

  const handleSendFailOrTimeout = useCallback(
    () => {
      setSending(false);
      setEnableRetry(true);
    },
    [],
  )
  
  const handleEmptyStatus = useCallback(
    (empty : boolean) => {
      setIsEmpty(empty);
    },
    [],
  )
  
  const handleClear = useCallback(
    () => {
      childRef.current?.Clear();
      setSending(false);
      setEnableRetry(false);
    },
    [],
  )
  
  const handleClose = useCallback(
    () => {
      setEOpen(false);
      setSending(false);
      setEnableRetry(false);    
    },
    [setEOpen],
  )
  
  const getButtonLabel = useCallback(
    () => {
      return (enableRetry === true ? "Retry" : "Sign and Submit");
    },
    [enableRetry],
  )
  
  const openPrivatePolicy = useCallback(
    () => {
      window.open(config.PRIVACY_POLICY);
    },
    [],
  )
  
  const triggerFunctionOnEnter = useCallback(
    (trigger) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if(e.key === "Enter") {
            trigger();
        }
    },
    [],
  )
  
  const getSpacerCss = useCallback(
    () => {
      return ( (enableRetry === true && !sending) ? "signature_dialog_wo_spacer" : "signature_dialog_w_spacer");
    },
    [enableRetry, sending],
  )
  
  const handleSubmit = useCallback(
    () => {
      if (childRef.current)
      {
        // Extract signature data URL from signaturePad
        let signatureData = childRef.current.ToDataURL("image/png");

        // Create MPA request
        let request = {
          "FirstName" : merchantInformation.firstName,
          "LastName" : merchantInformation.lastName,
          "LegalEntityName" : merchantInformation.legalName,
          "PositionTitle" : merchantInformation.positionTitle,
          "BusinessName": merchantInformation.businessName,
          "BusinessStreet" : merchantInformation.street,
          "BusinessSuburb" : merchantInformation.suburb,
          "BusinessState" : merchantInformation.state,
          "BusinessPostCode" : merchantInformation.postcode,
          "Abn": merchantInformation.abn,
          "EmailAddress": merchantInformation.email,
          "MobileNumber": merchantInformation.phone,
          "Bytes" : signatureData
        };

        setSending(true);
        setTimeout(() => {
          handleSendFailOrTimeout();
        }, configMpa.mpaTimeoutPeriod);

        ApiService.sendMpa(request)
        .then(() => {
          handleNext();
        })
        .catch((error) => {
          handleSendFailOrTimeout();
        });      
      }
    },
    [handleNext, handleSendFailOrTimeout, merchantInformation.abn, merchantInformation.businessName, merchantInformation.email, merchantInformation.firstName, merchantInformation.lastName, merchantInformation.legalName, merchantInformation.phone, merchantInformation.positionTitle, merchantInformation.postcode, merchantInformation.state, merchantInformation.street, merchantInformation.suburb],
  )

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={eSignOpenP}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle eSignOpenP id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h5" className="signature_dialog_title_text">
          Electronically sign your Merchant Participation Agreement
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="signature_div_border" tabIndex={0}>
            <EventfullSignaturePad
              ref={childRef} 
              setEmptyStatus={handleEmptyStatus}
            />
          </div>

          <Grid container sx={{padding : 3, paddingBottom : 4, paddingTop : 1}} spacing={2}>
            <Grid item xs={12} sm={12} md={2} className="signature_dialog_clear_button">
              <Button variant="esignature" onClick={handleClear}>Clear</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className="signature_sign_above_box">
              <Box sx={{width : 342}} >
                <Typography variant="body1" className="signature_dialog_sign_above_text">
                    Sign above using your mouse, touchpad or touchscreen.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
            </Grid>
          </Grid>
          <Box className="signature_dialog_tandc_box">
            <div className="signature_dialog_border_top"/>
              <Typography tabIndex={0} onKeyPress={triggerFunctionOnEnter(openPrivatePolicy)} className="signature_dialog_text">
                {"By clicking ‘Sign and Submit’, I understand that this is a representation of my signature.\nBy clicking ‘Sign and Submit’, I accept the "}<span className='signature_dialog_privatePolicyHighlight' onClick={openPrivatePolicy}>Privacy Policy</span>{"."}
              </Typography>
              <div className="signature_dialog_bottom_border"/>
          </Box>

          {enableRetry && !sending &&
            <div className="signature_dialog_error_alert">
                <Alert severity="error">
                  <AlertTitle className='signature_dialog_alert_title_text'>Something has gone wrong.</AlertTitle>
                  <Typography tabIndex={0} variant="body1" className="signature_dialog_alert_text">                          
                    An error occurred, and your form could not be submitted as this time. Please try again.
                  </Typography>
                  <Typography tabIndex={0} variant="body1" className="signature_dialog_alert_text">   
                    If this error continues to occur please contact us at <span className='span_css'><a href={"mailto:" + config.ServicesAustralia_EMAIL}>{config.ServicesAustralia_EMAIL}</a>.</span>
                  </Typography>
                </Alert>
              </div>              
          }
          <div className={getSpacerCss()}></div>
            {sending &&
            <Typography tabIndex={0} variant="body1" sx={{width : '100%', textAlign: 'center', color: '#56127C'}} >   
            Please wait while we process your Merchant Participation Agreement.
            </Typography>
          }
          { sending &&
            <div className="signature_dialog_spinner">
              <CircularProgress />
          </div>
          }
          <div className='signature_dialog_centerButton'>
            <Button 
                onClick={handleSubmit}
                disabled={isEmpty}
            >
              { getButtonLabel() }
            </Button>
          </div>
          <Typography variant="body1" className="signature_dailog_endtext">
              {"By clicking ‘Sign and Submit’ this Merchant Participation Agreement will be sent to Services Australia for countersigning. We will send a copy to the email address you provided."}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
