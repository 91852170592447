import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { GoLiveStep } from '../Components/GoLiveStep';
import { Introduction } from '../Components/Introduction';
import '../../../css/FactSheets.css';
import { ContactInfoCards } from '../../Common/ContactInfoCards';
import golive_sign_icon from '../../../Assets/icons/golive_sign_icon.png';
import golive_compatibility_icon from '../../../Assets/icons/golive_compatibility_icon.png';
import golive_upgrades_icon from '../../../Assets/icons/golive_upgrades_icon.png';
import golive_training_icon from '../../../Assets/icons/golive_training_icon.png';
import golive_products_icon from '../../../Assets/icons/golive_products_icon.png';
import golive_installed_icon from '../../../Assets/icons/golive_installed_icon.png';

import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import ApiService from '../../../Services/apiServices';
import {AxiosResponse} from 'axios';
import { FactSheetServerErrorMessage } from '../FactSheetErrorMessage/FactSheetErrorMessage';

export const GoingLive = () => {

    const heading1 = {
        start: "Going live with ",
        highlight: "Product Level Blocking",
        body: <>Here is what happens when you sign a Merchant Participation Agreement to have Product Level Blocking in your business:</>
    }

    const [storageUrl, setStorageUrl] = React.useState<string>('');
    const [updatedDateDescription, setUpdatedDateDescription] = React.useState<string>('');
    const [apiError, setApiError] = React.useState<boolean>(false);

    useEffect(() => {
        fetchMetaData();
      }, []);

    // fetches the document's url to download from and the date description to display from the API
    // and then applies it to the page.  If it can't be found, an error message is displayed
    // next to the button and the error logged to the browser's console.
    const fetchMetaData = () => {
        ApiService.getFactSheetMetadata('Going_Live_With_Product_Level_Blocking')
        .then((response : AxiosResponse) => {
            setApiError(false);
            setStorageUrl(response.data.value[0].StorageUrl);
            setUpdatedDateDescription(response.data.value[0].UpdatedDateDescription);
        })
        .catch((e) => {
            console.log('Error fetching factsheet metadata for Going Live with PLB');
            console.error(e);
            setApiError(true);
            setUpdatedDateDescription(FactSheetServerErrorMessage);
        });
    };

    return (
        <>
            <Helmet>
                <title>{PageTitles.GoingLiveWithProductLevelBlocking} </title>
            </Helmet>
            <Introduction
                heading1={heading1}
                content={
                    <>
                    <Grid container columnSpacing={1} rowSpacing={2} sx={{mb:'2.1875rem'}}>
                        <GoLiveStep 
                            heading="Sign Merchant Participation Agreement"
                            body={
                                <ul>
                                    <li>You sign and submit a Merchant Participation Agreement</li>
                                </ul>
                            }
                            icon={golive_sign_icon}
                        />
                        <GoLiveStep 
                            heading="System check"
                            body={
                                <ul>
                                    <li>We check your current EFTPOS terminals and point of sale (POS) system for compatibility</li>
                                </ul>
                            }
                            icon={golive_compatibility_icon}
                        />
                        <GoLiveStep 
                            heading="System upgrades if required"
                            body={
                                <ul>
                                    <li>We co-ordinate any required updates</li>
                                    <li>If your current system setup is not compatible for update, you will be contacted and provided with options</li>
                                </ul>
                            }
                            icon={golive_upgrades_icon}
                        />
                        <GoLiveStep 
                            heading="Training"
                            body={
                                <ul>
                                    <li>Your POS provider will give PLB training to you and your staff</li>
                                </ul>
                            }
                            icon={golive_training_icon}
                        />
                        <GoLiveStep 
                            heading="Restricted items"
                            body={
                                <ul>
                                    <li>You identify restricted items in your product range. Help is available if you need it.</li>
                                </ul>
                            }
                            icon={golive_products_icon}
                        />
                        <GoLiveStep 
                            heading="Product Level Blocking installed!"
                            body={
                                <ul>
                                    <li>Product Level Blocking is enabled in your business</li>
                                </ul>
                            }
                            icon={golive_installed_icon}
                            arrow={false}
                        />
                    </Grid>
                    </>
                }
                downloadButton
                storageUrl={storageUrl}
                updatedDateDescription={updatedDateDescription}
                apiError={apiError}
            />
            <Box className="facts_background violetBackground">
                <Box className="facts_contentContainer" sx={{alignItems:'center'}}>
                    <Typography variant="h1" className="purple" sx={{mb:'3.125rem', height: '100%'}}>
                        Questions about going live with Product Level Blocking?
                    </Typography>
                    <Typography variant="body2" sx={{mb:'2.25rem', textAlign:'center', maxWidth:'42.1875rem', height: '100%'}}>
                        If you would like more information about going live with Product Level Blocking, please contact our Merchant Engagement Team.
                    </Typography>
                    <ContactInfoCards />
                    <Typography variant="body2" sx={{mt:'3.125rem', mb:'2.25rem', textAlign: 'center', height: '100%'}}>
                        Your point of sale (POS) provider can also provide assistance if you have specific questions about how Product Level Blocking will work on your particular POS system.
                        <br/><br/>Your bank can help you with any questions you may have about your EFTPOS terminals.
                    </Typography>
                </Box>
            </Box>
        </>
    );
}
