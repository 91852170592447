import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './Components/HomePage';
import { PageNotFound } from './Components/PageNotFound';
import { Compatibility } from './Components/Compatibility';
import { ContactUs } from './Components/ContactUs';
import { SignUpPage } from './Components/SignUpPage';
import { MpaStepper } from './Components/Mpa';
import { MpaRedirect } from './Components/Mpa/MpaRedirect';
import { ThankYou } from './Components/ThankYou';
import { GetToKnow } from './Components/FactSheets/GetToKnow';
import { RestrictedItems } from './Components/FactSheets/RestrictedItems';
import { WhatIsPlb } from './Components/FactSheets/WhatIsPlb';
import { YourBusiness } from './Components/FactSheets/YourBusiness';
import { Definitions } from './Components/FactSheets/Definitions';
import { GoingLive } from './Components/FactSheets/GoingLive';
import { FAQ } from './Components/FAQ';
import {
  HomeLocation,
  CompatibilityLocation,
  ContactUsLocation,
  SignUpLocation,
  MpaLocation,
  MpaRedirectLocation,
  ThankYouLocation,
  FactsheetGetToKnowLocation,
  FactsheetRestrictedLocation,
  FactsheetPLBLocation,
  FactsheetReadyLocation,
  FactsheetDefinitionsLocation,
  FactsheetGoingLiveLocation,
  FrequentlyAskedQuestionsLocation
} from './routeConstants';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Routes>
    <Route path={HomeLocation.path} element={<HomePage/>} />
    <Route path={CompatibilityLocation.path} element={<Compatibility/>} />
    <Route path={ContactUsLocation.path} element={<ContactUs/>} />
    <Route path={SignUpLocation.path} element={<SignUpPage/>} />
    <Route path={MpaRedirectLocation.path} element={<MpaRedirect/>} />
    <Route path={MpaLocation.path} element={<MpaStepper/>} />
    <Route path={ThankYouLocation.path} element={<ThankYou/>} />

    <Route path={FactsheetGetToKnowLocation.path} element={<GetToKnow/>}/>
    <Route path={FactsheetRestrictedLocation.path} element={<RestrictedItems/>}/>
    <Route path={FactsheetPLBLocation.path} element={<WhatIsPlb/>}/>
    <Route path={FactsheetGoingLiveLocation.path} element={<GoingLive/>}/>
    <Route path={FactsheetReadyLocation.path} element={<YourBusiness/>}/>
    <Route path={FactsheetDefinitionsLocation.path} element={<Definitions/>}/>
    <Route path={FrequentlyAskedQuestionsLocation.path} element={<FAQ/>} />
    <Route path="*" element={<PageNotFound/>} />
  </Routes>
);
