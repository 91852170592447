import React, { useCallback } from 'react';
import { Link, Typography } from '@mui/material';
import { ContactUsBlock } from '../Components/ContactUsBlock';
import { Introduction } from '../Components/Introduction';
import { TextBlock } from '../Components/TextBlock';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import config from "../../../config/config.json";
import { isAndroid, isApple, isMobileDevice } from '../../Common/DeviceCheck/DeviceCheck';
import '../../../css/App.css';

export const YourBusiness = () => {

    // Phone link
    const getPhoneLink = useCallback(
      () => {
          if (isAndroid()) {
              return ("tel:$(" + config.CONTACT_NUMBER + ")");
          } else if (isApple()) {
              return ("telprompt:$(" + config.CONTACT_NUMBER + ")");
          }
      },
      [],
    )

    // Introduction
    const intro_heading1 = {
        highlight:"Your business",
        end: " and the SmartCard"
    }
    const intro_heading2 = {
        start:"",
        body: <p>
        Your business participates in enhanced Income Management when a customer uses the SmartCard at your store. 
        <br/><br/>
        If your business does not sell any restricted items (for example, clothing stores, green grocers, butchers or toy shops), you will not need to do anything as the SmartCard will automatically work at your business. 
        <br/><br/>
        If your business exclusively or primarily sells restricted items and services (for example, casinos, TABs or liquor stores), your business will be blocked automatically from accepting payments with a SmartCard.
        <br/><br/>
        If your business sells both restricted and unrestricted items (for example, a grocery store with a liquor licence, a newsagency that sells gift cards that can be used at a liquor store, or a pub that sells food and alcohol), your business can take steps to prevent the sale of restricted items with a SmartCard.
    </p>
    }

    // Section 1
    const section1_body =   
        <p style={{height: '100%'}}>
        If you sell both restricted and unrestricted items, you can accept the SmartCard as a form of payment for items that are not restricted by taking one of these actions:
        {/* <br/> */}
        <ul style={{height: '100%'}}>
            <li>Enter into a ‘Merchant Participation Agreement’ with Services Australia to implement Product Level Blocking in your business. This technology will automatically detect restricted items so they cannot be purchased with a SmartCard. You can learn about Product Level Blocking and sign a Merchant Participation Agreement on this website, or call the Merchant Engagement Team at Services Australia on {isMobileDevice() ? <Link className='noWrap' href={getPhoneLink()}>{config.CONTACT_NUMBER}</Link> : <span className='noWrap'>{config.CONTACT_NUMBER}</span>} for further assistance.</li>
            <li>Remove restricted items from your product range.</li>
        </ul>
        Services Australia is committed to working collaboratively with businesses to accept the SmartCard as a method of payment. Services Australia will contact businesses that allow cardholders to purchase restricted items with a SmartCard to discuss the program and how it operates.
        </p>

    // Section 2
    const section2_heading = "What if my business sells both restricted and unrestricted items?";

    return (
        <>
            <Helmet>
                <title>{PageTitles.YourBusiness}</title>
            </Helmet>
            <Introduction
                heading1={intro_heading1}
                heading2={intro_heading2}
                downloadButton={false}
            />
            <TextBlock
                variant="violet"
                heading={section2_heading}
                body={section1_body}
            />
            <ContactUsBlock
                variant="white"
            />
        </>
    );
}