import '../Compatibility.css';
import { Typography, Grid, Button, Box, Radio, Dialog, Alert } from '@mui/material';
import { CompatibilityResult } from '../../../Models/compatibilityResult';
import { DialogTemplate } from '../../DialogTemplate';
import { ExampleDialog } from './Dialogs/ExampleDialog';
import { MoreDialog } from './Dialogs/MoreDialog';
import compatibility_image from '../../../Assets/images/compatibility_image_2.png';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useCallback } from 'react';
import ApiService from '../../../Services/apiServices';
import ReactGA from 'react-ga4';
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';

export interface GeneralInformationProps {
    handleResult(nextStep: number): void
}

export const GeneralInformation = (props: GeneralInformationProps) => {
    const { handleResult } = props;
    const [dialogPreview, setDialogPreview] = React.useState(-1);
    const [questionAlcohol, setQuestionAlcohol] = React.useState<boolean | null>(null);
    const [questionGambling, setQuestionGambling] = React.useState<boolean | null>(null);
    const [questionGiftcard, setQuestionGiftcard] = React.useState<boolean | null>(null);
    const [questionTobacco, setQuestionTobacco] = React.useState<boolean | null>(null);
    const [questionPornography, setQuestionPornography] = React.useState<boolean | null>(null);
    const [showErrors, setShowErrors] = React.useState<boolean>(false);

    const handleQuestionChange = useCallback(
        (state, answer: boolean) => (e: React.MouseEvent<HTMLSpanElement>) => {
            state(answer);
        },
        [],
    )
    
    const triggerFunctionOnEnter = useCallback(
        (state, answer) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                state(answer);
            }
        },
        [],
    )

    const openDialog = useCallback(
        (dialog: number) => (e: React.MouseEvent<HTMLSpanElement>) => {
            setDialogPreview(dialog);
        },
        [],
    )
    
    const closeDialog = useCallback(
        () => {
            const targetElement = document.getElementById("popup");
            if(targetElement != null) {
                enableBodyScroll(targetElement);
            }
            setDialogPreview(-1);
        },
        [],
    )

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        }
    }, []);
    
    const showResult = useCallback(
        () => {
            if(questionAlcohol === true || questionGambling === true || questionGiftcard === true || questionTobacco === true || questionPornography === true) {
                ReactGA.event({
                    category: 'User',
                    action: 'CompatChk - Compatible'
                });
                window.scrollTo(0, 0);
                handleResult(1);
                return;
            }
            if(questionAlcohol === false && questionGambling === false && questionGiftcard === false && questionTobacco === false && questionPornography === false) {
                ReactGA.event({
                    category: 'User',
                    action: 'CompatChk - No Restricted Items'
                });
                window.scrollTo(0, 0);
                handleResult(2);
                return;
            }
        },
        [handleResult, questionAlcohol, questionGambling, questionGiftcard, questionTobacco, questionPornography],
    )

    const checkCompatibility = useCallback(
        () => {
            if(questionAlcohol == null || questionGambling == null || questionGiftcard == null || questionTobacco == null || questionPornography == null) {
                setShowErrors(true);
                return;
            } else {
                let sendInfo: CompatibilityResult = {
                    questionHasAlcohol: questionAlcohol,
                    questionHasGambling: questionGambling,
                    questionHasGiftcard: questionGiftcard,
                    questionHasTobacco: questionTobacco,
                    questionHasPornography: questionPornography
                }
    
                ApiService.sendCompatibilityResult(sendInfo).then(() => {
                    console.log('Sent');
                })
                .catch(() => {
                    console.log('failed');
                });
            }
            showResult();
        },
        [questionAlcohol, questionGambling, questionGiftcard, questionTobacco, questionPornography, showResult],
    )

    const dialogScreen = (screen: number) => {
        switch (screen) {
            case 0: 
                return (
                    <DialogTemplate
                        handleClose={closeDialog}
                    >
                        <ExampleDialog cashProducts={true} tobaccoProducts={false}/>
                    </DialogTemplate>
                )
            case 1: 
                return (
                    <DialogTemplate
                        handleClose={closeDialog}
                    >
                        <ExampleDialog cashProducts={false} tobaccoProducts={true}/>
                    </DialogTemplate>
                )
            case 2:
                return (
                    <DialogTemplate
                        handleClose={closeDialog}
                    >
                        <MoreDialog pornographyProducts={true}/>
                    </DialogTemplate>
                )
        }
    }

    return (
        <div className='compatibility_general_container'>
            
            <Grid container>
                <Grid item xs={12} md={12} className="compatibility_general_title_container">
                    <Typography className="compatibility_general_title">
                        Check my compatibility
                    </Typography>
                    <Typography className="compatibility_general_text">
                        Use this checklist to confirm your business is compatible with Product Level Blocking:
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <div className='compatibility_question'>
                        <Typography className="compatibility_general_label">
                            Does your business sell alcohol?*
                        </Typography>
                        <Grid container spacing={3} className='compatibility_checkboxs'>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionAlcohol, true)}
                                    onClick={handleQuestionChange(setQuestionAlcohol, true)}
                                    tabIndex={0}
                                    className={questionAlcohol === true ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionAlcohol === true}/>
                                    <Typography className="compatibility_checkbox_label">
                                        Yes
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionAlcohol, false)}
                                    onClick={handleQuestionChange(setQuestionAlcohol, false)}
                                    tabIndex={0}
                                    className={questionAlcohol === false ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionAlcohol === false}/>
                                    <Typography className="compatibility_checkbox_label">
                                        No
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {showErrors && questionAlcohol === null && 
                            <Typography className="compatibility_check_error_text">
                                Please select your answer to the above question to proceed.
                            </Typography>
                        }
                    </div>

                    <div className='compatibility_question'>
                        <Typography className="compatibility_general_label" sx={{'@media (max-width: 23.5rem)': { whiteSpace:'normal'}}}>
                            {'Does your business sell gambling services?*'}
                        </Typography>
                        <Grid container spacing={3} className='compatibility_checkboxs'>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionGambling, true)}
                                    onClick={handleQuestionChange(setQuestionGambling, true)}
                                    tabIndex={0}
                                    className={questionGambling === true ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionGambling === true}/>
                                    <Typography className="compatibility_checkbox_label">
                                        Yes
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionGambling, false)}
                                    onClick={handleQuestionChange(setQuestionGambling, false)}
                                    tabIndex={0}
                                    className={questionGambling === false ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionGambling === false}/>
                                    <Typography className="compatibility_checkbox_label">
                                        No
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {showErrors && questionGambling === null && 
                            <Typography className="compatibility_check_error_text">
                                Please select your answer to the above question to proceed.
                            </Typography>
                        }
                    </div>

                    <div className='compatibility_question'>
                        <Typography className="compatibility_general_label">
                            Does your business sell tobacco products?*
                        </Typography>
                        <div className="compatibility_checkbox_info" role="button">
                            <div 
                                tabIndex={0} 
                                onKeyPress={triggerFunctionOnEnter(setDialogPreview, 1)}
                                onClick={openDialog(1)} 
                                className="compatibility_checkbox_info_click"
                            >
                                <InfoIcon className="compatibility_checkbox_info_icon"/>
                                <Typography className="compatibility_checkbox_info_text">Examples</Typography>
                            </div>
                        </div>
                        <Grid container spacing={3} className='compatibility_checkboxs'>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionTobacco, true)}
                                    onClick={handleQuestionChange(setQuestionTobacco, true)}
                                    tabIndex={0}
                                    className={questionTobacco === true ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionTobacco === true}/>
                                    <Typography className="compatibility_checkbox_label">
                                        Yes
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionTobacco, false)}
                                    onClick={handleQuestionChange(setQuestionTobacco, false)}
                                    tabIndex={0}
                                    className={questionTobacco === false ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionTobacco === false}/>
                                    <Typography className="compatibility_checkbox_label">
                                        No
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {showErrors && questionTobacco === null && 
                            <Typography className="compatibility_check_error_text">
                                Please select your answer to the above question to proceed.
                            </Typography>
                        }
                    </div>

                    <div className='compatibility_question'>
                        <Typography className="compatibility_general_label">
                            Does your business sell pornography?*
                        </Typography>
                        <div className="compatibility_checkbox_info" role="button">
                            <div 
                                tabIndex={0} 
                                onKeyPress={triggerFunctionOnEnter(setDialogPreview, 2)}
                                onClick={openDialog(2)} 
                                className="compatibility_checkbox_info_click"
                            >
                                <InfoIcon className="compatibility_checkbox_info_icon"/>
                                <Typography className="compatibility_checkbox_info_text">More Information</Typography>
                            </div>
                        </div>
                        <Grid container spacing={3} className='compatibility_checkboxs'>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionPornography, true)}
                                    onClick={handleQuestionChange(setQuestionPornography, true)}
                                    tabIndex={0}
                                    className={questionPornography === true ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionPornography === true}/>
                                    <Typography className="compatibility_checkbox_label">
                                        Yes
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionPornography, false)}
                                    onClick={handleQuestionChange(setQuestionPornography, false)}
                                    tabIndex={0}
                                    className={questionPornography === false ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionPornography === false}/>
                                    <Typography className="compatibility_checkbox_label">
                                        No
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {showErrors && questionPornography === null && 
                            <Typography className="compatibility_check_error_text">
                                Please select your answer to the above question to proceed.
                            </Typography>
                        }
                    </div>

                    <div className='compatibility_question'>
                        <Typography className="compatibility_general_label">
                            Does your business sell gift cards, prepaid cards or e-vouchers that can be used to purchase alcohol, gambling services, tobacco or pornography?*
                        </Typography>
                        <div className="compatibility_checkbox_info" role="button">
                            <div 
                                tabIndex={0} 
                                onKeyPress={triggerFunctionOnEnter(setDialogPreview, 0)}
                                onClick={openDialog(0)} 
                                className="compatibility_checkbox_info_click"
                            >
                                <InfoIcon className="compatibility_checkbox_info_icon"/>
                                <Typography className="compatibility_checkbox_info_text">Examples</Typography>
                            </div>
                        </div>
                        <Grid spacing={3} container className='compatibility_checkboxs'>
                            <Grid className="compatibility_checkboxs_content" item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionGiftcard, true)}
                                    onClick={handleQuestionChange(setQuestionGiftcard, true)}
                                    tabIndex={0}
                                    className={questionGiftcard === true ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionGiftcard === true}/>
                                    <Typography className="compatibility_checkbox_label">
                                        Yes
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid  className="compatibility_checkboxs_content" item xs={6} md={6}>
                                <Box 
                                    onKeyPress={triggerFunctionOnEnter(setQuestionGiftcard, false)}
                                    onClick={handleQuestionChange(setQuestionGiftcard, false)}
                                    tabIndex={0}
                                    className={questionGiftcard === false ? "compatibility_checkbox_selected compatibility_general_checkbox_container" : "compatbility_checkbox_not_selected compatibility_general_checkbox_container"}
                                >
                                    <Radio className='compatibility_check_radio' tabIndex={-1} checked={questionGiftcard === false}/>
                                    <Typography className="compatibility_checkbox_label">
                                        No
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {showErrors && questionGiftcard === null && 
                            <Typography className="compatibility_check_error_text">
                                Please select your answer to the above question to proceed.
                            </Typography>
                        }
                    </div>

                    
                    <Typography className="compatibility_hint">*indicates mandatory field</Typography>

                    {showErrors && 
                        <div className="compatibility_warning_container">
                            <Alert severity="error">
                                <Typography variant="body1" className="compatibility_warning_text">                          
                                    One or more of the questions have not been answered. Please select your answer on the fields indicated and click ‘check’ to proceed.
                                </Typography>
                            </Alert>
                        </div>
                    }

                    <Button
                        variant="primary"
                        className="compatibility_check_button"
                        onClick={checkCompatibility}
                    >
                        <p className="compatibility_check_button_text">
                            Check
                        </p>
                    </Button>
                </Grid>
                <Grid item xs={0} md={1}/>
                <Grid className="compatibility_image_container" item xs={12} md={5}>
                    <img 
                        src={compatibility_image}
                        className="compatibility_check_image"
                        alt=""/>
                </Grid>
            </Grid>
            <Dialog 
                fullWidth={true}
                maxWidth={"md"} 
                scroll={"paper"}
                open={dialogPreview >= 0}
                aria-describedby="scroll-dialog-description"
            >
                {dialogScreen(dialogPreview)}
            </Dialog>
        </div>
    )
}