import React, { useEffect, useState, useCallback } from 'react';
import { Step, Stepper, StepLabel, Typography, Grid, CircularProgress, Box } from '@mui/material';
import './Compatibility.css';
import { GeneralInformation } from './GeneralInformation';
import { ResultYes } from './ResultYes';
import { ResultNo } from './ResultNo';
import { Helmet } from 'react-helmet';
import { PageTitles } from "../../utils/PageTitles";
import { StepperBackButton } from '../Common/StepperBackButton';

function getSteps() {
  return ['General information', 'Compatibility result'];
}

export const Compatibility = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [stepperProgress, setStepperProgress] = useState(0);
    const [mobileStep, setMobileStep] = useState(0);
    const [hasBackLocation, setHasBackLocation] = useState(false);
    const steps = getSteps();
    
    const onBackButtonEvent = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(activeStep > 0) {
            window.removeEventListener('popstate', onBackButtonEvent);
            setActiveStep(0);
        }
    }
    
    useEffect(() => {
        if(!hasBackLocation) {
            window.history.pushState(null, "", window.location.pathname);
            setHasBackLocation(true);
        }
        window.addEventListener('popstate', onBackButtonEvent);
        
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [activeStep]);

    const getMobileStepperProgress = useCallback(
        () => {
            if(activeStep === 0) {
                return 50.00;
            } else {
                return 100.00;
            }
        },
        [activeStep],
    )
    
    const handleBack = useCallback(
        () => {
            setActiveStep(0);
            setStepperProgress(0);
        },
        [],
    )
    
    const handleResult = useCallback(
        (nextStep: number) => {
            setActiveStep(nextStep);
            if(nextStep > 0) {
                setStepperProgress(2);
                setMobileStep(1);
            }
        },
        [],
    )

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 1:
                return <ResultYes/>;
            case 2:
                return <ResultNo/>;
            default:
                return <GeneralInformation
                    handleResult={handleResult}
                />;
        }
   }

    return (
        <div>
            <Helmet>
                <title>{PageTitles.Compatibility}</title>
            </Helmet>
            <Grid container className="compatibility_stepper_container compad_gutter">
                <Grid item xs={0} md={2}>
                    {activeStep > 0 && 
                        <StepperBackButton
                        handleBack={handleBack}
                    />
                    }
                </Grid>
                <Grid item xs={0} md={1}/>
                <Grid className="compatibility_stepper_flex" item xs={0} md={6}>
                    <Stepper
                        activeStep={stepperProgress}
                        className="compatibility_stepper"
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={0} md={3}/>
                <Stepper 
                    activeStep={stepperProgress}
                    className="compatibility_mobile_stepper"
                >
                    <Box className='compatibility_mobile_progress_container'>
                        <CircularProgress className='compatibility_mobile_progress_circle' size={60} thickness={4} variant="determinate" value={100}/>
                        <CircularProgress size={60} thickness={4} variant="determinate" value={getMobileStepperProgress()}/>
                        <Box className="compatibility_mobile_progress">
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                >{mobileStep+1} of {steps.length}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="compatibility_mobile_stepper_text_container">
                        <Typography className="compatibility_mobile_progress_header">{steps[mobileStep]}</Typography>
                        {mobileStep + 1 < steps.length &&
                            <Typography className="compatibility_mobile_progress_subheader">Next: {steps[mobileStep+1]}</Typography>
                        }   
                    </Box>
                </Stepper>
            </Grid>
            <div className='compad_gutter compad_body'>
                {getStepContent(activeStep)}
            </div>
        </div>
    )
}