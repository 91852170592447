import { Box, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../ContactUs/ContactUs.css';
import { 
    HomeLocation
} from '../../routeConstants';
import React, { useCallback, useEffect } from 'react';

export const ThankYou = () => {
    const navigate = useNavigate();
    const [thankYouName, setThankYouName] = React.useState("");

    const ReturnHome = useCallback(
        () => {
            window.scrollTo(0, 0);
            navigate(HomeLocation.path);
        },
        [navigate],
    );

    useEffect(() => {
        let name = localStorage.getItem("ThankYou");
        if(name != null) {
            setThankYouName(name);
        }
    }, []);

    return (
        <Box className="thankyou_background">
            <Grid container>
                <Grid item xs={0} md={1}/>
                <Grid item xs={12} md={10}>
                    <Box className="thankyou_panel">
                        <Typography className="thankyou_title">Thanks {thankYouName}!</Typography>
                        <Typography className="thankyou_text">We have received your contact information and we will be in touch shortly. Thank you.</Typography>
                        <Button
                            variant="primary"
                            className="thankyou_button"
                            onClick={ReturnHome}
                        >
                            <p className="contactus_button_text">
                                Back to home
                            </p>
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={0} md={1}/>
            </Grid>
        </Box>
    )
}
