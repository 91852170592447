import { Typography, Button, DialogTitle } from '@mui/material';
import config from "../../../../config/config.json";
import './FailureDialog.css';

interface FailureDialogProps {
    handleClose(): void
}

export const FailureDialog = (props: FailureDialogProps) => {
    const { handleClose } = props;

    return (
        <>
          <DialogTitle style={{ textAlign: 'center' }}>
            Something went wrong
          </DialogTitle>
          <Typography
            className="failure_dialog_message"
          >
            {"An error occurred, and your form could not be submitted at this time. Please try again.\n\nIf this error continues to occur, please contact us at "}<a href={"mailto:" + config.ServicesAustralia_EMAIL}>{config.ServicesAustralia_EMAIL}</a>{"."}
          </Typography>
          <Button
            className="failure_dialog_button"
            onClick={handleClose}
            variant="contained"
          >
            Okay
          </Button>
        </>
      );
}