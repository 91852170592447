import { useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import SignaturePad from 'react-signature-pad-wrapper';

export interface EventfulSignaturePadProps {
    setEmptyStatus: (empty: boolean) => void
}

export interface SignaturePadOperations {
    Clear(): void;
    ToDataURL(type: string): string;
}

const EventfulSignaturePad = forwardRef<SignaturePadOperations, EventfulSignaturePadProps>((props, ref) => {
    const { setEmptyStatus } = props;
    const sigPadRef = useRef<SignaturePad>(null);

    useImperativeHandle(
        ref,
        () => ({
            Clear() {
                if (sigPadRef.current) {
                    sigPadRef.current.clear();
                    setEmptyStatus(true);
                }
            },
            ToDataURL(type: string) {
                if (sigPadRef.current) {
                    return sigPadRef.current.toDataURL(type);
                }
                return '';
            }
        }),
    )

    const handleEndStroke = () => {
        setEmptyStatus(false);
    }

    useEffect(()=>{
        const sigPad = sigPadRef.current;
        if (sigPadRef.current) {
            sigPadRef.current.instance.on();
            sigPadRef.current.instance.addEventListener('endStroke', handleEndStroke, true);        
            return () => {
                if (sigPad) {
                    sigPad.instance.removeEventListener('endStroke', handleEndStroke);
                    sigPad.off();
                }
            };
        }
    },[])

    return (
        <div tabIndex={0}>
            <SignaturePad
                ref={sigPadRef}
                height={180}
                redrawOnResize={true}
            />
        </div>
    )
})

export default EventfulSignaturePad;