import '../Compatibility.css';
import { Typography, Button, Grid } from '@mui/material';
import infoIcon from '../../../Assets/icons/info_required_icon.svg';
import { useNavigate } from 'react-router-dom';
import { ContactUsLocation } from '../../../routeConstants';
import { useCallback } from 'react';

export const ResultNo = () => {
    const navigate = useNavigate();

    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )

    return (
        <Grid container>
            <Grid item xs={0} md={2}/>
            <Grid item className="compatibility_result_container" xs={12} md={8}>
                <img 
                    src={infoIcon}
                    className="compatibility_icon_scaledown"
                    alt=""/>
                <Typography className="compatibility_result_text_bold">It sounds like we may need some more information from you to see if you qualify for Product Level Blocking.</Typography>
                <Button variant="primary" onClick={goToLocation(ContactUsLocation.path)} className="compatibility_result_button">
                    Contact Us
                </Button>
            </Grid>
            <Grid item xs={0} md={2}/>
        </Grid>
    )
}