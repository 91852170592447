import '../Compatibility.css';
import { Typography, Button, Grid } from '@mui/material';
import checkIcon from '../../../Assets/icons/check_icon.svg';
import { useNavigate } from 'react-router-dom';
import { ContactUsLocation, MpaLocation } from '../../../routeConstants';
import ReactGA from 'react-ga4';
import { useCallback } from 'react';

export const ResultYes = () => {
    const navigate = useNavigate();

    const goToLocation = useCallback(
        (location: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            window.scrollTo(0, 0);
            navigate(location);
        },
        [navigate],
    )

    const goToTermsConditions = useCallback(
        () => {
            ReactGA.event({
                category: 'User',
                action: 'SignUp - From CompatChk'
            });
            localStorage.setItem("requiresTerms","true");
            window.scrollTo(0, 0);
            navigate(MpaLocation.path);
        },
        [navigate],
    )

    return (
        <Grid container>
            <Grid item xs={0} md={2}/>
            <Grid item className="compatibility_result_container" xs={12} md={8}>
                <img 
                    src={checkIcon}
                    className=""
                    alt=""/>
                <Typography className="compatibility_result_text_title">
                    {'It sounds like you qualify for Product Level Blocking.'}
                </Typography>
                <Typography className="compatibility_result_text_subtitle">
                    To take the next step, sign up for Product Level Blocking or contact us for more information.
                </Typography>
                <div className='compatibility_result_button_container'>
                    <Button variant="primary" onClick={goToTermsConditions} className="compatibility_result_signup_button">
                        Sign up for Product Level Blocking
                    </Button>
                    <Button variant="secondary" onClick={goToLocation(ContactUsLocation.path)} className="compatibility_result_contact_button">
                        Contact Us
                    </Button>
                </div>
            </Grid>
            <Grid item xs={0} md={2}/>
        </Grid>
    )
}