import React from 'react';
import { Typography } from '@mui/material'
import '../../../DialogTemplate/DialogTemplate.css';

export const UnrestrictedDialog = () => {
    return (
        <>
            <Typography className='dialog_title_bold'>
                What if I don't sell restricted items?
            </Typography>
            
            <div className='dialog_text_container'>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"If your business does not sell any restricted items, you will not need to do anything as the SmartCard will automatically work at your business."}</Typography>
                </div>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"\n•\t"}</Typography>
                    <Typography className='dialog_text'>{"\nThe SmartCard can be used anywhere that accepts eftpos or Visa card payments, unless the business primarily sells restricted items."}</Typography>
                </div>
            </div>
        </>
    )
}