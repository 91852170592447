import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
import "@fontsource/open-sans";
import reportWebVitals from './reportWebVitals';
import { reportHandler } from './reportHandler';

cssVars({
  onlyLegacy: true,
  shadowDOM: true,
  variables: {
    '--primary-white': '#FFFFFF',
    '--primary-black': '#000000',
    '--primary-purple': '#5F277E',
    '--secondary-purple': '#8342A7',
    '--background-purple': '#5B3084',
    '--primary-purple-text': '#500A78',
    '--dss-light-purple': '#FAF7FC',
    '--primary-yellow': '#FEE98E',
    '--secondary-yellow': '#E6B000',
    '--primary-dark-gray': '#222222',
    '--dss-dark-green': '#004052',
    '--dss-dark-green-text':'#005A70',
    '--dss-light-green': '#65C3B3',
    '--disabled-button-gray': '#A7A7A7',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

reportWebVitals(reportHandler);