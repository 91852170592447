import React from 'react';
import { Typography } from '@mui/material'
import '../../../DialogTemplate/DialogTemplate.css';

export const RestrictedDialog = () => {
    return (
        <>
            <Typography className='dialog_title_bold'>
                Restricted items that cannot be purchased or accessed with a SmartCard
            </Typography>
            
            <Typography className='dialog_text'>
                {'\nRestricted items that cannot be purchased or accessed with a SmartCard:'}
                </Typography>
            <div className='dialog_text_container'>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"Alcohol"}</Typography>
                </div>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"Gambling services"}</Typography>
                </div>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"Tobacco"}</Typography>
                </div>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"Pornography"}</Typography>
                </div>
                <div className='dialog_flex'>
                    <Typography className='dialog_text_point'>{"•\t"}</Typography>
                    <Typography className='dialog_text'>{"Cash and cash-like products*"}</Typography>
                </div>
                <Typography className='dialog_text'>
                {'\n* A cash-like product is an item that can be used like cash to purchase alcohol, gambling services, tobacco, pornography or to withdraw cash. Examples include some gift cards, prepaid cards, e-vouchers or store credit.'}
                </Typography>
            </div>
        </>
    )
}