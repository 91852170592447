import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
interface CustomDialogInfoProps {
    dialog_title: string;
    dialog_text: string;
}

const useStyles = makeStyles()((theme) => {
    return {
        dialog_title: {
            textAlign: "left",
            font: "normal normal bold 1.5rem/1.875rem Open Sans",
            letterSpacing: "0.0112rem",
            color: "var(--primary-purple)"
        },
        dialog_text: {
            textAlign: "left",
            font: "normal normal normal 1rem/1.875rem Open Sans",
            letterSpacing: "0.0075rem",
            color: "var(--primary-black)",
            whiteSpace: "pre-wrap",
            marginTop: "2rem",
            marginBottom: "3rem"
        }
    };
});

export const CustomDialog = (props: CustomDialogInfoProps) => {
    const {
        dialog_title,
        dialog_text
    } = props;
    const { classes } = useStyles();
    return (
        <>
            <Typography className={classes.dialog_title}>
                {dialog_title}
            </Typography>
            <Typography className={classes.dialog_text}>
                {dialog_text}
            </Typography>
        </>
    )
}