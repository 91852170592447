import React, { useCallback } from 'react';
import { Typography, Link, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { isAndroid, isApple, isMobileDevice } from '../../../Common/DeviceCheck/DeviceCheck';

export interface PosProviderDialogProps {
    name: string,
    posName: string,
    website?: string,
    website2?: string,
    email?: string,
    customEmail?: JSX.Element,
    phone?: string,
    phoneAvailability?: string,
    customContent?: JSX.Element,
    noModal?: boolean
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '0.625rem 0.625rem 0rem 0rem',
        maxWidth: '870px',
        width: '800px'
    },
    '& .MuiDialogTitle-root': {
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: theme.spacing(5),
    },
    '& .MuiDialogContent-root': {
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    '& .MuiDialogActions-root': {
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        WebkitJustifyContent: 'flex-start'
    },
}));

const BootstrapDialogTitle = (props: { [x: string]: any; children: any; onClose: any; }) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function PosProviderDialog(props: PosProviderDialogProps) {
    const [open, setOpen] = React.useState(false);
    const { name, posName, website, website2, email, customEmail, phone, phoneAvailability, customContent, noModal } = props;

    const handleClose = useCallback(
        () => {
            setOpen(false);
        },
        [],
    )

    const handleClickOpen = useCallback(
        () => {
            setOpen(true);
        },
        [],
    )

    const triggerFunctionOnEnter = useCallback(
        (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                setOpen(true);
            }
        },
        [],
    )
    
    const getPhoneLink = useCallback(
        () => {
            if (isAndroid()) {
                return ("tel:" + phone);
            } else if (isApple()) {
                return ("telprompt:$(" + phone + ")");
            }
        },
        [phone],
    )
    
    const getEmailLink = useCallback(
        () => {
            return ("mailto:" + email);
        },
        [email],
    )

    return (
        <div>
            <ul>
                <li>
                    
                    {noModal ? 
                        <span className="plb_posProviderInfoContainer">
                            <Typography variant="body2" className="plb_accreditedBoxBody plb_accreditedBoxProviderName" display="inline">{name}</Typography>
                        </span> : 
                        <span tabIndex={0} className="plb_posProviderInfoContainer" onKeyPress={triggerFunctionOnEnter} onClick={handleClickOpen}>
                            <span>
                                <Typography variant="body2" className="plb_accreditedBoxBody plb_accreditedBoxProviderName_selectable facts_selectable" display="inline">{name}</Typography>
                                <InfoOutlinedIcon className="plb_infoIcon purple" />
                            </span>
                        </span>}
                    <Typography variant="body2" className="plb_accreditedBoxBodyFlex" display="inline"><i>{posName}</i></Typography>
                </li>
            </ul>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography variant="h4" className="plb_posProviderDialogHeading">{name} contact information</Typography>
                </BootstrapDialogTitle>
                <DialogContent className="plb_posProviderDialogContent" >
                    <Typography className="plb_posProviderDialogBody">
                        <ul>
                            {website ? 
                                <li><Link href={website} target="_blank" rel="noopener noreferrer" className="plb_overflow_wrapper">{website}</Link></li> : <></>}
                            {website2 ? 
                                <li><Link href={website2} target="_blank" rel="noopener noreferrer" className="plb_overflow_wrapper">{website2}</Link></li> : <></>}
                            {email ? 
                                <li><Link href={getEmailLink()} className="plb_overflow_wrapper">{email}</Link></li> : <></>}
                            {customEmail ? 
                                customEmail : <></>}
                            {phone ? 
                                    <li>
                                        {isMobileDevice() ? <><Link href={getPhoneLink()} className="plb_overflow_wrapper">{phone}</Link> {phoneAvailability}</> : <>{phone} {phoneAvailability}</>}
                                    </li>
                                 : <></>}
                            {customContent ? 
                                customContent : <></>} 
                        </ul>
                    </Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handleClose} sx={{ alignSelf: 'flex-start' }}>
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
