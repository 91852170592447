import CancelIcon from '@mui/icons-material/Cancel';
import { Button, DialogActions, DialogContent, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import './DialogTemplate.css';

import { disableBodyScroll } from 'body-scroll-lock';

export interface DialogProps {
    children: React.ReactNode
    handleClose(): void
}

export const DialogTemplate = (props: DialogProps) => {
    const { children, handleClose } = props;

    useEffect(()=>{
        const targetElement = document.getElementById("popup");
        if(targetElement != null) {
            targetElement.focus();
            disableBodyScroll(targetElement);
        }
        const handleEsc = (event: { key: string; }) => {
            if (event.key === "Escape") {
                handleClose();
           }
         };
         window.addEventListener('keydown', handleEsc, true);
         return () => {
            window.removeEventListener('keydown', handleEsc);
         };
      },[handleClose])

    return (
        <>
            <div className="DialogTemplate_Container">
                <DialogContent id="popup" dividers={true} className='DialogTemplate_ContentContainer'>
                    <div
                        id="scroll-dialog-description"
                        className='DialogTemplate_TextContainer'
                    >
                        {children}
                    </div>
                    <div className="DialogTemplate_CloseContainer">
                        <IconButton className="DialogTemplate_CloseIcon" onClick={() => handleClose()}>
                            <CancelIcon/>
                        </IconButton>
                    </div>
                </DialogContent>
            </div>
            <div className="DialogTemplate_Border"/>
            <DialogActions className="DialogTemplate_ButtonContainer">
                <Button variant="primary" onClick={() => handleClose()}>
                    <p className="DialogTemplate_ButtonText">Close</p>
                </Button>
            </DialogActions>
        </>
    )
}
