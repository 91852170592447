import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './GoLiveStep.css';
import golive_arrow from '../../../../Assets/icons/golive_arrow.png';

export interface GoLiveStepProps {
    heading: string,
    icon?: string,
    body: JSX.Element,
    arrow?: boolean
}

export const GoLiveStep = (props: GoLiveStepProps) => {
    const {heading, icon, body, arrow} = props;

    return (
        <>
            <Grid item xs={12} md={4}>
                <Box className="goLiveStep_box goLiveStep_headingBox">
                    <div className="goLiveStep_boxContainerWithArrow">
                        <div className="goLiveStep_boxContentContainer">
                            <Typography className = "goLiveStep_heading">{heading}</Typography>
                            <img src={icon} className="goLiveStep_icon" alt=""/>
                        </div>
                        {(arrow === false) ? <></> : <img src={golive_arrow} className="goLiveStep_arrow goLiveStep_arrow_desktop" alt=""/>}
                    </div>
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <Box className="goLiveStep_box goLiveStep_bodyBox">
                    <div className="goLiveStep_boxContainerWithArrow">
                        <div className="goLiveStep_boxContentContainer">
                            <Typography variant="h5" className = "goLiveStep_body">{body}</Typography>
                        </div>
                        {(arrow === false) ? <></> : <img src={golive_arrow} className="goLiveStep_arrow goLiveStep_arrow_mobile" alt=""/>}
                    </div>
                </Box>
            </Grid>
        </>
    );
}
