import React from 'react';
import { Introduction } from "../Components/Introduction";
import { ContactUsBlock } from '../Components/ContactUsBlock';
import { Link } from '@mui/material';
import { Helmet } from 'react-helmet';
import { PageTitles } from '../../../utils/PageTitles';
import config from "../../../config/config.json";

export const GetToKnow = () => {
    const heading1 = {
        start: "Get to know the ",
        highlight: "SmartCard",
        body: <>enhanced Income Management (eIM) and the SmartCard help recipients of certain welfare payments to manage their money and protect them from financial abuse.
                <br/><br/>The customer receives their income support payment in two parts. One part goes into their normal bank account and the balance goes into their eIM account. The eIM account is linked to a Visa/eftpos debit card called the SmartCard that cannot be used to purchase restricted items or to withdraw cash.
                <br/><br/>eIM is established under the Social Security (Administration) Act 1999 (Cth) (the Act). Under the Act, recipients of certain welfare payments are unable to spend a portion of such payments on restricted items.
                <br/><br/>enhanced Income Management money can't be used to buy:
                <ul>
                    <li>alcohol</li>
                    <li>gambling services</li>
                    <li>tobacco</li>
                    <li>pornography</li>
                    <li>cash-like products which can be used to purchase alcohol, gambling services, tobacco, pornography or to withdraw cash.</li>
                </ul>
                <br/>Services Australia delivers eIM on behalf of government.
                <br/><br/>To find out more about eIM, visit the <Link href={config.SA_EIM_WEBSITE} target="_blank" rel="noopener noreferrer">Services Australia SmartCard page</Link>.
            </>
    };

    return (
        <>
            <Helmet>
                <title>{PageTitles.GetToKnow} </title>
            </Helmet>
            <Introduction
                heading1={heading1}
            />
            <ContactUsBlock
                variant="violet"/>
        </>
    );
}
