import React, { useCallback } from 'react';
import { Box, Typography, Grid, Button, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './TermsConditions.css';
import {
    ContactUsLocation
} from '../../../routeConstants';

export interface TermsConditionsProps {
    handleTermsAccepted: () => void
}

export const TermsConditions = (props: TermsConditionsProps) => {
    const { handleTermsAccepted } = props;
    const [acceptTerms, setAcceptTerms] = React.useState<boolean | null>(true);
    const navigate = useNavigate();

    const handleChangeSetResponse = useCallback(
        (setAnswer: boolean) => (e: React.MouseEvent<HTMLSpanElement>) => {
            setAcceptTerms(setAnswer);
        },
        [],
    )
    
    const triggerChangeSetResponseOnEnter = useCallback(
        (setAnswer: boolean) => (e: React.KeyboardEvent<HTMLSpanElement>) => {
            if(e.key === "Enter") {
                setAcceptTerms(setAnswer);
            }
        },
        [],
    )
    
    const nextStage = useCallback(
        () => {
            if(acceptTerms === false) {
                window.scrollTo(0, 0);
                navigate(ContactUsLocation.path);
            } else {
                handleTermsAccepted();
            }
        },
        [acceptTerms, handleTermsAccepted, navigate],
    )

    return (
        <Box className="terms_background">
            <Box className="terms_panel">
                <div className="terms_flexcenter">
                    <Typography className="terms_title_title">Summary of Merchant Participation Agreement</Typography>
                    <Typography className="terms_title_text">{"A Merchant Participation Agreement is a one-page agreement between you and Services Australia to enable the upgrade of your point of sale (POS) system and EFTPOS terminal to implement Product Level Blocking.\n\nHere is an overview of what you and Services Australia will agree by signing a Merchant Participation Agreement."}</Typography>
                </div>
                <div tabIndex={0} className="terms_container">
                    <Typography className="terms_text">
                        Services Australia undertakes to:
                    </Typography>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"engage with your POS system supplier"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"engage with your payment card acquiring bank"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"work with the POS provider and acquirer to update your POS system"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"engage the POS system supplier to provide training to you and your staff at no cost."}</Typography>
                    </div>
                    
                    <Typography className="terms_text">
                        {"\nYou undertake to:"}
                    </Typography>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"allow your POS system to be updated to implement Product Level Blocking"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"correctly identify restricted items in your product range, including any new items added in the future"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"co-operate with service providers engaged by Services Australia"}</Typography>
                    </div>
                    <div className="terms_flex">
                        <Typography className='terms_text_inline_point'>{"\t•\t"}</Typography>
                        <Typography className='terms_text_inline'>{"allow de-identified SmartCard purchase data to be uploaded for use only by Services Australia for the purpose of managing and evaluating the program."}</Typography>
                    </div>
                </div>
                <Grid className="terms_response_grid" spacing={2} container>
                    <Grid item xs={12} md={6}>
                        <Box tabIndex={0} onKeyPress={triggerChangeSetResponseOnEnter(true)} onClick={handleChangeSetResponse(true)} className={acceptTerms === true ? "terms_response_container_selected" : "terms_response_container"}>
                            <Radio tabIndex={-1} className='terms_response_radio_button' checked={acceptTerms === true}/>
                            <div className='terms_response_divide'/>
                            <Typography className={acceptTerms === true ? "terms_response_text_selected" : "terms_response_text"}>
                                I would like to sign up for Product Level Blocking.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box tabIndex={0} onKeyPress={triggerChangeSetResponseOnEnter(false)} onClick={handleChangeSetResponse(false)} className={acceptTerms === false ? "terms_response_container_selected" : "terms_response_container"}>
                            <Radio tabIndex={-1} className='terms_response_radio_button' checked={acceptTerms === false}/>
                            <div className='terms_response_divide'/>
                            <Typography className={acceptTerms === false ? "terms_response_text_selected" : "terms_response_text"}>
                                I would like more information before signing up for Product Level Blocking.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Button
                    variant="primary"
                    className="terms_button"
                    disabled={acceptTerms === null}
                    onClick={nextStage}
                >
                    Continue to next step
                </Button>
            </Box>
        </Box>
    )
}
